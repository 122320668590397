import {
  Button,
  Card,
  Chip,
  DataPoint,
  Dialog,
  Fab,
  Heading,
  HSpacer,
  IconButton,
  TextLink,
  TopAppBar,
  VSpacer,
} from '@/components/DesignSystem';
import { GlobalBanner } from '@/components/DesignSystem/Banner/GlobalBanner';
import { Text } from '@/components/DesignSystem/Text/Text';
import { ConfirmDialog } from '@/components/shared/ConfirmDialog';
import { CreateOfferSuccessDialog } from '@/components/shared/CreateOffer/CreateOfferSucessDialog';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import { FriendlyFulfillmentMethod } from '@/constants/FriendlyFulfillmentMethod';
import { QueryKeys } from '@/constants/QueryKeys';
import { Routes } from '@/constants/Routes';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { CreateOfferModal } from '@/pages/CreateOffer/CreateOfferModal';
import { OfferProduct } from '@/pages/CreateSendPriceRequest/interfaces';
import { PaymentDetails } from '@/pages/ReceivePlaceOffers/OfferOverview/PaymentDetails';
import { ContactInfoModalBottomSheet } from '@/pages/ViewPriceRequest/ContactInfoModalBottomSheet';
import { doesFulfillmentMatch, useFarmerOfferInfo } from '@/pages/ViewPriceRequest/helpers';
import { ProductListDesktop, ProductListMobile } from '@/pages/ViewPriceRequest/ProductList';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { Typography } from '@/themes/MUITheme/typography';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import { CreateOfferEventType, useLogEvent } from '@/utilities/Analytics';
import { OffersApi } from '@/utilities/api/OffersApi';
import { PricingRequestsApi } from '@/utilities/api/PricingRequestsApi';
import { getChipStatusForOffers } from '@/utilities/PricingRequest';
import { ApiOffer, ApiPricingRequestProduct } from '@api/interfaces';
import ArrowBack from '@mui/icons-material/ArrowBack';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Alert, Box, Divider, Stack } from '@mui/material';
import {
  FulfillmentMethod,
  OfferStatus,
  OfferStatusNew,
  PaymentMethodPreference,
  PricingRequestStatus,
  ProductUom,
} from '@shared/enums';
import {
  formatCurrency,
  formatFullDate,
  formatPhoneNumber,
  LocalStorageUtility,
} from '@shared/utilities';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FarmerLicensesModalBottomSheet } from './FarmerLicensesModalBottomSheet';

const styles: SXStyles = {
  totalsDataPoint: {
    maxWidth: "100%",
    py: 0,
  },
  dataPoint: {
    py: 0,
  },
  lineBreaks: {
    overflowWrap: 'break-word',
    whiteSpace: 'pre-line',
  },
  bottomBuffer: {
    height: '100px',
  },
  bottomButtons: {
    alignItems: "center",
    bottom: "24px",
    flexDirection: "row",
    justifyContent: "center",
    position: "fixed",
    width: "100%",
  },
  rightAligned: {
    textAlign: 'right',
    width: '100%',
  },
} as const;

type ModalState = {
  showContactInfoDialog: boolean,
  showFarmerInfoDialog: boolean,
  showSuccessDialog: boolean,
  showFulfillmentDetailsDialog: boolean,
  showCreateOffer: boolean,
  showLicensesDialog: boolean,
  showIgnoreConfirmationDialog: boolean,
  showMarkAsConfirmationDialog: boolean,
}

const initialModalsState: ModalState = {
  showContactInfoDialog: false,
  showFarmerInfoDialog: false,
  showSuccessDialog: false,
  showFulfillmentDetailsDialog: false,
  showCreateOffer: false,
  showLicensesDialog: false,
  showIgnoreConfirmationDialog: false,
  showMarkAsConfirmationDialog: false,
}

export const ViewPriceRequest = () => {
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const inviteToken = searchParams.get('token') ?? undefined;
  const logEvent = useLogEvent();

  const { id } = useParams();
  const { isMobile } = useMediaQuery();
  const { isRetailer, user } = useAuthentication();

  const [expired, setExpired] = useState(false);
  const [isItemInLocalStorage, setIsItemInLocalStorage] = useState(false);

  const [modalState, setModalState] = useState<ModalState>(initialModalsState);

  const [errorMessage, setErrorMessage] = useState('');
  const [products, setProducts] = useState<OfferProduct[]>([]);
  const [placedOffer, setPlacedOffer] = useState<ApiOffer | undefined>();

  const mapOfferProducts = (
    products: ApiPricingRequestProduct[],
    offer: ApiOffer | null,
  ): OfferProduct[] => {
    const getOffer = (
      id: string,
      quantity: number | null,
      uom: ProductUom | null,
    ) => {
      const offerProduct = offer?.products?.find((offerProduct) => (
        offerProduct.pricingRequestProductId === id
      )) ?? {
        price: 0,
        pricingRequestProductId: id,
        quantity,
        substituteProduct: '',
        uom,
      }

      if (offerProduct.quantity == null && offerProduct.uom == null) {
        offerProduct.quantity = quantity;
        offerProduct.uom = uom;
      }

      return offerProduct;
    }

    return products.map((product): OfferProduct => ({
      ...product,
      ...getOffer(product.id, product.quantity, product.uom),
      initialQuantity: product.quantity,
      initialUom: product.uom,
      companionProducts: product.companionProducts?.map((companionProduct) => ({
        ...companionProduct,
        ...getOffer(companionProduct.id, companionProduct.quantity, companionProduct.uom),
        initialQuantity: companionProduct.quantity,
        initialUom: companionProduct.uom,
      })) ?? [],
    }));
  };

  const { data: priceRequest } = useQuery(
    [QueryKeys.GET_PRICING_REQUEST_PUBLIC, id, user?.id, placedOffer],
    () => PricingRequestsApi.getPricingRequestPublic(id as string, inviteToken),
    {
      onSuccess: (data) => {
        setPlacedOffer(data.offer ?? undefined);
        setProducts(
          mapOfferProducts(data.products || [], data.offer),
        );
      },
      onError: (error: { message: string }) => {
        setErrorMessage(error.message ?? 'An error has occurred');
      },
    },
  );

  const isLastLookEnabled = useMemo(() => {
    return !!priceRequest?.status
      && [
        PricingRequestStatus.Open,
        PricingRequestStatus.Review,
        PricingRequestStatus.Accepted,
        PricingRequestStatus.Closed,
        PricingRequestStatus.AwaitingFulfillment,
        PricingRequestStatus.OrderShipped,
        PricingRequestStatus.PickupReady,
        PricingRequestStatus.OnAccountRequested,
        PricingRequestStatus.FinancingRequested,
        PricingRequestStatus.AwaitingDelivery,
        PricingRequestStatus.AwaitingPickup,
      ].includes(priceRequest.status)
      && priceRequest.salesperson?.preferred;
  }, [priceRequest]);

  const { data: lastLookData } = useQuery(
    [QueryKeys.GET_LAST_LOOK, priceRequest?.id],
    () => PricingRequestsApi.getLastLook(priceRequest!.id, inviteToken),
    { enabled: isLastLookEnabled },
  );

  const isAcceptedOffer = placedOffer?.status === OfferStatus.Accepted
    || placedOffer?.status === OfferStatus.Partial;

  const { data: stripePayment } = useQuery(
    [QueryKeys.GET_STRIPE_PAYMENT, placedOffer?.id],
    () => OffersApi.getStripePayment(placedOffer!.id, inviteToken),
    {
      enabled: isAcceptedOffer
        && !!priceRequest?.publicId
        && placedOffer?.paymentMethodPreference === PaymentMethodPreference.OUTSIDE,
    },
  );

  const markShipmentMutation = useMutation(
    async () => {
      const fulfillmentMethod = priceRequest?.fulfillmentMethod === FulfillmentMethod.DELIVERY
        ? FulfillmentMethod.DELIVERY
        : FulfillmentMethod.PICKUP
      if (placedOffer?.id) {
        await OffersApi.markShipment(placedOffer.id, fulfillmentMethod);
      }
    }, {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.GET_PRICING_REQUEST_PUBLIC]);
        setModalState({ ...modalState, showMarkAsConfirmationDialog: false });
        openSnackbar(
          `#${priceRequest?.publicId} has been marked as ${priceRequest?.retailerOfferStatus}`,
        );
      },
      onError: () => {
        openSnackbar('There was a problem updating the status');
      },
    },
  );

  const showStripePayment = !!stripePayment && !!stripePayment.feePercent;

  const {
    deliveryAddress,
    expirationDate,
    farmerName,
    offerSubtotal,
    offerTotal,
    reviewExpirationDate,
  } = useFarmerOfferInfo(priceRequest, placedOffer, products);

  const status = priceRequest?.status || PricingRequestStatus.Open;

  const canCreateOffer = priceRequest?.status === PricingRequestStatus.Open;
  const canIgnore = canCreateOffer && !placedOffer;

  useEffect(() => {
    logEvent(CreateOfferEventType.ViewPriceRequest);
  }, []);

  useEffect(() => {
    if (!priceRequest) {
      return;
    }
    const existing = LocalStorageUtility.exists(priceRequest.id);
    setIsItemInLocalStorage(existing);

    setExpired(priceRequest?.isExpired && !priceRequest.offer);
  }, [priceRequest, status]);

  const shouldRenderGlobalBanner = !isItemInLocalStorage && expired;

  const formatOfferSubmittedDate = (offer?: ApiOffer | null) => {
    if (!offer) {
      return "";
    }
    const date = new Date(offer.updatedAt || '');
    return formatFullDate(date);
  };

  const TotalDetails = () => {
    return (
      <Stack spacing="8px">
        {!!placedOffer?.totalShipmentCost && (
          <DataPoint
            childrenStyle={styles.rightAligned}
            containerStyle={styles.totalsDataPoint}
            label="Subtotal"
            spacing="fixed"
            testID="view-price-request-totals-subtotal"
          >
            {offerSubtotal}
          </DataPoint>
        )}
        {!!placedOffer?.totalShipmentCost && (
          <DataPoint
            childrenStyle={styles.rightAligned}
            containerStyle={styles.totalsDataPoint}
            label="Shipping"
            spacing="fixed"
            testID="view-price-request-totals-shipping"
          >
            {formatCurrency(placedOffer?.totalShipmentCost)}
          </DataPoint>
        )}
        <DataPoint
          childrenStyle={{ ...styles.rightAligned }}
          containerStyle={styles.totalsDataPoint}
          label="Total"
          labelStyle={{
            ...Typography['title-medium'],
          }}
          spacing="fixed"
          testID="view-price-request-totals-total"
        >
          {offerTotal}
        </DataPoint>
      </Stack>
    );
  }

  const PriceRequestDetails = () => {
    return (
      <>
        <Stack spacing={1} sx={styles.lineBreaks}>
          <DataPoint
            containerStyle={styles.dataPoint}
            label="Expires on:"
            testID="view-price-request-details-sheet-expiration-date"
          >
            {reviewExpirationDate || expirationDate}
          </DataPoint>
          <DataPoint
            containerStyle={styles.dataPoint}
            label="Farmer:"
            testID="view-price-request-details-sheet-farmer"
          >
            <Stack alignItems="center" direction="row">
              <Text>
                {farmerName}
              </Text>
              {!isMobile && priceRequest?.user?.telephone && (
                <>
                  <HSpacer size="3" />
                  <IconButton
                    onClick={() => {
                      setModalState({ ...modalState, showFarmerInfoDialog: true });
                      logEvent(CreateOfferEventType.ClickFarmerInfoIcon);
                    }}
                    sx={{ width: '20px', height: '20px' }}
                    testID="view-price-request-details-sheet-farmer-info-icon"
                  >
                    <InfoOutlined fontSize="small" />
                  </IconButton>
                </>
              )}
            </Stack>
          </DataPoint>
          <DataPoint
            containerStyle={styles.dataPoint}
            label="Farm name:"
            testID="view-price-request-details-sheet-farm-name"
          >
            {priceRequest?.user?.businessName}
          </DataPoint>
          <DataPoint
            containerStyle={styles.dataPoint}
            label="Fulfillment:"
            testID="view-price-request-details-sheet-fulfillment"
          >
            {priceRequest?.fulfillmentMethod === FulfillmentMethod.DELIVERY ? (
              <TextLink
                category="body-medium"
                onClick={() => setModalState({ ...modalState, showFulfillmentDetailsDialog: true })}
                testID="view-price-request-details-sheet-fulfillment-text-link"
              >
                {priceRequest?.fulfillmentMethod}
              </TextLink>
            ) : (
              !priceRequest?.fulfillmentMethod
                ? '-'
                : FriendlyFulfillmentMethod[priceRequest.fulfillmentMethod]
            )}
          </DataPoint>
          <DataPoint
            containerStyle={styles.dataPoint}
            label="Contact info:"
            testID="view-price-request-details-sheet-contact-info"
          >
            <TextLink
              category="body-medium"
              onClick={() => {
                setModalState({ ...modalState, showContactInfoDialog: true });
                logEvent(CreateOfferEventType.ClickViewPriceRequestViewDetails);
              }}
              testID="view-price-request-details-sheet-fulfillment-text-link"
            >
              View
            </TextLink>
          </DataPoint>
          <DataPoint
            containerStyle={styles.dataPoint}
            label="Licenses:"
            testID="view-price-request-details-sheet-licenses"
          >
            <TextLink
              category="body-medium"
              onClick={() => {
                setModalState({ ...modalState, showLicensesDialog: true });
              }}
              testID="view-price-request-details-sheet-licenses-link"
            >
              View
            </TextLink>
          </DataPoint>
        </Stack>
        {priceRequest?.note && (
          <>
            <VSpacer size="9" />
            <Divider />
            <VSpacer size="4" />
            <Heading
              level="4"
              testID="view-price-request-details-note-from-farmer-header"
              title="NOTE FROM FARMER"
            />
            <VSpacer size="6" />
            <Text sx={styles.lineBreaks}>
              {priceRequest.note}
            </Text>
          </>
        )}
      </>
    );
  };

  const PageHeader = () => {
    const chipProps = () => {
      if (priceRequest?.retailerOfferStatus) {
        return getChipStatusForOffers(priceRequest);
      }
      return { label: undefined, testID: '' }
    }

    const shouldMarkedAsShipped = doesFulfillmentMatch(priceRequest, FulfillmentMethod.DELIVERY);
    const shouldMarkedAsFulfilled = doesFulfillmentMatch(priceRequest, FulfillmentMethod.PICKUP);

    const MarkAsButton = shouldMarkedAsFulfilled || shouldMarkedAsShipped ? (
      <>
        <HSpacer size="3" />
        <Button
          onClick={() => setModalState({ ...modalState, showMarkAsConfirmationDialog: true })}
          size="small"
          testID="mark-as-button"
        >
          {shouldMarkedAsShipped ? 'Mark as Shipped' : 'Mark as Fulfilled'}
        </Button>
      </>
    ) : undefined;

    const Status = priceRequest?.retailerOfferStatus ? (
      <>
        <Stack alignItems="center" direction="row" justifyContent="flex-start">
          <Chip {...chipProps()} />
          {MarkAsButton}
        </Stack>
        {priceRequest?.retailerOfferStatus === OfferStatusNew.OfferExpired
          && !priceRequest.offer && (
          <DataPoint
            label={`Expired on ${formatFullDate(new Date(priceRequest.expiration || ''))}`}
            testID="expired-data-point"
          />
        )}
      </>
    ) : null;

    return (
      <>
        <Box>
          <Heading
            actions={isMobile ? undefined : [
              <>
                {placedOffer ? (
                  <Text category="body-medium">
                    {`Offer submitted ${formatOfferSubmittedDate(placedOffer)}`}
                  </Text>
                ) : (
                  <>
                    {canIgnore && (
                      <>
                        <Button
                          onClick={() => {
                            setModalState({ ...modalState, showIgnoreConfirmationDialog: true });
                          }}
                          testID="view-price-request-create-offer-button"
                          variant="outlined"
                        >
                          Ignore
                        </Button>
                        <HSpacer size="5" />
                      </>
                    )}
                    <Button
                      disabled={!canCreateOffer}
                      onClick={() => {
                        setModalState({ ...modalState, showCreateOffer: true });
                        logEvent(CreateOfferEventType.ClickCreateOffer);
                      }}
                      testID="view-price-request-create-offer-button"
                    >
                      Create Offer
                    </Button>
                  </>
                )}
              </>,
            ]}
            level="1"
            overline="Product Request"
            testID="view-price-request-heading"
            title={priceRequest && `#${priceRequest.publicId}`}
          />
          <VSpacer size="4" />
          {Status}
        </Box>
      </>
    );
  };

  const FarmerInfoDialog = () => (
    <Dialog
      acceptButton={(props) => (
        <Button
          href={`tel:${priceRequest?.user?.telephone}`}
          {...props}
        >
          Call now
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          onClick={() => setModalState({ ...modalState, showFarmerInfoDialog: false })}
          {...props}
        >
          Close
        </Button>
      )}
      dialogWidth="318px"
      onClose={() => setModalState({ ...modalState, showFarmerInfoDialog: false })}
      open
      testID="view-price-request-details-farmer-info-dialog"
      title="Have a question about this Product Request?"
    >
      <Text category="body-medium">
        Call the farmer at {formatPhoneNumber(priceRequest?.user?.telephone || '', 'paren')}
      </Text>
    </Dialog>
  );

  const PaymentElement = () => showStripePayment ? (
    <>
      <Heading
        level="2"
        testID="view-price-request-payment-heading"
        title="Payment"
      />
      <VSpacer size="5" />
      <Box sx={styles.paymentBox}>
        <PaymentDetails
          offerPublicId={`${priceRequest?.publicId}`}
          payment={stripePayment}
        />
      </Box>
      <VSpacer size="5" />
      <Card testID="total-details-card">
        <TotalDetails />
      </Card>
      <VSpacer size="5" />
    </>
  ) : null;

  const IgnoreConfirmationDialog = (
    <ConfirmDialog
      confirmText="Confirm"
      onCancel={() => setModalState({ ...modalState, showIgnoreConfirmationDialog: false })}
      onConfirm={async () => {
        setModalState({ ...modalState, showIgnoreConfirmationDialog: false });
        await PricingRequestsApi.ignorePricingRequest(priceRequest!.id);
        navigate(Routes.VIEW_OFFERS);
      }}
      open
      testID="view-price-request-ignore-confirmation-dialog"
      title="Ignore offer"
    >
      Are you sure you want to ignore the offer?
    </ConfirmDialog>
  );

  const MarkAsConfirmationDialog = (
    <ConfirmDialog
      confirmText="Submit"
      onCancel={() => setModalState({ ...modalState, showMarkAsConfirmationDialog: false })}
      onConfirm={() => {
        markShipmentMutation.mutate();
      }}
      open
      testID="mark-as-confirm-dialog"
      title={doesFulfillmentMatch(priceRequest, FulfillmentMethod.DELIVERY)
        ? 'Mark as Shipped'
        : 'Mark as Fulfilled'}
    >
      Are you sure?
    </ConfirmDialog>
  );

  return (
    <>
      {isRetailer && (
        <TopAppBar
          isStatic
          leftAccessory={
            <Button
              onClick={() => navigate(Routes.VIEW_OFFERS)}
              startIcon={<ArrowBack />}
              testID="view-price-request-back-button"
              variant="text"
            >
              <DesktopOnly>
                <Text category="label-medium">Back to Product Requests</Text>
              </DesktopOnly>
            </Button>
          }
          testID="view-price-request-top-app-bar"
        >
        </TopAppBar>
      )}
      {shouldRenderGlobalBanner && (
        <GlobalBanner
          bottomSpacerSize="7"
          message="This Product Request has Expired. No new offers can be placed."
          testID="banner"
        />
      )}
      {errorMessage && (
        <Alert color="error" icon={false}>
          {errorMessage}
        </Alert>
      )}
      <Box
        sx={{
          margin: isMobile ? '0 16px 0' : '0 32px 32px',
        }}
      >
        <PageHeader />
        <MobileOnly>
          <VSpacer size="6" />
          <DataPoint
            containerStyle={styles.dataPoint}
            label="Expires on:"
            testID="view-price-request-expiration-date"
          >
            {reviewExpirationDate || expirationDate}
          </DataPoint>
          <VSpacer size="3" />
          <DataPoint
            containerStyle={styles.dataPoint}
            label="Farmer:"
            testID="view-price-request-farmer-name"
          >
            <Stack alignItems="center" direction="row">
              {farmerName}
              {priceRequest?.user?.telephone && (
                <>
                  <HSpacer size="3" />
                  <IconButton
                    onClick={() => setModalState({ ...modalState, showFarmerInfoDialog: true })}
                    sx={{ width: '20px', height: '20px' }}
                    testID="view-price-request-details-sheet-farmer-info-icon"
                  >
                    <InfoOutlined fontSize="small" />
                  </IconButton>
                </>
              )}
            </Stack>
          </DataPoint>
          {showStripePayment && (
            <>
              <VSpacer size="6" />
              <PaymentDetails
                offerPublicId={`${priceRequest?.publicId}`}
                payment={stripePayment}
              />
            </>
          )}
          <VSpacer size="6" />
          {!!placedOffer && (
            <>
              <TotalDetails />
              <VSpacer size="6" />
            </>
          )}
          <Button
            onClick={() => setModalState({ ...modalState, showContactInfoDialog: true })}
            testID="view-price-request-view-details-button"
            variant="outlined"
          >
            View details
          </Button>
          <VSpacer size="10" />
          <Heading
            level="2"
            testID="view-price-request-product-heading"
            title={`Products (${priceRequest?.products?.length ?? 0})`}
          />
          <VSpacer size="7" />
          <Divider />
          <Card testID="product-list-card">
            <ProductListMobile
              lastLookData={lastLookData}
              offerProducts={placedOffer?.products}
              products={products}
              variant="viewProductRequest"
            />
          </Card>
          <Box sx={styles.bottomBuffer} />
        </MobileOnly>
        <DesktopOnly>
          <VSpacer size="7" />
          <Stack direction="row">
            <Box width="296px">
              <PaymentElement />
              <Heading
                level="2"
                testID="view-price-request-details-heading"
                title="Details"
              />
              {(!!placedOffer && !showStripePayment) && (
                <>
                  <VSpacer size="5" />
                  <Card sx={styles.detailsBox} testID="total-details-card">
                    <TotalDetails />
                  </Card>
                </>
              )}
              <VSpacer size="5" />
              <Card sx={styles.detailsBox} testID="price-request-details-card">
                <PriceRequestDetails />
              </Card>
            </Box>
            <HSpacer size="7" />
            <Stack flexGrow="1">
              <Heading
                level="2"
                testID="view-price-request-product-heading"
                title={`Products (${priceRequest?.products?.length ?? 0})`}
              />
              <VSpacer size="5" />
              <Card testID="product-list-card">
                <ProductListDesktop
                  lastLookData={lastLookData}
                  offerProducts={placedOffer?.products}
                  products={products}
                  variant="viewProductRequest"
                />
              </Card>
            </Stack>
          </Stack>
        </DesktopOnly>
      </Box>
      <MobileOnly>
        <Stack sx={styles.bottomButtons}>
          {canIgnore && (
            <>
              <Fab
                onClick={() => {
                  setModalState({ ...modalState, showIgnoreConfirmationDialog: true });
                }}
                testID="view-price-request-create-offer-button"
                variant="extended"
              >
                Ignore
              </Fab>
              <HSpacer size="5" />
            </>
          )}
          {!placedOffer && (
            <Fab
              disabled={!canCreateOffer}
              onClick={() => setModalState({ ...modalState, showCreateOffer: true })}
              testID="view-price-request-create-offer-button"
              variant="extended"
            >
              Create Offer
            </Fab>
          )}
        </Stack>
      </MobileOnly>
      {modalState.showFulfillmentDetailsDialog && (
        <Dialog
          onClose={() => setModalState({ ...modalState, showFulfillmentDetailsDialog: false })}
          open
          testID="view-price-request-fulfillment-details-dialog"
          title="Fulfillment Details"
        >
          Delivery to {deliveryAddress}.
        </Dialog>
      )}
      {(modalState.showContactInfoDialog && !!priceRequest) && (
        <ContactInfoModalBottomSheet
          onClose={() => setModalState({ ...modalState, showContactInfoDialog: false })}
          onOpen={() => setModalState({ ...modalState, showContactInfoDialog: true })}
          priceRequest={priceRequest}
        />
      )}
      {(modalState.showLicensesDialog && !!priceRequest && !!priceRequest.user) && (
        <FarmerLicensesModalBottomSheet
          farmer={priceRequest.user}
          onClose={() => setModalState({ ...modalState, showLicensesDialog: false })}
          onOpen={() => setModalState({ ...modalState, showLicensesDialog: true })}
        />
      )}
      {modalState.showFarmerInfoDialog && <FarmerInfoDialog />}
      {modalState.showCreateOffer && priceRequest && products && (
        <CreateOfferModal
          inviteToken={inviteToken}
          onClose={() => setModalState({ ...modalState, showCreateOffer: false })}
          onSubmit={(placedOffer) => {
            setPlacedOffer(placedOffer);
            setProducts(
              mapOfferProducts(priceRequest.products || [], placedOffer),
            );
            setModalState({
              ...modalState,
              showCreateOffer: false,
              showSuccessDialog: true,
            });
          }}
          onViewDetails={() => {
            setModalState({ ...modalState, showContactInfoDialog: true });
          }}
          priceRequest={priceRequest}
          products={products}
        />
      )}
      {modalState.showSuccessDialog && (
        <CreateOfferSuccessDialog
          daysToAccept={priceRequest?.requestedExpirationDays || 0}
          onDone={() => setModalState({ ...modalState, showSuccessDialog: false })}
        />
      )}
      {modalState.showIgnoreConfirmationDialog && IgnoreConfirmationDialog}
      {modalState.showMarkAsConfirmationDialog && MarkAsConfirmationDialog}
    </>
  );
};
