import { DataPoint, SideSheet, Text, VSpacer } from '@/components/DesignSystem';
import { ShowMoreText } from '@/components/shared/ShowMoreText/ShowMoreText';
import { FriendlyUoM } from '@/constants/FriendlyUoM';
import { ProductCard } from '@/pages/Admin/PricingRequestList/ProductCard';
import { ApiProduct, ApiPromotion } from '@api/interfaces';
import { ApiBundle } from '@api/interfaces/ApiBundle';
import { Box, Divider, Stack } from '@mui/material';
import { PromotionType } from '@shared/enums/PromotionType';
import { formatCurrency, formatDateOnly } from '@shared/utilities';
import React from 'react';

interface ProductBundleDetailsSideSheetProps {
  bundle: ApiBundle,
  isOpen: boolean;
  onClose: () => void;
}

export const ProductBundleDetailsSideSheet = ({
  bundle,
  isOpen,
  onClose,
}: ProductBundleDetailsSideSheetProps) => {
  const currentDate = formatDateOnly(new Date());

  const getFarmerPromotions = (product: ApiProduct) => {
    return product.promotions?.filter(
      (promotion) => promotion.type === PromotionType.Farmer
        && currentDate >= promotion.startDate
        && currentDate <= promotion.endDate,
    ) as ApiPromotion[] ?? [];
  }

  const getRetailerPromotions = (product: ApiProduct) => {
    return product.promotions?.filter(
      (promotion) => promotion.type === PromotionType.Retailer
        && currentDate >= promotion.startDate
        && currentDate <= promotion.endDate,
    ) as ApiPromotion[] ?? [];
  }

  const Body = () => {
    return (
      <Box px='24px'>
        <Stack>
          <Text category="overline">
            PRODUCT BUNDLE
          </Text>
          <VSpacer size="2" />
          <Text category="headline-small">
            {bundle.name}
          </Text>
          <VSpacer size="2" />
          <Text category="title-medium">
            {bundle.quantity} {FriendlyUoM[bundle.uom]}
          </Text>
          <VSpacer size="3" />
          <DataPoint
            containerStyle={{ py: 0 }}
            label="Price per unit:"
            testID="price-per-unit-datapoint"
          >
            {formatCurrency(bundle.pricePerUnit ?? 0)}
          </DataPoint>
          <VSpacer size="2" />
          <DataPoint
            containerStyle={{ py: 0 }}
            label="Bundle cost:"
            testID="bundle-cost-datapoint"
          >
            {formatCurrency((bundle.pricePerUnit ?? 0) * (bundle.quantity ?? 0))}
          </DataPoint>
          <VSpacer size="5" />
          <Text category="body-small">
            Description
          </Text>
          <VSpacer size="2" />
          {!!bundle.description && (
            <ShowMoreText text={bundle.description} />
          )}
          <VSpacer size="5" />
          <Divider />
          <VSpacer size="5" />
          <Text category="label-small">
            Note: Itemized products will not be shown to the farmer. The farmer will receive the
            product bundle name, uom, quantity, price per unit, and bundle cost.
          </Text>
          <VSpacer size="5" />
          <Divider />
          <VSpacer size="5" />
          <Text category="title-medium">Products ({bundle.products?.length ?? 0})</Text>
          <VSpacer size="5" />
          {bundle.products?.map((product) => (
            <ProductCard
              farmerPromotions={
                product.productDetails ? getFarmerPromotions(product.productDetails) : []
              }
              key={product.id}
              product={product}
              retailerPromotions={
                product.productDetails ? getRetailerPromotions(product.productDetails) : []
              }
              showPrices
            />
          ))}
        </Stack>
      </Box>
    );
  }

  return (
    <SideSheet
      hideScrollbar
      isBorderless
      onClose={onClose}
      open={isOpen}
      testID="product-bundle-details"
      title="Bundle Details"
      width="425px"
    >
      <Body />
    </SideSheet>
  );
};
