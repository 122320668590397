import { HSpacer, Text } from '@/components/DesignSystem';
import { ApiProductRecommendation } from '@api/interfaces';
import { Stack } from '@mui/material';
import { DateTime } from 'luxon';

const ProductRecommendationDetails = ({
  info,
  showDescription = false,
}: {
  info: ApiProductRecommendation | undefined;
  showDescription?: boolean;
}) => {
  const numberOfProducts = (
    (info?.productRecommendationData?.length ?? 0) + (info?.bundles?.length ?? 0)
  );
  return (
    <Stack spacing={1.2}>
      <Text category='h6' fontWeight={'medium'}>
        Product Recommendation #{info?.publicId}
      </Text>
      <Stack flexDirection="row">
        <Text>
          Salesperson Name:
        </Text>
        <HSpacer size="2" />
        <Text category="body-medium" color="primary">
          {info?.salespersonDetails?.businessName ?? '-'}
        </Text>
      </Stack>
      <Stack flexDirection="row">
        <Text>
          Products: {numberOfProducts}&nbsp;&nbsp;
          Farmers: {info?.recommendationFarmer?.length}
        </Text>
      </Stack>
      <Stack flexDirection="row">
        <Text>
          Created On:
        </Text>
        <HSpacer size="2" />
        <Text color="success">
          { DateTime.fromISO(info?.createdAt as unknown as string).toFormat('MMM dd, yyyy') }
        </Text> &nbsp;&nbsp;
        <Text>
          Expires On:
        </Text>
        <HSpacer size="2" />
        <Text color="warning">
          { DateTime.fromISO(info?.expiration as unknown as string).toFormat('MMM dd, yyyy') }
        </Text>
      </Stack>
      {showDescription && (
        <Stack flexDirection="row">
          <Text>
            Description:
          </Text>
          <HSpacer size="2" />
          <Text>
            {info?.note}
          </Text>
        </Stack>
      )}
    </Stack>
  );
};

export default ProductRecommendationDetails;
