import { Button, HSpacer, Toolbar, VSpacer } from '@/components/DesignSystem';
import {
  Filter,
  FilterOption,
  FilterSelections,
} from '@/components/DesignSystem/Toolbar/interfaces';
import { useSearch } from '@/hooks/useSearch';
import { useUser } from '@/hooks/useUser';
import { FarmerRecipientCard } from '@/pages/Admin/CustomNotifications/FarmerRecipientCard';
import { UserApi } from '@/utilities/api/UserApi';
import { UserEndpoint } from '@api/endpoints';
import AddIcon from '@mui/icons-material/Add';
import { CircularProgress, Pagination, Stack } from '@mui/material';
import { UserType } from '@shared/enums';
import React, { useEffect, useState } from 'react';

interface AddFarmerRecipientsDialogContentProps {
  onChange: (updatedIds: string[]) => void,
  retailerOptions: FilterOption[],
  selectedRecipientIds: string[],
  stateAndCountyOptions: FilterOption[],
}

export const AddFarmerRecipientsDialogContent = ({
  onChange,
  retailerOptions,
  selectedRecipientIds,
  stateAndCountyOptions,
}: AddFarmerRecipientsDialogContentProps) => {
  const [page, setPage] = useState(0);
  const [isAddingAll, setIsAddingAll] = useState(false);
  const { setSearch, debouncedSearch } = useSearch(1);
  const [filterSelections, setFilterSelections] =
    useState<FilterSelections | undefined>(() => new Map());

  const selectionsString = filterSelections && JSON.stringify(
    Array.from(filterSelections).map(([, values]) => Array.from(values)),
  );

  const countyIds = Array.from(filterSelections?.get('state-county-filter') ?? []);
  const locationIds = Array.from(filterSelections?.get('location-filter') ?? []);

  const userFilters: UserEndpoint.List.Query = {
    countyId: countyIds.length ? countyIds : undefined,
    includeStorefrontUsers: false,
    preferredLocationId: locationIds.length ? locationIds : undefined,
    search: debouncedSearch || undefined,
    userType: [UserType.Farmer],
  };

  const {
    isFetching,
    users,
  } = useUser({
    ...userFilters,
    page,
  }, true);

  useEffect(() => {
    setPage(0);
  }, [debouncedSearch, selectionsString]);

  const addAll = async () => {
    setIsAddingAll(true);
    const newSelectedRecipients = new Set(selectedRecipientIds);
    const users = await UserApi.list(userFilters, true);
    users.data.forEach((user) => newSelectedRecipients.add(user.id));
    onChange(Array.from(newSelectedRecipients));
    setIsAddingAll(false);
  }

  const isUserAdded = (userId: string) => {
    return selectedRecipientIds.includes(userId);
  }

  const removeAll = () => {
    onChange([]);
  }

  const showRemoveAllButton = !!selectedRecipientIds.length;

  const filters: Filter[] = [
    {
      id: 'state-county-filter',
      label: 'State & Counties',
      options: stateAndCountyOptions,
      selectionMethod: 'multi-select',
    },
    {
      helperText: 'Showing retailers with \'preferred\' status',
      id: 'location-filter',
      label: 'Retailer & Locations',
      options: retailerOptions,
      selectionMethod: 'multi-select',
    },
  ];

  return (
    <>
      <Toolbar
        filters={filters}
        onChange={({ search, selections }) => {
          setSearch(search ?? '');
          setFilterSelections(selections);
        }}
        testID="farmer-recipients-toolbar"
        totalItems={users?.total}
        totalUnit="farmer"
      />
      <Stack flexDirection="row" justifyContent="flex-end">
        {showRemoveAllButton && (
          <Button
            onClick={removeAll}
            testID="remove-all-recipients-button"
            variant="text"
          >
            Remove all
          </Button>
        )}
        {showRemoveAllButton && (
          <HSpacer size="5" />
        )}
        <Button
          loading={isAddingAll}
          onClick={addAll}
          startIcon={<AddIcon />}
          testID="add-all-recipients-button"
        >
          Add all
        </Button>
      </Stack>
      <VSpacer size="5" />
      {users?.data.map((user) => (
        <React.Fragment key={user.id}>
          <FarmerRecipientCard
            isUserAdded={isUserAdded(user.id)}
            onAction={() => {
              const updatedRecipients = new Set(selectedRecipientIds);
              if (isUserAdded(user.id)) {
                updatedRecipients.delete(user.id);
              } else {
                updatedRecipients.add(user.id);
              }
              onChange(Array.from(updatedRecipients));
            }}
            user={user}
          />
          <VSpacer size="4" />
        </React.Fragment>
      ))}
      <VSpacer size='8' />
      {isFetching ? (
        <Stack
          alignItems='center'
          direction='column'
          justifyContent='center'
          sx={{ marginTop: '10rem' }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <>
          {users && !!users.lastPage && (
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='center'
            >
              <Pagination
                count={users.lastPage + 1}
                onChange={(_, page) => {
                  setPage(page - 1);
                }}
                page={users.page + 1}
              />
            </Stack>
          )}
        </>
      )}
    </>
  )
}
