import React, { useState } from 'react';
import { Button, DatePickerDropdown, HSpacer, Modal } from '@/components/DesignSystem';
import { Stack } from '@mui/material';
import { ModalProps } from '@/components/DesignSystem/Modal/Modal';

interface DateRangeModalProps extends ModalProps {
  acceptButtonLabel: string,
  onClose: () => void,
  onConfirm: (startDate: Date, endDate: Date) => void,
  testID: string,
}

export const DateRangeModal = ({
  acceptButtonLabel = 'Confirm',
  onClose,
  onConfirm,
  testID,
  ...rest
}: DateRangeModalProps) => {
  const [startDate, setStartDate] = useState<Date | undefined>(new Date());
  const [endDate, setEndDate] = useState<Date | undefined>(new Date());

  const isFormValid = (
    !!startDate
    && !!endDate
    && startDate <= endDate
  );

  return (
    <Modal
      acceptButton={(props) => (
        <Button
          {...props}
          disabled={!isFormValid}
          onClick={() => onConfirm(startDate!, endDate!)}
        >
          {acceptButtonLabel}
        </Button>
      )}
      cancelButton={(props) => (
        <Button {...props} onClick={onClose}>
          Close
        </Button>
      )}
      onClose={onClose}
      testID={testID}
      {...rest}
    >
      <Stack direction="row">
        <DatePickerDropdown
          errorMessage={isFormValid
            ? undefined
            : 'Invalid dates'}
          label="Start date"
          onChange={(startDate) => setStartDate(startDate)}
          testID={`${testID}-start-date`}
          value={startDate}
        />
        <HSpacer size="7"/>
        <DatePickerDropdown
          errorMessage={isFormValid
            ? undefined
            : 'Invalid dates'}
          label="End date"
          onChange={(endDate) => setEndDate(endDate)}
          testID={`${testID}-end-date`}
          value={endDate}
        />
      </Stack>
    </Modal>
  );
}