import { HSpacer, Text } from "@/components/DesignSystem";
import PricingRequestStatusChip from "@/components/shared/PricingRequestStatusChip";
import { StorefrontChip } from '@/components/shared/StorefrontChip';
import { ApiPricingRequest } from "@api/interfaces";
import { Accordion, AccordionSummary, Stack } from "@mui/material";
import { getFarmerName } from '@shared/utilities';

interface PricingRequestItemProps {
  pricingRequest: ApiPricingRequest,
  onSelect: () => void,
}

const PricingRequestItem = (
  { onSelect, pricingRequest }: PricingRequestItemProps,
) => {
  return (
    <Accordion disableGutters sx={{ padding: '28px' }}>
      <AccordionSummary sx={{ padding: '0px' }}>
        <Stack alignItems="center" direction="row" justifyContent="space-between" onClick={onSelect}
        width="100%">
          <Stack spacing={1}>
            <Text category="h6">Product Request #{pricingRequest.publicId}</Text>
            <Text category="body-large">Farmer: {getFarmerName(pricingRequest.user, true)}</Text>
            <Stack direction="row">
              <PricingRequestStatusChip
                orderCompletedOn={pricingRequest.orderCompletedOn}
                paymentStatus={pricingRequest.paymentStatus}
                status={pricingRequest.status}
              />
              <HSpacer size="3" />
              <StorefrontChip subdomain={pricingRequest.storefront?.subdomain} />
            </Stack>
          </Stack>
        </Stack>
      </AccordionSummary>
    </Accordion>
  );
};

export default PricingRequestItem;
