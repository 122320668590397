import { useMutation, useQuery, useQueryClient } from 'react-query';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import { RetailerEndpoint } from '@api/endpoints/RetailerEndpoint';
import { QueryKeys } from '@/constants/QueryKeys';
import { UserEndpoint } from '@api/endpoints';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { AdminRetailerConstants, errorMessages } from '@/constants/constant';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';
const useAddRetailer =
  (setIsAddRetailerOpen: (args: boolean) => void, setError: (args: string) => void) => {
    const { openSnackbar: showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { mutate: addRetailer, isLoading } = useMutation(
      (args: RetailerEndpoint.Create.Request) =>
        HierarchyOfRetailersApi.addHierarchyOfRetailers(args),
      {
        onSuccess: async () => {
          showSnackbar(AdminRetailerConstants.addRetailerSuccess);
          setIsAddRetailerOpen(false);
          await queryClient.invalidateQueries(QueryKeys.GET_RETAILERS);
        },
        onError: (error: DetailedApiError) => {
          setError(error.message);
        },
      },
    );

    return { addRetailer, isLoading };
  };

const useEditRetailer =
(id = '', onClose: (args: boolean) => void, setError?: (args: string) => void) => {
  const { openSnackbar: showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate: editRetailer, isLoading } = useMutation(
    async (args: RetailerEndpoint.Update.Request) => {
      if (id) {
        return await HierarchyOfRetailersApi.updateRetailer(id, args);
      }
      throw new Error('Retailer ID is required');
    },
    {
      onSuccess: async () => {
        onClose(false);
        showSnackbar(AdminRetailerConstants.updateRetailerSuccess);
        await queryClient.invalidateQueries(QueryKeys.GET_RETAILERS);
        await queryClient.invalidateQueries(QueryKeys.GET_RETAILER_BY_ID);
      },
      onError: (error: DetailedApiError) => {
        setError ? setError(error.message) : showSnackbar(error.message);
      },
    },
  );

  return { editRetailer, isLoading };
};

const useGetRetailers = (
  query: RetailerEndpoint.List.Query,
  usePost = false,
  enabled = true,
) => {
  if (query.isActive && typeof query.isActive === 'string')
    delete query.isActive;
  const { data: getRetailers, isFetched, isLoading } = useQuery(
    [QueryKeys.GET_RETAILERS, query],
    () => HierarchyOfRetailersApi.listRetailers(query, usePost),
    {
      enabled,
      staleTime: 5 * 60 * 1000,
    },
  );

  return { getRetailers, isFetched, isLoading };
};

const useGetRetailerById = (id: string) => {
  const { data: retailer, isLoading } = useQuery(
    [QueryKeys.GET_RETAILER_BY_ID, id],
    () => HierarchyOfRetailersApi.getRetailerById(id),
    {
      enabled: !!id,
    },
  );

  return { retailer, isLoading };
};

const useAddRetailerMember = (onClose: () => void, setErorr: (args: string) => void) => {
  const { openSnackbar: showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate: addRetailerMember, isLoading } = useMutation(
    (args: UserEndpoint.Create.Request) =>
      HierarchyOfRetailersApi.addRetailerMember(args),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_RETAILER_MEMBERS);
        await queryClient.invalidateQueries(QueryKeys.GET_LOCATION_MEMBERS);
        showSnackbar(AdminRetailerConstants.addMemberSuccess);
        onClose();
      },
      onError: (error: DetailedApiError) => {
        setErorr(error.message);
      },
    },
  );

  return { addRetailerMember, isLoading };
};

const useGetRetailerMembers = (id: string, search: string) => {
  const { data: getRetailerMembers, isLoading } = useQuery(
    [QueryKeys.GET_RETAILER_MEMBERS, id, search],
    () => HierarchyOfRetailersApi.getRetailerMembers(id, search),
    {
      enabled: !!id,
    },
  );

  return { getRetailerMembers, isLoading };
};

const useUpdateMember =
  (id: string, isLocation = false, onClose: () => void, setError?: (args: string) => void) => {
    const { openSnackbar: showSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { mutate: updateMember, isLoading } = useMutation(
      (args: UserEndpoint.Update.Request) =>
        HierarchyOfRetailersApi.updateMember(id, args, isLocation),
      {
        onSuccess: async () => {
          const queryKey = isLocation ?
            QueryKeys.GET_LOCATION_MEMBERS : QueryKeys.GET_RETAILER_MEMBERS;

          await queryClient.invalidateQueries(queryKey);
          showSnackbar(AdminRetailerConstants.updateMemberSuccess);
          onClose();
        },
        onError: (error: DetailedApiError) => {
          setError ? setError(error.message) : showSnackbar(error.message);
        },
      },
    );

    return { updateMember, isLoading };
  };

const useDeleteMember = (id: string, onClose: () => void, setError: (args: string) => void) => {
  const { openSnackbar: showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate: deleteMember, isLoading } = useMutation(
    () => HierarchyOfRetailersApi.deleteMember(id),
    {
      onSuccess: async () => {
        showSnackbar(AdminRetailerConstants.deleteMemberSuccess);
        await queryClient.invalidateQueries(QueryKeys.GET_RETAILER_MEMBERS);
        await queryClient.invalidateQueries(QueryKeys.GET_LOCATION_MEMBERS);
      },
      onError: (error: DetailedApiError) => {
        onClose();
        if(error.code === '500') {
          return setError(errorMessages.somethingWentWrong)
        }
        setError(error.message);
      },
    },
  );

  return { deleteMember, isLoading };
}

export {
  useAddRetailer,
  useAddRetailerMember,
  useDeleteMember,
  useEditRetailer,
  useGetRetailerById,
  useGetRetailerMembers,
  useGetRetailers,
  useUpdateMember,
};
