import { Card, Text, VSpacer } from '@/components/DesignSystem';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { Divider, Stack } from "@mui/material";
import { formatCurrency } from '@shared/utilities';
import { useMemo } from 'react';
import { Offer } from '../interfaces';

interface OfferCostSummaryProps {
  offer: Offer,
}

const OfferCostSummary = ({ offer }: OfferCostSummaryProps) => {
  const { isMobile } = useMediaQuery();

  const totalWithNoDiscounts = useMemo(() => {
    let totalAmount = 0;
    offer.products?.forEach((product) => {
      totalAmount += (product.price || 0);
    });
    return totalAmount;
  }, [offer]);

  return (
    <>
      <Stack direction="row" justifyContent={isMobile ? "center" : "space-between"}>
        <Text category="h3">Cost Summary</Text>
      </Stack>
      <VSpacer size="7" />
      <Card testID="offer-cost-summary">
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Text category="p1">
              Total products:
            </Text>
            <Text category="p1">
              {offer.products?.length}
            </Text>
          </Stack>
          <VSpacer size="4" />
          <Stack direction="row" justifyContent="space-between">
            <Text category="p1">
              Total products in offer:
            </Text>
            <Text category="p1">
              {offer.products?.filter((p) => !!p.price).length}
            </Text>
          </Stack>
          <VSpacer size="4" />
          <Divider light />
          <Stack direction="row" justifyContent="space-between">
            <Text category="h6">Total:</Text>
            <Text category="h6">{formatCurrency(totalWithNoDiscounts)}</Text>
          </Stack>
        </Stack>
      </Card>
    </>
  );
}

export default OfferCostSummary;
