import { Button, HSpacer, Modal, Text, VSpacer } from "@/components/DesignSystem";
import { ContactFooter } from "@/components/shared/ContactFooter";
import { QueryKeys } from "@/constants/QueryKeys";
import { Box, Chip, Container, Stack } from "@mui/material";
import { State } from "@shared/enums";
import { Fragment, useMemo, useState } from "react";
import { useQuery } from "react-query";
import ImportAllowedCountiesModal from "./ImportAllowedCountiesModal";
import CountiesModal from "./CountiesModal";
import { ApiUser } from "@api/interfaces";
import { RetailerLocationApi } from "@/utilities/api/RetailerLocationApi";

export interface PartnerRetailerAreaModalProps {
  isVisible: boolean,
  onClose: () => void,
  partnerRetailer: ApiUser,
  showImportButton?: boolean,
  title: string,
}

const PartnerRetailerAreaModal = ({
  isVisible,
  onClose,
  partnerRetailer,
  showImportButton = false,
  title,
}: PartnerRetailerAreaModalProps) => {

  const [selectedState, setSelectedState] = useState<State>();
  const [countiesMap, setCountiesMap] = useState(new Map<State, string[]>());
  const [
    isImportAllowedCountiesModalVisible,
    setIsImportAllowedCountiesModalVisible,
  ] = useState(false);

  const states = useMemo(() => {
    return Array.from(countiesMap?.keys() || []);
  }, [countiesMap]);

  const { isLoading } = useQuery(
    [QueryKeys.GET_ALLOWED_COUNTIES, partnerRetailer.locationId],
    () => {
      if (partnerRetailer.locationId) {
        return RetailerLocationApi.getAllowedCounties(partnerRetailer.locationId);
      }
      return null;
    },
    {
      enabled: !!partnerRetailer.locationId,
      onSuccess: (allowedCounties) => {
        const counties = new Map<State, string[]>();
        allowedCounties?.forEach((c) => {
          const county = c.county;
          const state = c.state;

          if (county && state) {
            const countiesArray = counties.get(state) || [];
            countiesArray.push(county);
            counties.set(state, countiesArray);
          }
        });

        setCountiesMap(counties);
      },
      staleTime: 5 * 60 * 1000,
    },
  );

  return (
    <>
      <Modal
        cancelButton={(props) => (
          <Button onClick={onClose} {...props}>
            Close
          </Button>
        )}
        largeModal
        onClose={onClose}
        open={isVisible}
        testID="partner-retailer-area-modal"
        title={title}
      >
        <Container maxWidth="xs">
          <Stack>
            <Text category="s1">States</Text>
            <VSpacer size="5"/>
            {states.length > 0 && !isLoading ? (
              <Box>
                {
                  states.map((state) =>
                    <Fragment key={state}>
                      <Chip label={state}/>
                      <HSpacer size="3"/>
                    </Fragment>,
                  )
                }
              </Box>
            ) : (
              <Text category="p2">
                {isLoading ? "Loading states" : "No states served"}
              </Text>
            )
            }
            <VSpacer size="9"/>
            <Text category="s1">Counties</Text>
            <VSpacer size="5"/>
            {
              states.length > 0 && !isLoading ? (
                <Box>
                  {
                    states.map((state) =>
                      <Fragment key={state}>
                        <Chip
                          clickable
                          label={`${state} (${countiesMap?.get(state)?.length})`}
                          onClick={() => setSelectedState(state)}/>
                        <HSpacer size="3"/>
                      </Fragment>,
                    )
                  }
                </Box>
              ) : (
                <Text category="p2">
                  {isLoading ? "Loading counties" : "No counties served"}
                </Text>
              )
            }

            <VSpacer size="12" />

            {showImportButton ? (
              <Stack direction="row" justifyContent="center">
                <Button
                  onClick={() => setIsImportAllowedCountiesModalVisible(true)}
                  size="giant"
                  testID="partner-retailer-area-modal-import-counties-button"
                >
                  Import Counties
                </Button>
              </Stack>
            ) : (
              <>
                <Stack direction="row" justifyContent="center">
                  <Text category="p2">
                    Need to update this list?
                  </Text>
                </Stack>
                <ContactFooter prefix="" />
              </>
            )}
          </Stack>
        </Container>
      </Modal>
      {
        !!selectedState && (
          <CountiesModal
            counties={countiesMap}
            isVisible={!!selectedState}
            onClose={() => {
              setSelectedState(undefined);
            }}
            state={selectedState} />
        )
      }
      {
        isImportAllowedCountiesModalVisible && (
          <ImportAllowedCountiesModal
            isVisible
            onClose={() => setIsImportAllowedCountiesModalVisible(false)}
            partnerRetailerId={partnerRetailer.id}
          />
        )
      }
    </>
  );
}

export default PartnerRetailerAreaModal;

