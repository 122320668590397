import { Color } from '@/themes/MUITheme/palette';
import { alpha, darken, lighten, useTheme } from '@mui/material';

export type ColorStopNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

interface IColorStop {
  [stop: number]: {
    colorChange: 'darken' | 'lighten' | null,
    coefficient: number,
  }
}

const ColorStops: IColorStop = {
  1: {
    colorChange: 'lighten',
    coefficient: .9,
  },
  2: {
    colorChange: 'lighten',
    coefficient: .75,
  },
  3: {
    colorChange: 'lighten',
    coefficient: .5,
  },
  4: {
    colorChange: 'lighten',
    coefficient: .3,
  },
  5: {
    colorChange: null,
    coefficient: 0,
  },
  6: {
    colorChange: 'darken',
    coefficient: .14,
  },
  7: {
    colorChange: 'darken',
    coefficient: .3,
  },
  8: {
    colorChange: 'darken',
    coefficient: .4,
  },
  9: {
    colorChange: 'darken',
    coefficient: .5,
  },
  10: {
    colorChange: 'darken',
    coefficient: .6,
  },
};

export const useColor = () => {
  const theme = useTheme();

  const getColor = (color: Color, stopNumber: ColorStopNumber) => {
    const colorStop = ColorStops[stopNumber];
    if (colorStop.colorChange === 'lighten') {
      return lighten(theme.palette[color].main, colorStop.coefficient);
    } else if (colorStop.colorChange === 'darken') {
      return darken(theme.palette[color].main, colorStop.coefficient);
    }
    return theme.palette[color].main;
  }

  const getHoverColor = (color: Color) => {
    return alpha(theme.palette[color].main, .08);
  }

  return {
    getColor,
    getHoverColor,
  }
}
