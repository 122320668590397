import { HSpacer, IconButton, Text } from '@/components/DesignSystem';
import { ButtonProps } from '@/components/DesignSystem/Button/Button';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import Close from '@mui/icons-material/Close';
import { DialogTitle, Divider, Stack } from '@mui/material';
import SwipeableDrawer, { SwipeableDrawerProps } from '@mui/material/SwipeableDrawer';
import { Modify } from '@shared/utilities/UtilityTypes';
import { ReactNode } from 'react';

const styles: SXStyles = {
  header: {
    overflow: 'hidden',
    p: 0,
  },
  headerContent: {
    mt: '12px',
  },
} as const;

export interface SideSheetProps
  extends Modify<
    SwipeableDrawerProps,
    {
      onClose: () => void,
      onOpen?: () => void,
    }
  > {
  actionButton?: (props: ButtonProps) => ReactNode,
  centerActions?: boolean,
  children?: ReactNode,
  hideScrollbar?: boolean,
  isBorderless?: boolean,
  secondaryActionButton?: (props: ButtonProps) => ReactNode,
  testID: string,
  title?: string,
  width?: number | string,
}

export const SideSheet = ({
  actionButton,
  centerActions,
  children,
  hideScrollbar,
  isBorderless = false,
  onClose,
  onOpen,
  open,
  secondaryActionButton,
  testID,
  title,
  width,
}: SideSheetProps) => {

  const ActionButtonProps: ButtonProps = {
    size: 'medium',
    testID: `${testID}-action-button`,
  }

  const SecondaryActionButtonProps: ButtonProps = {
    size: 'medium',
    testID: `${testID}-secondary-action-button`,
    variant: 'outlined',
  }

  return (
    <SwipeableDrawer
      ModalProps={{ disableEnforceFocus: true }}
      PaperProps={{
        style: {
          borderRadius: !isBorderless ? '16px 0 0 16px' : '0px',
          overflowY: 'hidden',
          width: width ?? '375px',
        },
      }}
      anchor="right"
      onClose={onClose}
      onOpen={() => {
        onOpen?.();
      }}
      open={open}
      sx={{ zIndex: 1300 }}
    >
      <DialogTitle data-testid={`${testID}-title`} sx={styles.header}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          p="12px 12px 16px 24px"
        >
          <Text category="title-large" sx={styles.headerContent}>
            {title}
          </Text>
          <IconButton
            onClick={onClose}
            sx={styles.headerContent}
            testID={`${testID}-close-button`}
          >
            <Close/>
          </IconButton>
        </Stack>
      </DialogTitle>
      <Stack
        className={hideScrollbar ? 'scrollable-container' : ''}
        height="100%"
        overflow="auto"
      >
        {children}
      </Stack>
      {(!!actionButton || !!secondaryActionButton) && (
        <Stack height="81px" pb="24px">
          <Divider />
          <Stack
            direction="row"
            divider={<HSpacer size="3" />}
            justifyContent={centerActions ? 'center' :
              !secondaryActionButton ? 'center':'space-between'}
            padding="16px 24px 0"
          >
            {!!actionButton && (
              actionButton(ActionButtonProps)
            )}
            {!!secondaryActionButton && (
              secondaryActionButton(SecondaryActionButtonProps)
            )}
          </Stack>
        </Stack>
      )}
    </SwipeableDrawer>
  );
};
