import { Button, HSpacer, ProgressLoader, Text, VSpacer } from '@/components/DesignSystem';
import { Filter } from '@/components/DesignSystem/Filter/Filter';
import { DateRangeModal } from '@/components/shared/DateRangeModal';
import { FriendlyPricingRequestStatus } from '@/constants/FriendlyPricingRequestStatus';
import { productRequest } from '@/constants/ProductRequest';
import { QueryKeys } from '@/constants/QueryKeys';
import { useUser } from '@/hooks/useUser';
import { PricingRequestsApi } from '@/utilities/api/PricingRequestsApi';
import { downloadCsv } from '@/utilities/Export';
import { getUserDisplayName } from '@/utilities/Farmer';
import { PricingRequestEndpoint } from '@api/endpoints';
import { ApiPricingRequest, ApiUser } from '@api/interfaces';
import { Download } from '@mui/icons-material';
import { Alert, Box, Container, Pagination, Stack } from '@mui/material';
import { PricingRequestStatus, UserType } from '@shared/enums';
import { SharedConfig } from '@shared/SharedConfig';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import PricingRequestItem from './PricingRequestListItem';

const PricingRequestList = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [results, setResults] = useState(0);
  const [selectedFilters, setSelectedFilters] = (
    useState<PricingRequestEndpoint.ListForAdmin.Query>({})
  );
  const [searchAndFilter, setSearchAndFilter] = (
    useState<PricingRequestEndpoint.ListForAdmin.Query>({})
  );
  const [showOrderPaymentsModal, setShowOrderPaymentsModal] = useState(false);

  useEffect(()=>{
    if(selectedFilters?.search){
      if(selectedFilters?.search?.length > 2){
        setSearchAndFilter(selectedFilters)
      }
    } else{
      setSearchAndFilter(selectedFilters)
    }
  },[selectedFilters])


  const navigate = useNavigate();
  const { data } = useQuery(
    [QueryKeys.GET_PRICING_REQUESTS_FOR_ADMIN, searchAndFilter],
    () => PricingRequestsApi.getPricingRequestsForAdmin(searchAndFilter),
    {
      onSuccess: (paginatedData) => {
        setResults(paginatedData.total);
      },
      onError: (error: { message: string }) => {
        setErrorMessage(error.message || 'An error has occurred loading product requests');
      },
    },
  );

  const { users: farmers } = useUser({
    limit: SharedConfig.maxPageLimit,
    userType: [UserType.Farmer],
  });

  const handleOnRedirect = (pricingRequest: ApiPricingRequest) => {
    navigate(`/admin/individual-Pricing-Request/${pricingRequest.id}`, {
      state: { pricingRequest },
    });
  };

  const {
    mutateAsync: exportOrderPayments,
    isLoading: isCsvExporting,
  } = useMutation(
    async ({ startDate, endDate }: { startDate?: Date, endDate?: Date }) => {
      const response = await PricingRequestsApi.exportOrderPayments({ startDate, endDate });
      const dateString = new Date().toISOString().slice(0, 10); // Format: YYYY-MM-DD
      downloadCsv(response.csv, `order-payment-export-${dateString}`);
    },
    {
      onError: (error: { message: string }) => {
        setErrorMessage(error.message || 'Encountered an error exporting order payments');
      },
    },
  );

  return (
    <>
      <Container maxWidth="xl">
        <Stack>
          <VSpacer size="8" />
          <Text category="h3">{productRequest.productRequests}</Text>
          <VSpacer size="10" />
          <Stack alignItems="flex-end" direction="row" justifyContent="space-between">
            <Box sx={{ minWidth: '600px', maxWidth: '900px' }}>
              <Filter<PricingRequestEndpoint.ListForAdmin.Query>
                filters={[
                  {
                    id: 'status',
                    label: 'Status',
                    options: (
                      Object.values(PricingRequestStatus).map(
                        (value) => ({ id: value, label: FriendlyPricingRequestStatus[value] }),
                      )
                    ),
                  },
                  {
                    id: 'userId',
                    label: 'Farmer',
                    options: (
                      (farmers?.data as ApiUser[] ?? []).map(
                        (farmer) => ({ id: farmer.id , label: getUserDisplayName(farmer) }),
                      )
                    ),
                  },
                ]}
                onChange={setSelectedFilters}
                recordName="Product Request"
                testID="pricing-request-list-filter"
                totalResults={results}
              />
            </Box>
            <Box pb="16px">
              <Button
                onClick={() => setShowOrderPaymentsModal(true)}
                testID="pricing-request-list-export-button"
                variant="text"
              >
                Export order payments
                <HSpacer size="3" />
                {isCsvExporting ? (
                  <>
                    <HSpacer size="2" />
                    <ProgressLoader size="20px" type="circular" />
                  </>
                ) : (
                  <Download />
                )}
              </Button>
            </Box>
          </Stack>
          {!!errorMessage && (
            <Alert color="error" icon={false}>{errorMessage}</Alert>
          )}
          {data?.data.map((pricingRequest: ApiPricingRequest) => (
            <React.Fragment key={pricingRequest.id}>
              <PricingRequestItem
                onSelect={() => {
                  handleOnRedirect(pricingRequest)
                }}
                pricingRequest={pricingRequest}
              />
              <VSpacer size="3" />
            </React.Fragment>
          ))}
          {data && data.lastPage !== 0 &&
            <Stack alignItems="center">
              <VSpacer size="9" />
              <Pagination
                count={data.lastPage + 1}
                onChange={
                  (event, page) => setSelectedFilters({ ...selectedFilters, page: page - 1 })
                }
                page={data.page + 1}
              />
            </Stack>
          }
          <VSpacer size="14" />
        </Stack>
      </Container>
      {showOrderPaymentsModal && (
        <DateRangeModal
          acceptButtonLabel="Export"
          onClose={() => setShowOrderPaymentsModal(false)}
          onConfirm={async (startDate, endDate) => {
            setShowOrderPaymentsModal(false);
            await exportOrderPayments({ startDate, endDate });
          }}
          open
          subline="Choose the start and end dates for the report.
       These dates correspond to the date the order was paid."
          testID="export-order-payments-modal"
          title="Export Order Payments"
        />
      )}
    </>
  );
}


export default PricingRequestList;
