import {
  Button,
  FullscreenDialog,
  SegmentedButton,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { useCountyList } from '@/hooks/useCountyList';
import { useGetRetailers } from '@/hooks/useHierarchyOfRetailers';
import {
  AddFarmerRecipientsDialogContent,
} from '@/pages/Admin/CustomNotifications/AddFarmerRecipientsDialogContent';
import {
  AddSalespersonRecipientsDialogContent,
} from '@/pages/Admin/CustomNotifications/AddSalespersonRecipientsDialogContent';
import {
  ViewAddedRecipientsModal,
} from '@/pages/Admin/CustomNotifications/ViewAddedRecipientsModal';
import { People } from '@mui/icons-material';
import Check from '@mui/icons-material/Check';
import { Divider, Stack } from '@mui/material';
import { SharedConfig } from '@shared/SharedConfig';
import { groupBy } from '@shared/utilities';
import React, { useMemo, useState } from 'react';

interface AddRecipientsDialogProps {
  farmerIds?: string[],
  onSave: (farmerIds: string[], salespersonIds: string[]) => void,
  salespersonIds?: string[],
  show: boolean,
}

type RecipientType = 'farmer' | 'salesperson';

export const AddRecipientsDialog = ({
  farmerIds = [],
  onSave,
  salespersonIds = [],
  show,
}: AddRecipientsDialogProps) => {
  const [selectedRecipientType, setSelectedRecipientType] = useState<RecipientType>('farmer');
  const [selectedFarmerIds, setSelectedFarmerIds] = useState<string[]>(farmerIds);
  const [selectedSalespersonIds, setSelectedSalespersonIds] = useState<string[]>(salespersonIds);
  const selectedIds = [...selectedFarmerIds, ...selectedSalespersonIds];
  const [showViewAddedRecipientsModal, setShowViewAddedRecipientsModal] = useState(false);

  const { counties } = useCountyList();
  const { getRetailers } = useGetRetailers({ limit: SharedConfig.maxPageLimit, sort: 'name' });

  const retailerOptions = useMemo(() => {
    return getRetailers?.data
      .filter((retailer) => !!(retailer.locationDetails?.length))
      .map((retailer) => (
        {
          id: retailer.id,
          label: retailer.name,
          subOptionLabel: 'Locations',
          subOptions: retailer.locationDetails?.map((location) => (
            {
              id: location.id,
              label: location.name,
            }
          )).sort((a, b) => a.label.localeCompare(b.label)) ?? [],
        }
      )) ?? [];
  }, [getRetailers]);

  const stateAndCounties = groupBy(counties, (county) => county.state);
  const stateAndCountyOptions = Object.keys(stateAndCounties).map((state) => (
    {
      id: state,
      label: state,
      subOptionLabel: 'Counties',
      subOptions: stateAndCounties[state].map((county) => (
        {
          id: county.id,
          label: county.county,
        }
      )),
    }
  )).sort((a, b) => a.label.localeCompare(b.label));

  const selectedFarmersCount = selectedFarmerIds.length;
  const selectedSalespersonsCount = selectedSalespersonIds.length;

  return (
    <>
      <FullscreenDialog
        actionButton={
          <Button
            onClick={() => onSave(selectedFarmerIds, selectedSalespersonIds)}
            testID="action-button"
          >
            Done adding recipients
          </Button>
        }
        contentStyle={{ alignSelf: 'center', width: '100%' }}
        open={show}
        testID="add-recipients-dialog"
        title="Add Recipients"
        titleDescriptor={
          <Button
            onClick={() => setShowViewAddedRecipientsModal(true)}
            startIcon={<People />}
            testID="added-recipients-button"
            variant="text"
          >
            Added recipients ({selectedIds.length})
          </Button>
        }
      >
        <Stack margin="0 auto" width="750px">
          <VSpacer size="6" />
          <Text category="title-small">
            Select recipient type:
          </Text>
          <VSpacer size="3" />
          <SegmentedButton
            color="primary"
            fullWidth
            selectedIndex={selectedRecipientType === 'farmer' ? 0 : 1}
          >
            <Button
              aria-selected={selectedRecipientType === 'farmer'}
              onClick={() => setSelectedRecipientType('farmer')}
              startIcon={selectedRecipientType === 'farmer' && <Check />}
              testID="farmer-recipient-button"
            >
              Farmers{selectedFarmersCount ? ` (${selectedFarmersCount})` : ''}
            </Button>
            <Button
              aria-selected={selectedRecipientType === 'salesperson'}
              onClick={() => setSelectedRecipientType('salesperson')}
              startIcon={selectedRecipientType === 'salesperson' && <Check />}
              testID="salesperson-recipient-button"
            >
              Salespersons{selectedSalespersonsCount ? ` (${selectedSalespersonsCount})` : ''}
            </Button>
          </SegmentedButton>
          <VSpacer size="6" />
          <Divider />
          <VSpacer size="6" />
          {selectedRecipientType === 'farmer' ? (
            <AddFarmerRecipientsDialogContent
              onChange={setSelectedFarmerIds}
              retailerOptions={retailerOptions}
              selectedRecipientIds={selectedFarmerIds}
              stateAndCountyOptions={stateAndCountyOptions}
            />
          ) : (
            <AddSalespersonRecipientsDialogContent
              onChange={setSelectedSalespersonIds}
              retailerOptions={retailerOptions}
              selectedRecipientIds={selectedSalespersonIds}
              stateAndCountyOptions={stateAndCountyOptions}
            />
          )}
        </Stack>
      </FullscreenDialog>
      {showViewAddedRecipientsModal && (
        <ViewAddedRecipientsModal
          onChange={(farmerIds, salespersonIds) => {
            setSelectedFarmerIds(farmerIds);
            setSelectedSalespersonIds(salespersonIds);
          }}
          onClose={() => setShowViewAddedRecipientsModal(false)}
          recipientIds={[...selectedFarmerIds, ...selectedSalespersonIds]}
          testID="view-added-recipients-modal"
        />
      )}
    </>
  );
}
