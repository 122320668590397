import { Button, Input, Text, VSpacer } from '@/components/DesignSystem';
import { useAddRetailer, useEditRetailer } from '@/hooks/useHierarchyOfRetailers';
import { ApiRetailer, UpdateRetailer } from '@api/interfaces';
import { Alert, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ImageInput } from '@/components/shared/ImageInput/ImageInput';
import { validateImage } from '@/pages/Admin/HierarchyOfRetailers/Locations/AddLocation';
import { getObjectChanges, omit, sanitizedFileName, trimValue } from '@shared/utilities';
import { ImageRequirementsHeader } from '@/components/shared/ImageInput/ImageRequirementsHeader';
import { addContrastingBackgroundToPNG } from '@/utilities/Image';

type FormInputs = Pick<ApiRetailer, 'isActive' | 'isFeatured' | 'name'> & {
  connectAccountId?: string | null,
  imageFile?: File | null,
  imageUrl?: string | null,
};

type RetailerFormDialogProps = {
  isAddRetailerOpen: boolean;
  setIsAddRetailerOpen: (isAddRetailerOpen: boolean) => void;
  initialValue?: FormInputs;
};
const RetailerFormDialog = ({
  isAddRetailerOpen,
  initialValue = {
    connectAccountId: '',
    imageFile: null,
    imageUrl: null,
    isActive: true,
    isFeatured: false,
    name: '',
  },
  setIsAddRetailerOpen,
}: RetailerFormDialogProps) => {

  const [retailer, setRetailer] = useState(initialValue);
  const [error, setError] = useState('');
  const [imageError, setImageError] = useState<string | null>(null);

  const { id } = useParams();

  const isEditDisabled = Object.entries(retailer).every(
    ([key, value]: [string, string | boolean | File | undefined | null]) =>
      initialValue[key as keyof FormInputs] === value,
  );

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'name') {
      setError('');
    }
    setRetailer({
      ...retailer,
      [e.target.name]: e.target.value.trimStart(),
    });
  };

  const onChangeImage = async (file: File) => {
    try {
      await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const image = new Image();
          image.src = reader.result as string;
          image.onload = () => {
            const imageError = validateImage(image, file);
            if (imageError) {
              reject(imageError);
            }
            resolve(reader.result as string);
          };
        };
        reader.readAsDataURL(file as Blob)
      });
    } catch (error) {
      setImageError(error as string);
      return;
    }
    setRetailer({
      ...retailer,
      imageFile: file,
      imageUrl: URL.createObjectURL(file as Blob),
    });
    setImageError(null);
  }

  const onClose = () => {
    setIsAddRetailerOpen(false);
  };

  const { addRetailer, isLoading } = useAddRetailer(setIsAddRetailerOpen, setError);
  const { editRetailer, isLoading: isEditLoading } = useEditRetailer(
    id ?? '',
    setIsAddRetailerOpen,
    setError,
  );

  const onSave = async () => {
    let imageBase64: string | null = null;
    let imageFilename: string | null = null;
    if (retailer.imageFile) {
      imageBase64 = await new Promise<string>((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result as string;
          img.onload = () => {
            const imgWithBackground = addContrastingBackgroundToPNG(img);
            resolve(imgWithBackground);
          };
        };
        reader.readAsDataURL(retailer.imageFile as Blob)
      });
      imageFilename = sanitizedFileName(retailer.imageFile.name);
    }

    if (imageError) {
      return;
    }

    const trimmedInputs = Object.fromEntries(
      Object.entries(retailer).map(([key, nestedValue]) =>
        [key, trimValue(nestedValue)]),
    ) as FormInputs;

    const changedInputs = getObjectChanges(
      initialValue,
      omit(trimmedInputs, ['imageFile', 'imageUrl']),
    ) as UpdateRetailer;

    if (imageBase64) {
      changedInputs.image = `${imageFilename}+${imageBase64}`;
    }
    if (!retailer.imageUrl && initialValue.imageUrl) {
      changedInputs.image = null;
    }

    if (id) {
      editRetailer(changedInputs);
    } else {
      addRetailer({
        ...changedInputs,
        name: changedInputs?.name?.toString() ?? '',
      });
    }
  };

  return (
    <Dialog fullWidth onClose={onClose} open={isAddRetailerOpen}>
      <DialogContent>
        <Stack spacing={3}>
          {!!error && (
            <Alert color='error' icon={false}>{error}</Alert>
          )}
          <Text category='h6'>Add Retailer</Text>
          <Input
            label='Retailer Name'
            name='name'
            onChange={onInputChange}
            required
            testID='hierarchy-retailer-name'
            value={retailer.name}
          />
          <Stack spacing={3}>
            <Text category='h6'>Payment</Text>
            <Input
              label='Stripe Connect Account ID'
              name='connectAccountId'
              onChange={onInputChange}
              testID='hierarchy-retailer-connect-id'
              value={retailer?.connectAccountId}
            />
          </Stack>
          {!!imageError && (
            <Text sx={{ color: 'red' }}>
              {imageError}
            </Text>
          )}
          <ImageRequirementsHeader />
          <ImageInput
            imageSrc={retailer.imageUrl}
            onChangeImage={onChangeImage}
            onRemove={() => setRetailer({ ...retailer, imageFile: null, imageUrl: null })}
          />
        </Stack>
      </DialogContent>
      <VSpacer size='8' />
      <DialogActions>
        <Button
          disabled={isLoading || isEditLoading}
          onClick={onClose}
          style={{
            color: 'white',
          }}
          testID='hierarchy-retailer-cancel'
          variant='text'
        >
          Cancel
        </Button>
        <Button
          disabled={!retailer?.name
            || isEditDisabled || isLoading || isEditLoading || !!error}
          loading={isLoading || isEditLoading}
          onClick={onSave}
          testID='hierarchy-retailer-save'
        >
          Save
        </Button>
      </DialogActions>
      <VSpacer size='5' />
    </Dialog>
  );
};

export default RetailerFormDialog;
