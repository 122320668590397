export enum RecommendationFarmerStatus {
  /** @deprecated DB rows never have an Expired value. This is only calculated in ConvertToApi.
      TODO: Remove/simplify. */
  Expired = 'EXPIRED',
  Ignored = 'IGNORED',
  New = 'NEW',
  Viewed = 'VIEWED',
}

export enum RecommendationFarmerStatusFilterList {
  Expired = 'Expired',
  Ignored = 'Ignored',
  New = 'New',
  Ordered = 'Ordered',  
}
