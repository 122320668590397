import { ToastMessages } from '@/constants/constant';
import { QueryKeys } from '@/constants/QueryKeys';
import { useSearch } from '@/hooks/useSearch';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';
import { RetailerLocationApi } from '@/utilities/api/RetailerLocationApi';
import { UserApi } from '@/utilities/api/UserApi';
import { ApiUser } from '@api/interfaces';
import { UserType } from '@shared/enums';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';

interface useSelectFarmersProps {
  isSelectFarmers: boolean;
  locationId: string;
  setIsSelectFarmers: (args: boolean) => void;
}
export const useSelectFarmers = ({
  isSelectFarmers,
  locationId,
  setIsSelectFarmers,
}: useSelectFarmersProps) => {
  const queryClient = useQueryClient();
  const [selectedFarmers, setSelectedFarmers] = useState<ApiUser[]>([]);

  const { openSnackbar } = useSnackbar();
  const { search, setSearch, debouncedSearch } = useSearch();

  let page = 0;
  const {
    data: allFarmersData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [QueryKeys.GET_FARMERS, debouncedSearch, locationId, isSelectFarmers],
    () => {
      return UserApi.list({
        locationId: [locationId],
        userType: [UserType.Farmer],
        search: debouncedSearch,
        page,
      });
    },
    {
      onSuccess: (data) => {
        const thisPage = data.pages[data.pages.length - 1].data as ApiUser[];
        setSelectedFarmers((prev) => {
          return [
            ...prev,
            ...thisPage.filter((farmer) => (
              farmer.farmerLocationSettings?.some(
                ({ isPreferred, locationId: locId }) => (
                  locId === locationId && isPreferred
                ),
              )
            )),
          ];
        });
      },
      enabled: !!locationId && isSelectFarmers,
      getNextPageParam: (lastPage) => {
        if (lastPage.page < lastPage.lastPage) {
          page = lastPage.page + 1;
          return page;
        }
        return undefined;
      },
    },
  );

  const observer = useRef<IntersectionObserver | null>(null);
  const lastItemRef = useRef<HTMLLIElement | null>(null);

  const handleFetchNextPage = useCallback(async (entries: IntersectionObserverEntry[]) => {
    const target = entries[0];
    if (target.isIntersecting && hasNextPage && !isFetchingNextPage) {
      await fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  useEffect(() => {
    let observerRefValue: Element | null = null;
    observer.current = new IntersectionObserver(handleFetchNextPage, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    });

    if (lastItemRef.current) {
      observer.current.observe(lastItemRef.current);
      observerRefValue = lastItemRef.current;
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
      if (observerRefValue) {
        observer.current?.unobserve(observerRefValue);
      }
    };
  }, [lastItemRef, hasNextPage, isFetchingNextPage, fetchNextPage, handleFetchNextPage]);

  const { mutate: saveFarmersByIds, isLoading: isSaveFarmersLoading } =
    useMutation(
      async (userIds: string[]) => RetailerLocationApi.updatePreferredFarmers(locationId, userIds),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            QueryKeys.GET_RETAILER_FARMERS_BY_ID,
          ]);
          openSnackbar(ToastMessages.farmersSaveSuccess);
          setIsSelectFarmers(false);
        },
        onError: (error: DetailedApiError) => {
          if (error?.code == '500') {
            return openSnackbar('Something went wrong');
          }
          return openSnackbar(error?.message);
        },
      },
    );

  return {
    selectedFarmers,
    setSelectedFarmers,
    setSearch,
    search,
    allFarmersData,
    saveFarmersByIds,
    isSaveFarmersLoading,
    lastItemRef,
    openSnackbar,
  }
};
