import { Button, Input, MenuItem, Modal, Select, VSpacer } from '@/components/DesignSystem';
import { QueryKeys } from '@/constants/QueryKeys';
import { useSearch } from '@/hooks/useSearch';
import { useUser } from '@/hooks/useUser';
import { NotificationApi } from '@/utilities/api/NotificationApi';
import { ApiUserPublic } from '@api/interfaces';
import HighlightOff from '@mui/icons-material/HighlightOff';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { PushNotificationRedirect, UserType } from '@shared/enums';
import { formatPhoneNumber, getFarmerName } from '@shared/utilities';
import React, { Fragment, useState } from 'react';
import { useQuery } from 'react-query';

interface SendTestNotificationModalProps {
  onClose: () => void,
}

export const SendTestNotificationModal = ({
  onClose,
}: SendTestNotificationModalProps) => {
  const { setSearch, debouncedSearch } = useSearch();
  const [selectedUser, setSelectedUser] = useState<ApiUserPublic | null>(null);
  const [message, setMessage] = useState<string>();
  const [image, setImage] = useState<string>();
  const [redirectPath, setRedirectPath] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [replacement, setReplacement] = useState<string>();

  const { users, isFetching: isUsersLoading } = useUser({
    isTest: true,
    search: debouncedSearch,
    userType: [UserType.Farmer, UserType.SalesPerson, UserType.RetailerAdmin],
  });

  const { data: notificationValues, isFetching: isValuesLoading } = useQuery(
    [QueryKeys.GET_NOTIFICATION_VALUES],
    () => NotificationApi.getValues(),
  );

  const images = notificationValues?.images.sort() ?? [];
  const redirectPaths = Object.values(PushNotificationRedirect);
  const redirectPathPlaceholder = redirectPath?.match(/#[A-z]+#/)?.[0];
  const isFormValid = !!(
    selectedUser
    && message
    && description
    && redirectPath
    && !(redirectPathPlaceholder && !replacement)
  );

  const onSendClicked = async () => {
    if (isFormValid) {
      let path = redirectPath;
      if (redirectPathPlaceholder && replacement) {
        path = path.replace(redirectPathPlaceholder, replacement)
      }
      await NotificationApi.sendTestNotification({
        image,
        message,
        redirectPath: path,
        title: description,
        userId: selectedUser.id,
      });
    }
  };
  return (
    <Modal
      acceptButton={(props) => {
        return (
          <Button
            {...props}
            disabled={!isFormValid}
            loading={isUsersLoading || isValuesLoading}
            onClick={onSendClicked}
          >
            Send
          </Button>
        );
      }}
      cancelButton={(props) => (
        <Button onClick={onClose} {...props}>
          Cancel
        </Button>
      )}
      onClose={onClose}
      open
      testID="send-test-notification-modal"
      title="Send Test Notification"
    >
      <Autocomplete
        clearIcon={<HighlightOff />}
        disablePortal
        getOptionLabel={(user) => (
          `${(user.firstName ? getFarmerName(user) : user.businessName)
            || formatPhoneNumber(user.telephone ?? '')}`
        )}
        loading={isUsersLoading}
        onChange={(_, value) => {
          setSelectedUser(value);
        }}
        onInputChange={(e, value, reason) => {
          if (reason === 'input' || reason === 'clear') {
            setSearch(value);
          }
        }}
        options={users?.data ?? []}
        renderInput={(params) => (
          <TextField
            label='Select User'
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {isUsersLoading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
      />
      <VSpacer size="5" />
      <Input
        label="Message"
        onChangeText={setMessage}
        required
        testID="send-test-notification-message-input"
        value={message}
      />
      <VSpacer size="5" />
      <Input
        label="Description"
        onChangeText={setDescription}
        required
        testID="send-test-notification-description-input"
        value={description}
      />
      <VSpacer size="5" />
      <Select
        label="Image"
        onChangeValue={setImage}
        testID="send-test-notification-image-select"
        value={image}
      >
        {images.map((image) => (
          <MenuItem
            key={image}
            testID={`${image}-menu-item`}
            value={image}
          >
            {image}
          </MenuItem>
        ))}
      </Select>
      <VSpacer size="5" />
      <Select
        label="Redirect Path"
        onChangeValue={setRedirectPath}
        required
        testID="send-test-notification-redirect-path-select"
        value={redirectPath}
      >
        {redirectPaths.map((path) => (
          <MenuItem
            key={path}
            testID={`${path}-menu-item`}
            value={path}
          >
            {path}
          </MenuItem>
        ))}
      </Select>

      {redirectPathPlaceholder &&
        <>
          <VSpacer size="5"/>
          <Input
            label={`Replacement for ${redirectPathPlaceholder}`}
            onChangeText={setReplacement}
            required={!!redirectPathPlaceholder}
            testID="send-test-notification-replacement-input"
            value={replacement}
          />
        </>
      }
    </Modal>
  )
}
