import { Button, Modal } from '@/components/DesignSystem';
import { ApiRecommendationFarmer } from '@api/interfaces';
import { Stack } from '@mui/material';
import { FarmerCard } from "@/components/DesignSystem/FarmerCard/FarmerCard";

interface ProductRecommendationFarmersModalProps {
  onClose: () => void;
  farmers: ApiRecommendationFarmer[];
}

export const ProductRecommendationFarmersModal = ({
  onClose,
  farmers,
}: ProductRecommendationFarmersModalProps) => {
  return (
    <Modal
      cancelButton={() => (
        <Button onClick={onClose} testID="prfm-cancel-button" variant="text">
          Close
        </Button>
      )}
      onClose={onClose}
      open
      testID="product-recommendation-farmers-modal"
      title="Shared with farmers"
    >
      <Stack spacing={1}>
        {farmers.map(farmer => (
          <FarmerCard
            farmer={farmer}
            key={farmer.id}
            showTelephone="inline"
            testID={`${farmer.id}-farmer-card`}
          />
        ))}
      </Stack>
    </Modal>
  );
};
