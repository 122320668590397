import { useMutation, useQuery } from 'react-query';
import { Button, Modal, Text, TextProps } from '@/components/DesignSystem';
import { PricingRequestsApi } from '@/utilities/api/PricingRequestsApi';
import { PricingRequestStatus } from '@shared/enums';
import { useState } from 'react';
import PricingRequestDetailsReadOnly from '../PricingRequestDetailsReadOnly';
import PricingRequestStatusChip from "@/components/shared/PricingRequestStatusChip";
import { Link, Stack } from '@mui/material';
import { QueryKeys } from '@/constants/QueryKeys';
import { DateTime } from 'luxon';
import {
  getExpirationStatus,
  getFarmerName,
  hoursUntil,
  timeRemainingShort,
} from '@shared/utilities';
import ExtendPricingRequestModal from '../ExtendPricingRequestModal';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { ButtonProps } from '@/components/DesignSystem/Button/Button';
import { PricingRequestEndpoint } from '@api/endpoints';
import ConfirmModal from '@/components/shared/ConfirmModal';

interface AdminViewPricingRequestProps {
  onClose: () => void,
  pricingRequestId: string,
}

const AdminViewPricingRequest = ({
  onClose,
  pricingRequestId,
}: AdminViewPricingRequestProps) => {
  const [showExtendWindowModal, setShowExtendWindowModal] = useState(false);
  const [
    showConfirmSubmitAndApprove,
    setShowConfirmSubmitAndApprove,
  ] = useState(false);

  const { openSnackbar } = useSnackbar();

  const { data: pricingRequest, isFetching, refetch } = useQuery(
    [QueryKeys.GET_PRICING_REQUEST, pricingRequestId],
    () => PricingRequestsApi.getPricingRequest(pricingRequestId),
    {
      onError: (error: { message: string }) => {
        openSnackbar(
          error.message || 'An error has occurred',
        );
      },
    },
  );

  const { mutate: approvePricingRequest, isLoading: approving } = useMutation(
    (requestId: string) => PricingRequestsApi.approvePricingRequest(requestId),
    {
      onError: (error: { message: string }) => {
        openSnackbar(
          error.message || 'An error has occurred',
        );
      },
      onSuccess: async () => {
        refetch(); // eslint-disable-line @typescript-eslint/no-floating-promises
      },
    },
  );

  const { mutate: rejectPricingRequest, isLoading: rejecting } = useMutation(
    (requestId: string) => PricingRequestsApi.rejectPricingRequest(requestId),
    {
      onError: (error: { message: string }) => {
        openSnackbar(
          error.message || 'An error has occurred',
        );
      },
      onSuccess: async () => {
        refetch(); // eslint-disable-line @typescript-eslint/no-floating-promises
      },
    },
  );

  const { mutateAsync: submitPriceRequest, isLoading: submitting } = useMutation(
    (requestId: string) => {
      return PricingRequestsApi.updatePricingRequest(requestId, {
        status: PricingRequestStatus.Open,
      } as PricingRequestEndpoint.Update.Request);
    },
    {
      onError: (error: { message: string }) => {
        openSnackbar(error.message || "An error has occurred");
      },
    },
  );

  const submitAndApprove = async () => {
    if (!pricingRequest || pricingRequest?.status !== PricingRequestStatus.Draft) {
      return;
    }

    setShowConfirmSubmitAndApprove(false);
    await submitPriceRequest(pricingRequest.id);
    approvePricingRequest(pricingRequest.id);
  }

  const isPriceRequestValid = () => {
    if (!pricingRequest) {
      return false;
    }

    if (!pricingRequest.salespersons?.length) {
      return false;
    }

    if (!pricingRequest.products?.length) {
      return false;
    }

    if (!pricingRequest.paymentType) {
      return false
    }

    return true;
  }

  const expirationDetails = (props: TextProps) => {

    if (!pricingRequest) {
      return undefined;
    }

    const showExtendWindowLink = [
      PricingRequestStatus.Open,
      PricingRequestStatus.Review,
    ].includes(pricingRequest.status as PricingRequestStatus);

    const expirationTextBuilder: string[] = [];

    const expirationDate = pricingRequest.expiration ? (
      DateTime.fromISO(pricingRequest.expiration as unknown as string).toJSDate()
    ) : undefined;

    if (pricingRequest.status === PricingRequestStatus.Review
      && expirationDate
      && pricingRequest.reviewExpiration
    ) {
      const reviewExpirationDate = (
        DateTime.fromISO(pricingRequest.reviewExpiration as unknown as string).toJSDate()
      );
      
      const daysUntilExpiration = timeRemainingShort(hoursUntil(reviewExpirationDate));
      expirationTextBuilder.push(`Offer window closed ${expirationDate?.toLocaleDateString()}`);
      expirationTextBuilder.push(`Farmer Review expires in ${daysUntilExpiration} on ${reviewExpirationDate.toLocaleDateString()}`);
    } else {
      if (!expirationDate) {
        expirationTextBuilder.push('No expiration date')
      } else {
        expirationTextBuilder.push(
          getExpirationStatus(expirationDate),
          expirationDate.toLocaleDateString(),
        );
      }
    }

    return (
      <Stack alignItems="center" direction="row">
        <Text {...props}>{expirationTextBuilder.join(' | ')}</Text>
        {showExtendWindowLink && (
          <>
            <Text {...props}>&nbsp;|&nbsp;</Text>
            <Link
              onClick={() => setShowExtendWindowModal(true)}
              sx={{ cursor: 'pointer' }}
            >
              Extend window?
            </Link>
          </>
        )}
      </Stack>
    )
  };

  const showApproveRejectButtons = (
    !!pricingRequest
    && !pricingRequest.approvedDate
    && !pricingRequest.rejectedDate
    && pricingRequest.status !== PricingRequestStatus.Closed
    && pricingRequest.status !== PricingRequestStatus.Draft
  );

  const ApproveButton = (props: ButtonProps) => {
    if (pricingRequest?.status === PricingRequestStatus.Draft) {
      return (
        <Button
          disabled={!isPriceRequestValid()}
          onClick={() => setShowConfirmSubmitAndApprove(true)} {...props}
        >
          Approve & Submit
        </Button>
      );
    }
    if (showApproveRejectButtons) {
      return (
        <Button onClick={() => approvePricingRequest(pricingRequest.id)} {...props}>
          Allow Request
        </Button>
      );
    }
  };

  const RejectButton = (props: ButtonProps) => {
    if (showApproveRejectButtons) {
      return (
        <Button onClick={() => rejectPricingRequest(pricingRequest.id)} {...props}>
          Reject Request
        </Button>
      );
    }
  };

  return (
    <>
      {!!pricingRequest && (
        <Modal
          acceptButton={ApproveButton}
          cancelButton={(props) => (
            <Button onClick={onClose} {...props}>
              Close
            </Button>
          )}
          headerAccessoryRight={
            <PricingRequestStatusChip
              paymentStatus={pricingRequest.paymentStatus}
              status={pricingRequest.status}
            />
          }
          largeModal
          loading={isFetching || approving || rejecting || submitting}
          onClose={onClose}
          open
          secondaryActionButton={RejectButton}
          subline={expirationDetails}
          testID="admin-view-pricing-request-modal"
          title={`Offer Request #${pricingRequest.publicId}`}
        >
          <PricingRequestDetailsReadOnly pricingRequest={pricingRequest} />
        </Modal>
      )}
      {showExtendWindowModal && !!pricingRequest && (
        <ExtendPricingRequestModal
          onClose={() => {
            setShowExtendWindowModal(false);
            refetch(); // eslint-disable-line @typescript-eslint/no-floating-promises
          }}
          pricingRequest={pricingRequest}
        />
      )}
      <ConfirmModal
        confirmButtonText="Yes, approve and submit"
        message={`This Product Request belonging to ${getFarmerName(pricingRequest?.user, true)}
          will be approved and submitted to the retailers indicated. The farmer will
          receive an email confirmation at ${pricingRequest?.user?.email}.`
        }
        onCancel={() => setShowConfirmSubmitAndApprove(false)}
        onConfirm={submitAndApprove}
        open={showConfirmSubmitAndApprove}
        testID="confirm-submit-and-approve-modal"
        title="Approve and submit Product Request?"
      />
    </>
  )
}

export default AdminViewPricingRequest;
