export const stringifyCount = (count: number = 0, singular: string, plural: string) => {
  if(count < 0)
    throw new Error(`${singular} count must be greater than or equal to 0`);
  return `${count} ${count <= 1 ? singular : plural}`;
}

interface GetCountsText {
  activeCount: number,
  isActive: boolean | string,
  selectedTabText: string,
  total: number,
}

export const getCountsText = ({
  activeCount = 0,
  isActive,
  selectedTabText,
  total = 0,
} : GetCountsText) => {

  const inactiveCount = Math.max(total - activeCount, 0);

  const activeCountText = stringifyCount(activeCount, 'is active', 'are active');
  const inActiveCountText = stringifyCount(inactiveCount, 'is inactive', 'are inactive');
  const totalCountText = stringifyCount(total, selectedTabText, `${selectedTabText}s`);

  switch (isActive) {
    case true:
      return `${totalCountText}; ${activeCountText}`;
    case false:
      return `${totalCountText}; ${inActiveCountText}`;
    case 'all':
      return `${totalCountText}`;
  }
}

export const ADD_LOCATION_POP_UP = 'Activate the retailer before adding a new location';
export const MARK_AS_ELIGIBLE = 'Mark this retailer as eligible to add farmers.'
export const TOGGLE_LOCATION_POP_UP = 'Activate the retailer before changing location status';
