import { Button, Card, HSpacer, IconButton, Text, VSpacer } from '@/components/DesignSystem';
import { ApiProduct } from '@api/interfaces';
import { Box, Skeleton, Stack, SxProps } from '@mui/material';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import Add from '@mui/icons-material/Add';
import { QueryKeys } from '@/constants/QueryKeys';
import { useQuery } from 'react-query';
import { ProductApi } from '@/utilities/api/ProductApi';
import { getProductImageUrl } from '@/utilities/Product';
import { useShoppingCart } from '@/hooks/useShoppingCart';
import Check from '@mui/icons-material/Check';

const styles: SXStyles = {
  card: {
    borderRadius: '12px',
    flex: '0 1 auto',
    height: '100%',
    maxWidth: '240px',
    minWidth: '108px',
  },
  image: {
    borderRadius: '16px',
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  truncateText: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    wordBreak: 'break-word',
  },
} as const;

interface ProductCardProps {
  onClick?: () => void,
  onQuickAdd?: () => void,
  product: ApiProduct,
  sx?: SxProps,
  testID: string,
}

export const ProductCard = ({
  onClick,
  onQuickAdd,
  product,
  sx,
  testID,
}: ProductCardProps) => {
  const { shoppingCart } = useShoppingCart();

  const { data: productDetails, isLoading } = useQuery(
    [QueryKeys.GET_PRODUCT_DETAILS, product.id],
    async () => {
      return (await ProductApi.get(product.id));
    },
    {
      enabled: !!product,
    },
  );

  const manufacturerName = productDetails?.manufacturer?.name ?? null;
  const imageUrl = getProductImageUrl(product.image);

  const productIsInCart = shoppingCart.some((item) => item.productId === product.id);

  return (
    <Card
      cardContentStyle={{ display: 'flex', height: '100%', padding: '16px 16px 20px' }}
      onClick={onClick}
      sx={{
        ...styles.card,
        cursor: onClick ? 'pointer' : 'default',
        ...sx,
      } as SxProps}
      testID={testID}
    >
      <Stack flex={1} justifyContent="space-between">
        <Stack>
          <Stack alignItems="center" flex={1} justifyContent="flex-start" width="100%">
            <Box
              alt={`Image of ${product?.name}`}
              component="img"
              data-testid={`${testID}-image`}
              src={imageUrl}
              sx={styles.image}
            />
          </Stack>
          <VSpacer size="5" />
          {isLoading ? (
            <Skeleton height={12} />
          ) : (
            <>
              <Text category="title-medium" sx={styles.truncateText} testID={`${testID}-name`}>
                {product?.name ?? ''}
              </Text>
              <VSpacer size="2" />
              <Text category="body-medium" sx={styles.truncateText} testID={`${testID}-manufacturer`}>
                {manufacturerName ?? ''}
              </Text>
            </>
          )}
          <VSpacer size={manufacturerName ? '2' : '7'} />
        </Stack>
        <Stack alignItems="flex-end">
          {productIsInCart ? (
            <Button
              sx={{ pointerEvents: 'none' }}
              testID={`${testID}-added`}
              variant="text"
            >
              <Check sx={{ width: '18px', height: '18px' }} />
              <HSpacer size="3" />
              In cart
            </Button>
          ) : (
            <IconButton
              onClick={(e) => {
                onQuickAdd?.();
                e.stopPropagation();
              }}
              size="small"
              testID={`${testID}-add-button`}
              variant="filled"
            >
              <Add/>
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Card>
  )
}
