import {
  Button,
  HSpacer,
  IconButton,
  Modal,
  ProgressLoader,
  Search,
  Tabs,
  Text,
  TextLink,
  VSpacer,
} from '@/components/DesignSystem';
import { AppConfig } from '@/constants/AppConfig';
import { useSearch } from '@/hooks/useSearch';
import { useUser } from '@/hooks/useUser';
import { ApiRetailer, ApiRetailerLocation, ApiUser } from '@api/interfaces';
import ArrowBack from '@mui/icons-material/ArrowBack';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Store from '@mui/icons-material/Store';
import { Avatar, Box, Divider, Stack, Tab } from '@mui/material';
import { State, UserType } from '@shared/enums';
import { getFarmerName, localizeNumber } from '@shared/utilities';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

interface ViewAddedRecipientsModalProps {
  onChange?: (farmerIds: string[], salespersonIds: string[]) => void,
  onClose: () => void,
  recipientIds?: string[],
  testID: string,
}

export const ViewAddedRecipientsModal = ({
  onChange,
  onClose,
  recipientIds = [],
  testID,
}: ViewAddedRecipientsModalProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedState, setSelectedState] = useState<State | null | undefined>(undefined);
  const [selectedRetailer, setSelectedRetailer] = useState<ApiRetailer | undefined>(undefined);
  const [selectedLocation, setSelectedLocation]
    = useState<ApiRetailerLocation | undefined>(undefined);
  const { debouncedSearch, search, setSearch } = useSearch();
  const viewOnly = !onChange;

  const currentPage = useMemo(() => {
    if (selectedState !== undefined) {
      return 'state';
    } else if (selectedLocation !== undefined) {
      return 'location';
    } else if (selectedRetailer !== undefined) {
      return 'retailer';
    }
    return undefined;
  }, [selectedState, selectedLocation, selectedRetailer]);

  const recipientCount = recipientIds.length;

  const { users, isFetching } = useUser(
    { id: recipientIds, search: debouncedSearch },
    true,
    !!recipientCount,
  );

  const apiUsers = (users?.data ?? []) as ApiUser[];

  const farmers = apiUsers.filter(({ userType }) => userType === UserType.Farmer);
  const farmerIds = farmers.map((farmer) => farmer.id) ?? [];
  const salespersons = apiUsers.filter(({ userType }) => userType === UserType.SalesPerson);
  const salespersonIds = salespersons.map((salesperson) => salesperson.id) ?? [];

  const farmerStates = [...new Set(farmers.map(({ state }) => state ?? null) ?? [])];
  farmerStates.sort((a, b) => (a ?? '').localeCompare(b ?? ''));
  const farmersInSelectedState = farmers.filter((farmer) => farmer.state === selectedState);
  // Sort farmers by preferred location, then first name
  farmersInSelectedState.sort((a, b) => {
    const aPreferredLocationName = a.farmerLocationSettings
      ?.find((setting) => setting.isPreferred)
      ?.location?.name;
    const bPreferredLocationName = b.farmerLocationSettings
      ?.find((setting) => setting.isPreferred)
      ?.location?.name;
    if (
      aPreferredLocationName && bPreferredLocationName
      && aPreferredLocationName !== bPreferredLocationName
    ) {
      return aPreferredLocationName.localeCompare(bPreferredLocationName);
    } else if (a.firstName && b.firstName) {
      return a.firstName.localeCompare(b.firstName);
    }
    return 0;
  });
  // Sort salespersons by retailer, then location, then business name
  salespersons.sort((a, b) => {
    if (
      a.userRetailer?.name && b.userRetailer?.name
      && a.userRetailer.name !== b.userRetailer.name
    ) {
      return a.userRetailer.name.localeCompare(b.userRetailer.name);
    } else if (
      a.userLocation?.name && b.userLocation?.name
      && a.userLocation.name !== b.userLocation.name
    ) {
      return a.userLocation.name.localeCompare(b.userLocation.name);
    } else if (a.businessName && b.businessName) {
      return a.businessName.localeCompare(b.businessName);
    } else if (a.firstName && b.firstName) {
      return a.firstName.localeCompare(b.firstName);
    }
    return 0;
  });
  const retailers: ApiRetailer[] = [];
  salespersons.forEach(({ userRetailer }) => {
    if (userRetailer && !retailers.some(({ id }) => id === userRetailer.id)) {
      retailers.push(userRetailer);
    }
  });
  const locations: ApiRetailerLocation[] = [];
  salespersons.forEach(({ userLocation }) => {
    if (userLocation && !locations.some(({ id }) => id === userLocation.id)) {
      locations.push(userLocation);
    }
  });
  locations.sort((a, b) => a.name.localeCompare(b.name));
  const locationsInSelectedRetailer = locations.filter((location) => (
    location.retailerId === selectedRetailer?.id
  ));
  const salespersonsInSelectedLocation = salespersons.filter((salesperson) => (
    salesperson.locationId === selectedLocation?.id
  ));

  const getStateFarmerCount = useCallback((state: State | null): number => {
    return farmers.filter((farmer) => farmer.state === state).length ?? 0;
  }, [farmers]);

  const getRetailerSalespersonCount = useCallback((retailer: ApiRetailer): number => {
    return salespersons.filter((salesperson) => (
      salesperson.retailerId === retailer.id
    )).length ?? 0;
  }, [salespersons]);

  const getLocationSalespersonCount = useCallback((location: ApiRetailerLocation): number => {
    return salespersons.filter((salesperson) => (
      salesperson.locationId === location.id
    )).length ?? 0;
  }, [salespersons]);

  const getFarmerInfoText = (farmer: ApiUser) => {
    let line = '';
    if (farmer.businessName) {
      line += farmer.businessName;
    }
    if (farmer.businessName && farmer.countyData?.county) {
      line += ' • ';
    }
    if (farmer.countyData?.county) {
      line += farmer.countyData.county;
      if (farmer.state) {
        line += ', ';
      }
    }
    if (farmer.state) {
      line += farmer.state;
    }
    return line;
  }

  const getFarmerPreferredRetailer = (farmer: ApiUser) => {
    return farmer.farmerLocationSettings
      ?.find((setting) => setting.isPreferred)
      ?.location?.retailer;
  }

  useEffect(() => {
    if (isFetching) return;
    if (currentPage === 'state' && getStateFarmerCount(selectedState!) === 0) {
      setSelectedState(undefined);
    }
  }, [currentPage, getStateFarmerCount, isFetching, selectedState]);
  useEffect(() => {
    if (isFetching) return;
    if (currentPage === 'retailer' && getRetailerSalespersonCount(selectedRetailer!) === 0) {
      setSelectedRetailer(undefined);
    }
  }, [currentPage, getRetailerSalespersonCount, isFetching, selectedRetailer]);
  useEffect(() => {
    if (isFetching) return;
    if (currentPage === 'location' && getLocationSalespersonCount(selectedLocation!) === 0) {
      setSelectedLocation(undefined);
    }
  }, [currentPage, getLocationSalespersonCount, isFetching, selectedLocation]);

  const showBackButton = !!currentPage;
  const handleClickBack = () => {
    switch (currentPage) {
      case 'state':
        setSelectedState(undefined);
        break;
      case 'retailer':
        setSelectedRetailer(undefined);
        break;
      case 'location':
        setSelectedLocation(undefined);
        break;
    }
  }

  const handleClickRemoveAll = () => {
    switch (currentPage) {
      case 'state':
        handleRemoveState(selectedState!);
        break;
      case 'retailer':
        handleRemoveRetailer(selectedRetailer!);
        break;
      case 'location':
        handleRemoveLocation(selectedLocation!);
        break;
    }
  }

  const handleRemoveFarmer = (id: string) => {
    const newFarmerIds = farmerIds.filter((recipientId) => recipientId !== id);
    onChange?.(newFarmerIds, salespersonIds);
  }

  const handleRemoveSalesperson = (id: string) => {
    const newSalespersonIds = salespersonIds.filter((salespersonId) => salespersonId !== id);
    onChange?.(farmerIds, newSalespersonIds);
  }

  const handleRemoveState = (state: State | null) => {
    const newFarmerIds = farmers.filter((farmer) => farmer.state !== state).map(({ id }) => id);
    if (newFarmerIds !== undefined) {
      onChange?.(newFarmerIds, salespersonIds);
    }
  }

  const handleRemoveRetailer = (retailer: ApiRetailer) => {
    const newSalespersonIds = salespersonIds.filter((salespersonId) => {
      const salesperson = salespersons.find(({ id }) => id === salespersonId);
      return salesperson?.retailerId !== retailer.id;
    });
    onChange?.(farmerIds, newSalespersonIds);
  }

  const handleRemoveLocation = (location: ApiRetailerLocation) => {
    const newSalespersonIds = salespersonIds.filter((salespersonId) => {
      const salesperson = salespersons.find(({ id }) => id === salespersonId);
      return salesperson?.locationId !== location.id;
    });
    onChange?.(farmerIds, newSalespersonIds);
  }

  const title = useMemo(() => {
    if (selectedState !== undefined) {
      return `${selectedState ?? 'Unverified Accounts'} (${localizeNumber(getStateFarmerCount(selectedState))})`;
    } else if (selectedLocation !== undefined) {
      return `${selectedLocation.name} (${localizeNumber(getLocationSalespersonCount(selectedLocation))})`;
    } else if (selectedRetailer !== undefined) {
      return `${selectedRetailer.name} (${localizeNumber(getRetailerSalespersonCount(selectedRetailer))})`;
    }
    return `${viewOnly ? 'View' : 'Added'} Recipients (${recipientCount})`;
  }, [
    getLocationSalespersonCount, getRetailerSalespersonCount, getStateFarmerCount, recipientCount,
    selectedLocation, selectedRetailer, selectedState, viewOnly,
  ]);

  const showRemoveAllButton = !!currentPage && !viewOnly;

  const FarmerTab = (
    <Stack gap="4px">
      <Divider />
      {farmerStates.map((state) => (
        <Fragment key={state}>
          <Stack
            alignItems="center"
            direction="row"
            height="56px"
            justifyContent="space-between"
          >
            <Text category="title-medium">
              {state ?? 'Unverified Accounts'}
            </Text>
            <Stack alignItems="center" direction="row">
              {!viewOnly && (
                <TextLink
                  category="label-medium"
                  onClick={() => handleRemoveState(state)}
                  sx={{ padding: '8px 12px' }}
                  testID={`${testID}-remove-all-textlink-${state}`}
                >
                  Remove all
                </TextLink>
              )}
              <Text category="label-medium">
                {localizeNumber(getStateFarmerCount(state))}
                {' '}
                recipient{getStateFarmerCount(state) !== 1 ? 's' : ''}
              </Text>
              <HSpacer size="4" />
              <IconButton
                onClick={() => setSelectedState(state)}
                testID={`${testID}-select-state-button-${state}`}
              >
                <KeyboardArrowRight />
              </IconButton>
            </Stack>
          </Stack>
          <Divider />
        </Fragment>
      ))}
    </Stack>
  );

  const StatePage = (
    <Stack gap="4px">
      <Divider />
      {farmersInSelectedState?.map((farmer) => {
        const preferredRetailer = getFarmerPreferredRetailer(farmer);
        return (
          <>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              key={farmer.id}
              padding="16px 4px"
            >
              <Stack>
                <Text category="body-large">
                  {getFarmerName(farmer)}
                </Text>
                <Text>
                  {getFarmerInfoText(farmer)}
                </Text>
                {!!preferredRetailer && (
                  <Stack direction="row">
                    <Text category="label-small">
                      Preferred retailer:
                    </Text>
                    <HSpacer size="3" />
                    <Text category="body-small">
                      {preferredRetailer.name}
                    </Text>
                  </Stack>
                )}
              </Stack>
              {!viewOnly && (
                <TextLink
                  onClick={() => handleRemoveFarmer(farmer.id)}
                  testID={`${testID}-remove-farmer-textlink-${farmer.id}`}
                >
                  Remove
                </TextLink>
              )}
            </Stack>
            <Divider />
          </>
        );
      })}
    </Stack>
  );

  const SalespersonTab = (
    <Stack gap="4px">
      <Divider />
      {retailers.map((retailer) => (
        <Fragment key={retailer.id}>
          <Stack
            alignItems="center"
            direction="row"
            height="56px"
            justifyContent="space-between"
          >
            <Stack alignItems="center" direction="row">
              <Avatar
                alt={`${retailer.name} logo`}
                src={retailer?.image ? `${AppConfig.staticImageHost}/${retailer.image}` : undefined}
                sx={{ bgcolor: '#EBEBEB' }}
              >
                {retailer?.image ? null : <Store />}
              </Avatar>
              <HSpacer size="4" />
              <Text category="title-medium">
                {retailer.name}
              </Text>
            </Stack>
            <Stack alignItems="center" direction="row">
              {!viewOnly && (
                <TextLink
                  category="label-medium"
                  onClick={() => handleRemoveRetailer(retailer)}
                  sx={{ padding: '8px 12px' }}
                  testID={`${testID}-remove-all-textlink-${retailer.name}`}
                >
                  Remove all
                </TextLink>
              )}
              <Text category="label-medium">
                {localizeNumber(getRetailerSalespersonCount(retailer))}
                {' '}
                recipient{getRetailerSalespersonCount(retailer) !== 1 ? 's' : ''}
              </Text>
              <HSpacer size="4" />
              <IconButton
                onClick={() => setSelectedRetailer(retailer)}
                testID={`${testID}-select-state-button-${retailer.name}`}
              >
                <KeyboardArrowRight />
              </IconButton>
            </Stack>
          </Stack>
          <Divider />
        </Fragment>
      ))}
    </Stack>
  );

  const RetailerPage = (
    <Stack gap="4px">
      <Divider />
      {locationsInSelectedRetailer?.map((location) => (
        <Fragment key={location.id}>
          <Stack
            alignItems="center"
            direction="row"
            height="56px"
            justifyContent="space-between"
          >
            <Text category="title-medium">
              {location.name}
            </Text>
            <Stack alignItems="center" direction="row">
              {!viewOnly && (
                <TextLink
                  category="label-medium"
                  onClick={() => handleRemoveLocation(location)}
                  sx={{ padding: '8px 12px' }}
                  testID={`${testID}-remove-all-textlink-${location.name}`}
                >
                  Remove all
                </TextLink>
              )}
              <Text category="label-medium">
                {localizeNumber(getLocationSalespersonCount(location))}
                {' '}
                recipient{getLocationSalespersonCount(location) !== 1 ? 's' : ''}
              </Text>
              <HSpacer size="4" />
              <IconButton
                onClick={() => setSelectedLocation(location)}
                testID={`${testID}-select-state-button-${location.name}`}
              >
                <KeyboardArrowRight />
              </IconButton>
            </Stack>
          </Stack>
          <Divider />
        </Fragment>
      ))}
    </Stack>
  );

  const LocationPage = (
    <Stack gap="4px">
      <Divider />
      {salespersonsInSelectedLocation?.map((salesperson) => (
        <Fragment key={salesperson.id}>
          <Stack
            alignItems="center"
            direction="row"
            height="56px"
            justifyContent="space-between"
          >
            <Text category="title-medium">
              {salesperson.businessName ?? getFarmerName(salesperson)}
            </Text>
            {!viewOnly && (
              <TextLink
                onClick={() => handleRemoveSalesperson(salesperson.id)}
                testID={`${testID}-remove-farmer-textlink-${salesperson.id}`}
              >
                Remove
              </TextLink>
            )}
          </Stack>
          <Divider />
        </Fragment>
      ))}
    </Stack>
  );


  const MainPage = (
    <>
      <Search
        fullWidth
        onChangeText={setSearch}
        testID="view-recipients-search"
        value={search}
      />
      <VSpacer size="4" />
      <Tabs
        onChange={(_, value) => setSelectedTab(value)}
        testID={`${testID}-tabs`}
        value={selectedTab}
        variant="fullWidth"
      >
        <Tab label={`Farmers (${farmerIds.length})`} />
        <Tab label={`Salespersons (${salespersonIds.length})`} />
      </Tabs>
      {isFetching ? (
        <Stack alignItems="center" pt="80px">
          <ProgressLoader type="circular" />
        </Stack>
      ) : (
        <>
          {selectedTab === 0 && FarmerTab}
          {selectedTab === 1 && SalespersonTab}
        </>
      )}
    </>
  );

  const getModalContent = () => {
    switch (currentPage) {
      case 'state':
        return StatePage;
      case 'retailer':
        return RetailerPage;
      case 'location':
        return LocationPage;
      default:
        return MainPage;
    }
  }

  return (
    <Modal
      cancelButton={(props) => (
        <Button
          {...props}
          color="inherit"
          onClick={onClose}
          variant="outlined"
        >
          Close
        </Button>
      )}
      headerAccessoryLeft={showBackButton && (
        <IconButton
          onClick={handleClickBack}
          testID={`${testID}-back-button`}
          variant="outlined"
        >
          <ArrowBack />
        </IconButton>
      )}
      headerAccessoryRight={showRemoveAllButton && (
        <Stack direction="row">
          <Button
            onClick={handleClickRemoveAll}
            testID={`${testID}-state-page-remove-all-button`}
            variant="text"
          >
            <DeleteOutlined />
            <HSpacer size="3" />
            Remove all
          </Button>
        </Stack>
      )}
      onClose={onClose}
      open
      testID={testID}
      title={title}
      width={800}
    >
      <Box minHeight={250}>
        {getModalContent()}
      </Box>
    </Modal>
  );
}
