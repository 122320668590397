import { Text } from '@/components/DesignSystem';
import { FilterBar } from '@/components/DesignSystem/Toolbar/FilterBar';
import {
  Filter,
  FilterResult,
  FilterSelection, FilterSelections,
} from '@/components/DesignSystem/Toolbar/interfaces';
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Search } from '../Search/Search';
import { VSpacer } from '../Spacer';

interface ToolbarProps {
  filters?: Filter[],
  hideAllFilter?: boolean,
  initialSelections?: FilterSelections,
  onChange?: (result: FilterResult) => void,
  searchPlaceholder?: string,
  showClearAllButton?: boolean,
  testID: string,
  totalItems?: number,
  totalUnit?: string,
}

interface SearchVariation extends ToolbarProps {
  hideFilter?: never,
  hideSearch?: boolean,
}

interface FilterVariation extends ToolbarProps {
  hideFilter?: boolean,
  hideSearch?: never,
}

export const Toolbar = ({
  filters = [],
  hideFilter = false,
  hideSearch = false,
  hideAllFilter = true,
  initialSelections,
  onChange,
  searchPlaceholder = 'Search',
  showClearAllButton = false,
  testID,
  totalItems,
  totalUnit = 'item',
}: SearchVariation | FilterVariation) => {
  const { isMobile } = useMediaQuery();
  const [search, setSearch] = useState('');
  const [filterSelections, setFilterSelections]
    = useState<FilterSelections>(initialSelections ?? new Map());

  const stringifiedFilters = JSON.stringify(filters);
  useEffect(() => {
    if (!initialSelections) {
      const defaultSelections = new Map<string, FilterSelection>(
        filters.map((filter) => (
          [
            filter.id,
            new Set(filter.options.filter((option) => option.default).map(({ id }) => id)),
          ]
        )),
      );
      setFilterSelections(defaultSelections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedFilters]);

  const getFilterResult = (
    search: string,
    selections: Map<string, FilterSelection>,
  ) => {
    const result: FilterResult = {};
    if (search !== '') {
      result.search = search.toLowerCase();
    }

    if (selections.size) {
      result.selections = new Map();
      Array.from(selections.entries(), ([filterId, selection]) => {
        if (selection.size) {
          result.selections?.set(filterId, new Set(Array.from(selection)));
        }
      });
    }
    return result;
  };

  const stringifiedSelections = JSON.stringify(
    Array.from(filterSelections).map(([, values]) => Array.from(values)),
  );

  useEffect(() => {
    onChange?.(getFilterResult(search, filterSelections));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, stringifiedSelections]);

  return (
    <Stack data-testid={testID}>
      {!hideSearch && (
        <>
          <Search
            fullWidth={isMobile}
            onChangeText={setSearch}
            placeholder={searchPlaceholder}
            testID={`${testID}-search`}
            value={search}
            width={!isMobile ? 380 : undefined}
          />
          <VSpacer size="7" />
        </>
      )}
      {!hideFilter && !!filters.length && (
        <FilterBar
          filters={filters}
          hideAllFilter={hideAllFilter}
          onChange={setFilterSelections}
          selections={filterSelections}
          showClearAllButton={showClearAllButton}
          testID={`${testID}-filter`}
        />
      )}
      {totalItems !== undefined && (
        <>
          <VSpacer size="7" />
          <Text category="body-medium">
            {totalItems} {totalUnit}{totalItems !== 1 ? 's' : ''}
          </Text>
        </>
      )}
    </Stack>
  );
}
