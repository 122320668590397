import { Button, Text, VSpacer } from '@/components/DesignSystem';
import { ApiProductRecommendation } from '@api/interfaces';
import { Card, Container, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from "react";
import {
  ProductRecommendationFarmersModal,
} from "@/pages/Admin/ProductRecommendation/ProductRecommendationFarmersModal";
import { useQuery } from "react-query";
import { QueryKeys } from "@/constants/QueryKeys";
import { HierarchyOfRetailersApi } from "@/utilities/api/HierarchyOfRetailersApi";

interface ProductRecommendationInfoProps {
  recommendation?: ApiProductRecommendation;
}

const ProductRecommendationInfo = ({
  recommendation,
}: ProductRecommendationInfoProps) => {
  const [showFarmersModal, setShowFarmersModal] = useState(false);
  const farmerCount = recommendation?.recommendationFarmer?.length ?? 0

  const salesperson = recommendation?.salespersonDetails

  const { data: location } = useQuery(
    [QueryKeys.GET_LOCATION_BY_ID, salesperson?.locationId],
    () => {
      if (!salesperson?.locationId) {
        return null;
      }
      return HierarchyOfRetailersApi.getRetailerLocationById(salesperson.locationId)
    },
    {
      enabled: !!salesperson?.locationId,
      staleTime: 5 * 60 * 1000,
    },
  );

  return (
    <Container>
      <Card sx={{ padding: '20px' }}>
        <Text
          category='overline'
          testID='card-text'
        >
          Product Recommendation
        </Text>
        <VSpacer size='5' />
        <Text fontSize='28px' testID='card-text'>
          {recommendation?.publicId ? `#${recommendation?.publicId}` : '-'}
        </Text>
      </Card>
      <VSpacer size='5' />
      <Card sx={{ padding: '20px' }}>
        <Text testID="retailer-name-label-text">
          Retailer Name
        </Text>
        <VSpacer size="3"/>
        <Text category="body-large" testID="retailer-name-text">
          {location?.retailer?.name ?? '-'}
        </Text>
        <VSpacer size="8"/>
        <Text testID="retailer-location-label-text">
          Retailer Location
        </Text>
        <VSpacer size="3"/>
        <Text category="body-large" testID="retailer-location-text">
          {location?.name ?? '-'}
        </Text>
        <VSpacer size="8"/>
        <Text testID='salesperson-name-label-text'>
          Salesperson Name
        </Text>
        <VSpacer size="3" />
        <Text category="body-large" testID="salesperson-name-text">
          {salesperson?.businessName ?? '-'}
        </Text>
        <VSpacer size='8' />
        <Text testID='products-count-label-text'>
          Products
        </Text>
        <VSpacer size="3" />
        <Text category="body-large" testID="products-count-text">
          {(recommendation?.productRecommendationData?.length ?? 0) +
            (recommendation?.bundles?.length ?? 0)}
        </Text>
        <VSpacer size='8' />
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <Stack>
            <Text testID="farmers-count-label-text">
              Farmers
            </Text>
            <VSpacer size="3"/>
            <Text category="body-large" testID="farmers-count-text">
              {farmerCount}
            </Text>
          </Stack>
          <Button
            disabled={farmerCount === 0}
            onClick={() => setShowFarmersModal(true)}
            testID="view-farmers-modal-button"
            variant="text"
          >
            View
          </Button>
        </Stack>
        <VSpacer size='8' />
        <Text testID='created-on-label-text'>
          Created on
        </Text>
        <VSpacer size="3" />
        <Text category="body-large" testID="created-on-text">
          {recommendation?.createdAt &&
            DateTime.fromISO(recommendation.createdAt.toString()).toFormat('MMM dd, yyyy')}
        </Text>
        <VSpacer size='8' />
        <Text testID='expires-on-label-text'>
          Expires on
        </Text>
        <VSpacer size="3" />
        <Text category="body-large" testID="expires-on-text">
          {recommendation?.expiration &&
            DateTime.fromISO(recommendation.expiration.toString()).toFormat('MMM dd, yyyy')}
        </Text>
        <VSpacer size='8' />
        <Text testID='description-label-text'>
          Description
        </Text>
        <VSpacer size="3" />
        <Text category="body-large" testID="description-text">
          {recommendation?.note}
        </Text>
      </Card>
      {showFarmersModal && !!recommendation?.recommendationFarmer && (
        <ProductRecommendationFarmersModal
          farmers={recommendation?.recommendationFarmer}
          onClose={() => setShowFarmersModal(false)}
        />
      )}
    </Container>
  );
};

export default ProductRecommendationInfo;
