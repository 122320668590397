import { useQuery } from 'react-query';
import { ProductRecommendationApi } from '@/utilities/api/ProductRecommendationApi';
import { QueryKeys } from '@/constants/QueryKeys';
import { Filters } from '@/pages/Admin/ProductRecommendation/Interfaces';
import { FilterResult } from '@/components/DesignSystem/Toolbar/interfaces';

const useProductRecommendations = (filter: Filters | FilterResult, search: string) => {
  const { data: productRecommendations, isLoading } = useQuery(
    [ QueryKeys.GET_RECOMMENDATIONS, JSON.stringify(filter), search],
    () => ProductRecommendationApi.list({
      ...filter,
      search: search.toLowerCase(),
    }),
  );

  return { productRecommendations, isLoading };
};

const useProductRecommendationsById = (id: string) => {
  const { data: productRecommendationById, isLoading } = useQuery(
    [QueryKeys.GET_RECOMMENDATION_BY_ID, id],
    () => ProductRecommendationApi.getById(id),
  );

  return { productRecommendationById, isLoading };
}

export { useProductRecommendations, useProductRecommendationsById };
