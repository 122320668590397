import GrowersIcon from '@/assets/icons/growers-icon.svg';
import ManufacturerIcon from '@/assets/icons/manufacturer-icon.svg';
import RetailerIcon from '@/assets/icons/retailer-icon.svg';
import {
  Button, Checkbox,
  DataPoint,
  Dialog,
  HSpacer,
  NumericInput,
  Select,
  Text,
  VSpacer,
} from '@/components/DesignSystem'
import { rewardsConstants } from '@/constants/constant';
import { QueryKeys } from '@/constants/QueryKeys';
import useAddRewardsByFarmerId from '@/hooks/useFarmerRewards';
import CreateWalletDialog from '@/pages/Admin/FarmerList/CreateWalletDialog';
import ConfirmDialog from '@/pages/Admin/HierarchyOfRetailers/ConfirmDialog';
import { PricingRequestsApi } from '@/utilities/api/PricingRequestsApi';
import { UserApi } from '@/utilities/api/UserApi';
import { ApiManufacturer, ApiPricingRequest, ApiRetailer, ApiUser } from '@api/interfaces';
import HighlightOff from '@mui/icons-material/HighlightOff';
import {
  Autocomplete,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  useTheme,
} from '@mui/material'
import { PricingRequestStatus } from '@shared/enums';
import { SharedConfig } from '@shared/SharedConfig';
import { formatCurrency } from '@shared/utilities';
import React, { Dispatch, Fragment, SetStateAction, useState } from 'react'
import { useQuery } from 'react-query';
import { Wallet } from './helper';

type AddRewardsDialogProps = {
  farmer: ApiUser,
  setShow: Dispatch<SetStateAction<boolean>>,
  show: boolean,
}

const AddRewardsDialog = (
  {
    farmer,
    setShow,
    show,
  } : AddRewardsDialogProps,
) => {
  const theme = useTheme();

  const [createWalletManufacturer, setCreateWalletManufacturer] = (
    useState<ApiManufacturer | null>(null)
  );
  const [createWalletRetailer, setCreateWalletRetailer] = useState<ApiRetailer>();
  const [notifyFarmer, setNotifyFarmer] = useState(false);
  const [points, setPoints] = useState<number>();
  const [selectedRequest, setSelectedRequest] = useState<ApiPricingRequest | string>();
  const [selectedWallet, setSelectedWallet] = useState<Wallet>();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showCreateWallet, setShowCreateWallet] = useState(false);

  const { addRewardsById, isAddRewardsLoading } = useAddRewardsByFarmerId(() => setShow(false));

  const onSave = async () => {
    let associatedPricingRequestId;
    if (selectedRequest && typeof selectedRequest !== 'string') {
      associatedPricingRequestId = selectedRequest.id;
    }
    addRewardsById({
      farmerId: farmer.id,
      manufacturerId: selectedWallet?.manufacturerId,
      notifyFarmer,
      pointsToAdd: Number(points) ?? 0,
      pricingRequestId: associatedPricingRequestId,
      retailerId: selectedWallet?.retailerId,
    });
    setShow(false);
    setShowConfirmDialog(false);
  };

  const { data: requests, isLoading: isLoadingRequests } = useQuery(
    [QueryKeys.GET_PRICING_REQUESTS, farmer.id],
    () =>
      PricingRequestsApi.getPricingRequestsForAdmin({
        limit: SharedConfig.maxPageLimit,
        status: [
          PricingRequestStatus.AwaitingDelivery,
          PricingRequestStatus.AwaitingFulfillment,
          PricingRequestStatus.AwaitingPickup,
          PricingRequestStatus.Closed,
          PricingRequestStatus.FinancingRequested,
          PricingRequestStatus.OnAccountRequested,
          PricingRequestStatus.OrderShipped,
          PricingRequestStatus.PickupReady,
        ],
        sort: 'pricingRequestPublicId',
        sortDesc: true,
        userId: [farmer.id],
      }),
  );

  const { data: rewards } = useQuery(
    [QueryKeys.GET_USER_REWARDS, farmer.id],
    () => UserApi.getFarmerRewards(farmer.id),
  );

  const getNewWalletName = (retailer?: ApiRetailer, manufacturer?: ApiManufacturer | null) => {
    let name = '';
    if (retailer) {
      name = retailer.name;
    }
    if (manufacturer) {
      if (name) {
        name += ` / ${manufacturer.name}`;
      } else {
        name = manufacturer.name;
      }
    }
    return name;
  }

  const getWallets = () => {
    let wallets: Wallet[] = [];
    if (rewards?.subledgerBalances) {
      wallets = [...rewards.subledgerBalances];
    }
    if (createWalletManufacturer || createWalletRetailer) {
      const newWallet: Wallet = {
        activePoints: 0,
        retailerId: createWalletRetailer?.id ?? null,
        retailerName: createWalletRetailer?.name ?? null,
        name: getNewWalletName(createWalletRetailer, createWalletManufacturer),
        manufacturerId: createWalletManufacturer?.id ?? null,
        manufacturerName: createWalletManufacturer?.name ?? null,
      }
      wallets.push(newWallet);
    }
    wallets.sort((a, b) => a.name.localeCompare(b.name));
    const growersWallet: Wallet = {
      activePoints: rewards?.balance?.activePoints ?? 0,
      retailerId: null,
      retailerName: null,
      name: 'Growers',
      manufacturerId: null,
      manufacturerName: null,
    };
    wallets.unshift(growersWallet);
    return wallets;
  }

  const getIcon = (wallet: Wallet) => {
    if (!wallet.manufacturerId && !wallet.retailerId) {
      return GrowersIcon;
    } else if (wallet.manufacturerId) {
      return ManufacturerIcon;
    }
    return RetailerIcon;
  }

  const renderWallets = () => {
    const wallets = getWallets();
    return wallets.map((wallet, index) => (
      <MenuItem
        key={wallet.name}
        onClick={() => {
          setSelectedWallet(wallet);
        }}
        sx={{
          borderBottom: index !== wallets.length - 1
            ? `1px solid ${theme.palette.divider}` : 'none',
        }}
        value={wallet.name}
      >
        <ListItemIcon>
          <img
            src={getIcon(wallet)}
            style={{
              backgroundColor: theme.palette.getContrastText(theme.palette.background.paper),
              borderRadius: '100px',
              padding: '8px',
            }}
          />
        </ListItemIcon>
        <HSpacer size="8" />
        <ListItemText>
          <Text category="body-large">{wallet.name}</Text>
        </ListItemText>
        <HSpacer size="5" />
        <Text category="body-large">{wallet.activePoints} points</Text>
      </MenuItem>
    ));
  }

  const isFormValid = !!selectedWallet && !!points && !!selectedRequest;

  const rewardPointsUpdated = (value?: number) => {
    if (value && value > 0 && !points) {
      setNotifyFarmer(true);
    } else if (notifyFarmer && value && value <= 0) {
      setNotifyFarmer(false);
    }
    setPoints(value);
  };

  return (
    <Dialog
      acceptButton={() => (
        <Button
          disabled={!isFormValid}
          loading={isAddRewardsLoading}
          onClick={() => setShowConfirmDialog(true)}
          testID='save-rewards-button'
          variant='contained'
        >
          Save
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          onClick={() => setShow(false)}
          {...props}
          color='inherit'
          testID="rewards-cancel-button"
        >
          Cancel
        </Button>
      )}
      dialogWidth="500px"
      onClose={() => setShow(false)}
      open={show}
      showCloseButton={false}
      testID="add-rewards-dialog"
      title={rewardsConstants?.addRewardsDialogTitle}
    >
      <Stack direction="row" justifyContent="space-between">
        <DataPoint
          label="Farmer:"
          testID="farmer-name-datapoint">
          {farmer.firstName} {farmer.lastName}
        </DataPoint>
        <DataPoint
          label="Total reward points:"
          testID="reward-points-datapoint">
          {formatCurrency(selectedWallet?.activePoints ?? 0, '', 2)}
        </DataPoint>
      </Stack>
      <VSpacer size="7" />
      <Autocomplete
        clearIcon={<HighlightOff />}
        disablePortal
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return `Request #${option.publicId.toString()}`;
        }}
        loading={isLoadingRequests}
        onChange={(_, value) => {
          setSelectedRequest(value ?? undefined);
        }}
        options={['Growers', ...(requests?.data ?? [])]}
        renderInput={(params) => (
          <TextField
            label='Select Request'
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {isLoadingRequests ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
            required
          />
        )}
      />
      <VSpacer size="7" />
      <Select
        label="Select Wallet"
        renderValue={() => {
          return selectedWallet?.name;
        }}
        required
        testID="add-reward-wallet-select"
        value={selectedWallet}
      >
        {renderWallets()}
      </Select>
      <VSpacer size="3" />
      <Button
        onClick={() => setShowCreateWallet(true)}
        testID="create-wallet-button"
        variant="text"
      >
        Create wallet
      </Button>
      <VSpacer size="5" />
      <NumericInput
        label="Add/Subtract Reward Points"
        maxValue={9999.99}
        minValue={-9999.99}
        onChangeNumber={rewardPointsUpdated}
        required
        testID="add-rewards-points-input"
        value={points}
      />
      <VSpacer size="5" />
      <Checkbox
        checked={notifyFarmer}
        disabled={(points ?? 0) < 0}
        onChangeChecked={setNotifyFarmer}
        testID="rewards-notify-farmer"
      >
        <Text>Notify Farmer</Text>
      </Checkbox>
      <CreateWalletDialog
        farmer={farmer}
        onConfirm={(retailer, manufacturer) => {
          setCreateWalletManufacturer(manufacturer);
          setCreateWalletRetailer(retailer);
          const newWallet: Wallet = {
            activePoints: 0,
            retailerId: retailer.id,
            retailerName: retailer.name,
            name: getNewWalletName(retailer, manufacturer),
            manufacturerId: manufacturer?.id ?? null,
            manufacturerName: manufacturer?.name ?? null,
          };
          setSelectedWallet(newWallet);
        }}
        setShow={setShowCreateWallet}
        show={showCreateWallet}
      />
      <ConfirmDialog
        isOpen={showConfirmDialog}
        message={rewardsConstants.addRewardsConfirmation}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={onSave}
        title="Add/remove reward points?"
      />
    </Dialog>
  )
}

export default AddRewardsDialog;
