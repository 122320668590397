import { useState } from 'react';
import { Button } from '@/components/DesignSystem';
import { OfferDetails } from '@/pages/ReviewSelectOffers/OfferDetails';
import {
  ApiOffer,
  ApiOfferProduct,
  ApiPricingRequest,
  ApiPricingRequestRetailer,
} from '@api/interfaces';
import {
  FulfillmentMethod,
  OfferStatus, PricingRequestStatus,
  SeedProductUnitOfMeasure, State, TalonStatus,
} from '@shared/enums';

const demoProducts: ApiOfferProduct[] = [
  {
    createdAt: new Date(),
    id: '1',
    isAccepted: false,
    offerId: 'offer-id',
    price: 10,
    pricingRequestProductId: '1',
    quantity: 5,
    substituteProduct: 'substituteProduct1',
    updatedAt: new Date(),
    uom: SeedProductUnitOfMeasure.POUND,
  },
  {
    createdAt: new Date(),
    id: '2',
    isAccepted: false,
    offerId: 'offer-id',
    price: 30,
    pricingRequestProductId: '1',
    quantity: 2,
    substituteProduct: 'substituteProduct2',
    updatedAt: new Date(),
    uom: SeedProductUnitOfMeasure.BUSHEL,
  },
];

const demoSalesperson: ApiPricingRequestRetailer = {
  createdAt: new Date(),
  id: '',
  lastLook: false,
  name: 'Salesperson',
  salespersonId: 'salesperson-id',
  preferred: true,
  pricingRequestId: '',
  updatedAt: new Date(),
}

const demoOffer: ApiOffer = {
  createdAt: new Date(),
  fulfillmentMethod: FulfillmentMethod.DELIVERY,
  id: '',
  isRead: false,
  note: (
    'Please contact me if this fulfillment method will not work for you or address information' +
    ' changes. I will be available M-F afternoons.'
  ),
  paymentMethodPreference: null,
  pricingRequestId: '',
  pricingRequestRetailerId: '',
  products: demoProducts,
  publicId: 'public-id',
  status: OfferStatus.Open,
  updatedAt: new Date(),
}

const demoPriceRequest: ApiPricingRequest = {
  acceptedDate: null,
  acceptedRetailerIds: [],
  approvedDate: null,
  createdAt: new Date(),
  createdById: '',
  deletedAt: null,
  deliveryAddress1: "Test Address 1",
  deliveryAddress2: "Test Address 2",
  deliveryCity: "Test City",
  deliveryPostalCode: "12345",
  deliveryState: State.NorthCarolina,
  expiration: null,
  fulfillmentMethod: FulfillmentMethod.DELIVERY,
  id: '',
  isExpired: false,
  isRead: true,
  isReviewExpired: false,
  note: null,
  paymentType: null,
  publicId: 0,
  redeemedPoints: 0,
  referralAffiliate: null,
  referralNote: null,
  referralSender: null,
  rejectedDate: null,
  requestedExpirationDays: null,
  reviewExpiration: null,
  status: PricingRequestStatus.Open,
  storefrontId: null,
  submittedDate: null,
  systemDiscount: 0,
  talonStatus: TalonStatus.DEFAULT,
  updatedAt: new Date(),
  userId: '1234',
  paymentStatus: null,
  retailerPaymentStatus: '',
  isPaymentSubmitted: false,
};

export const OfferDetailsDemo = () => {
  const [showOfferDetails, setShowOfferDetails] = useState(true);
  return (
    <>
      <Button
        onClick={() => setShowOfferDetails(true)}
        testID="offer-details-demo-button"
      >
        Show Offer Details
      </Button>
      {showOfferDetails && (
        <OfferDetails
          offer={demoOffer}
          onClose={() => setShowOfferDetails(false)}
          priceRequest={demoPriceRequest}
          retailer={demoSalesperson}
          testID="offer-details"
        />
      )}
    </>
  );
};
