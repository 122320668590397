import { useState } from 'react';
import { Button, VSpacer } from '@/components/DesignSystem';
import { CircularProgress, Container, Divider, Stack } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import RetailerDetailsCard from './RetailerDetailsCard';
import { useNavigate, useParams } from 'react-router-dom';
import MembersCard from './Members/MemberOverviewSection';
import LocationCard from './Locations/LocationOverviewSection';
import { useGetRetailerMembers } from '@/hooks/useHierarchyOfRetailers';
import { useGetRetailerById } from '@/hooks/useHierarchyOfRetailers';
import { useSearch } from '@/hooks/useSearch';
import { stringifyCount } from '@/constants/HierarchyOfRetailers';

const Loader = () => (
  <Container>
    <Stack alignItems='center'>
      <VSpacer size='14' />
      <CircularProgress />
    </Stack>
  </Container>
);

const RetailerDetailsOverviewPage = () => {
  const [showAddMember, setShowAddMember] = useState(false);
  const [showViewMember, setShowViewMember] = useState(false);
  const [showLocation, setShowLocation] = useState(false);

  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const backToAllRetailers = () => navigate(-1);

  const onAddMember = () => setShowAddMember(!showAddMember);
  const onViewMember = () => setShowViewMember(!showViewMember);
  const onAddLocation = () => setShowLocation(!showLocation);
  const { debouncedSearch, search, setSearch } = useSearch();

  const { getRetailerMembers, isLoading } = useGetRetailerMembers(id, debouncedSearch);

  const { retailer } = useGetRetailerById(id ?? '');

  if (isLoading) {
    return <Loader />
  }

  return (
    <Container maxWidth='lg'>
      <Button
        onClick={backToAllRetailers}
        startIcon={<KeyboardBackspaceIcon />}
        sx={{
          color: 'white',
          margin: '25px 0px',
        }}
        testID='back-to-all-retailers'
        variant='text'
      >
        Back to all retailers
      </Button>
      <Stack spacing='2'>
        <RetailerDetailsCard />
        <MembersCard
          getMembersCountText={stringifyCount(getRetailerMembers?.total, 'member', 'members')}
          members={getRetailerMembers}
          onAddMember={onAddMember}
          onViewMember={onViewMember}
          search={search}
          setSearch={setSearch}
          showAddMember={showAddMember}
          showViewMember={showViewMember}
        />
        <VSpacer size='8' />
        <Divider />
        <VSpacer size='8' />
        <LocationCard
          isRetailerActive={retailer?.isActive ?? false}
          onAddLocation={onAddLocation}
          retailerId={id}
          show={showLocation}
        />
      </Stack>
    </Container>
  );
};

export default RetailerDetailsOverviewPage;
