import {
  Button,
  VSpacer,
  SideSheet,
  Text,
} from "@/components/DesignSystem";
import { InnerContainer } from "@/components/shared/InnerContainer";
import { Stack, Box, Divider, Chip } from "@mui/material";
import { SXStyles } from "@/themes/variant-interfaces/SXStyles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteConfirmation  from "./DeleteConfirmation";
import { useState, useEffect } from "react";
import { useDeleteProductById, useGetProductById } from "@/hooks/useProducts";
import { viewProductConstant } from "@/constants/productConstant";
import { AppConfig } from "../../../constants/AppConfig";
import defaultImage from '@/assets/logos/reverse/icon-product-generic_dry crop_nutrition_scaled.png'
import { UseActiveIngredients } from '@/hooks/useProductQuery';
import { ProductSubcategory } from "./Interfaces";

const styles: SXStyles = {
  editButtonContainer: {
    position: "absolute",
    top: 20,
    left: 20,
  },
  keywordChip: {
    marginRight: "10px",
    marginBottom: "10px",
  },
} as const;
interface ProductViewProps {
  isVisible: boolean;
  onClose: () => void;
  onEdit: (args: string) => void;
  productId?: string
}

const ViewProductDrawer = ({
  isVisible,
  onClose,
  onEdit,
  productId,
}:
ProductViewProps) => {

  const { productById: productField } = useGetProductById(productId ?? '');

  const { deleteProductById } = useDeleteProductById();

  const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog]=useState<boolean>(false);
  const [showSubcategoryA1, setShowSubcategoryA1]=useState<boolean>(false);
  const { activeIngredientsDataList } = UseActiveIngredients();
  const formattedSubCategoryA1Data = productField?.productSubcategories?.map((item:
  {subcategories:{id:string, name:string, label:number}}) => {
    const { label, id } = item.subcategories;
    if(label === 2){
      return  id;
    }
  }).filter(Boolean).join('');
  useEffect(() => {
    let shouldShowSubcategoryA1 = false;
    if(productField && productField.productCategory){
      if(productField.productCategory.name === 'Seed'){
        if (productField && productField.productSubcategories) {
          for (const item of productField.productSubcategories) {
            if (
              item.subcategories.name !== 'Seed Treatment' &&
                item.subcategories.name !== 'Biologicals'
            ) {
              shouldShowSubcategoryA1 = true;
              break;
            }
          }
        }
      }
    }

    setShowSubcategoryA1(shouldShowSubcategoryA1);
  }, [productField]);

  return (
    <>
    <SideSheet
      actionButton={(props) => (
        <Button {...props}
          color="error"
          onClick={()=>{
            setShowDeleteConfirmationDialog(true);
          }}
          startIcon={<DeleteOutlineIcon />}
          testID="button-demo-enabled-text"
          variant="text"
        >
        {viewProductConstant.deleteProduct}
        </Button>
      )}
      hideScrollbar={true}
      onClose={()=>onClose()}
      onOpen={() => null}
      open={isVisible}
      testID="viewProduct"
    >
      <Box sx={styles.editButtonContainer}>
        <Button
          onClick={() => {
            onEdit(productField?.id ?? '')
            onClose()
          }}
          startIcon={<EditIcon fontSize="small" />}
          testID="button-demo-enabled-outline-left-accessory"
          variant="outlined"
        >
          {viewProductConstant.edit}
        </Button>
      </Box>

      <Box>
        <InnerContainer maxWidth="xl">
            <Stack spacing={3}>
              <Text category="h4">{productField?.name}</Text>
              <Divider />
              <Stack spacing={3}>
                <Text fontSize="16px">{viewProductConstant.Manufacturer}</Text>
                <Text
                  fontSize="14px"
                  lineHeight="20px"
                  sx={{ display: "flex", flexWrap: "wrap" }}
                >
                  {productField?.manufacturer?.name }
                </Text>
              </Stack>
              <Divider />
              <Stack spacing={3}>
                <Text fontSize="16px">{viewProductConstant.retailers}</Text>
                <Text
                  fontSize="14px"
                  lineHeight="20px"
                  sx={{ display: "flex", flexWrap: "wrap" }}
                >
                  {productField?.productRetailers?.map((item) => (
                    item?.retailerDetails?.name
                  ))?.join(', ')}
                </Text>
              </Stack>
              <Divider />
              <Stack spacing={3}>
                <Text fontSize="16px">{viewProductConstant.categoryFilters}</Text>
                <Text
                  fontSize="14px"
                  lineHeight="20px"
                  sx={{ display: "flex", flexWrap: "wrap" }}
                >
                  {productField?.productCategory?.name}
                </Text>
              </Stack>
              <Divider />
              <Stack spacing={3}>
                <Text fontSize="16px">{viewProductConstant.SubcategoryA}</Text>
                <Text
                  fontSize="14px"
                  lineHeight="20px"
                  sx={{ display: "flex", flexWrap: "wrap" }}
                >
                  {productField?.productSubcategories?.map((item) => (
                    item.subcategories.name
                  )).join(', ')}
                </Text>
              </Stack>
              <Divider />
              {(productField?.primaryNutrients &&
              productField?.primaryNutrients?.length > 0) ? (
                <>
                  <Stack spacing={3}>
                    <Text fontSize="16px">{viewProductConstant.primaryNutrient}</Text>
                    <Text
                      fontSize="14px"
                      lineHeight="20px"
                      sx={{ display: "flex", flexWrap: "wrap" }}
                    >
                      {productField?.primaryNutrients?.map(({ name }: { name: string }) => (
                        name
                      )).join(', ')}
                    </Text>
                  </Stack>
                  <Divider />
                </>
                ):<></>}
              {
              (productField?.activeIngredientsIds
              && productField?.activeIngredientsIds?.length > 0) ? (
                <>
                  <Stack spacing={3}>
                    <Text fontSize="16px">{viewProductConstant.activeIngredient}</Text>
                    <Text
                      fontSize="14px"
                      lineHeight="20px"
                      sx={{ display: "flex", flexWrap: "wrap" }}
                    >
                      {productField?.activeIngredientsIds &&
                        activeIngredientsDataList
                          .filter(({ id }: { id: string }) => (
                            productField?.activeIngredientsIds?.length &&
                            productField.activeIngredientsIds
                              .find((ingredientsId) => ingredientsId === id)
                          ))
                          .map(({ name }: { name: string }) => name).join(', ')
                      }
                    </Text>
                  </Stack>
                  <Divider />
                </>
                ): <></>}
              {showSubcategoryA1 &&
                <>
                  <Stack spacing={3}>
                    <Text fontSize="16px">{viewProductConstant.subcategoryA1}</Text>
                    <Text
                      fontSize="14px"
                      lineHeight="20px"
                      sx={{ display: "flex", flexWrap: "wrap" }}
                    >
                      {productField?.productSubcategories?.map((item: ProductSubcategory) => (
                        item?.subcategories?.subcategories.filter((item: { id: string })=>
                          item.id === formattedSubCategoryA1Data,
                        ).map((itemA1: { name: string }) => itemA1.name)),
                      )}
                    </Text>
                  </Stack>
                  <Divider />
                </>
              }
              <Stack spacing={3}>
                <Text fontSize="16px">{viewProductConstant.cropType}</Text>
                <Text
                  fontSize="14px"
                  lineHeight="20px"
                  sx={{ display: "flex", flexWrap: "wrap" }}
                >
                  {productField?.productCrops
                    ?.map((item: { crops: { name: string }}) => item.crops.name)
                    .join(', ')}
                </Text>
              </Stack>
              <Divider />
              <Stack spacing={3}>
                <Text fontSize="16px">{viewProductConstant.practice}</Text>
                <Text
                  fontSize="14px"
                  lineHeight="20px"
                  sx={{ display: "flex", flexWrap: "wrap" }}
                >
                  {productField?.productPractices?.map((item: {practices: { name: string }}) => (
                    item.practices.name
                  )).join(', ')}
                </Text>
              </Stack>
              <Divider />
              <Stack spacing={3}>
                <Text fontSize="16px">{viewProductConstant.purchaseUoM}</Text>
                <Text
                  fontSize="14px"
                  lineHeight="20px"
                  sx={{ display: "flex", flexWrap: "wrap" }}
                >
                  {productField?.purchaseUom?.join(', ')}
                </Text>
              </Stack>
              <Divider />
              <Stack spacing={3}>
                <Text fontSize="16px">{viewProductConstant.packageSize}</Text>
                <Text
                  fontSize="14px"
                  lineHeight="20px"
                  sx={{ display: "flex", flexWrap: "wrap" }}
                >
                  {productField?.packageSize?.join(', ')}
                </Text>
              </Stack>
              <Divider />
              <Stack spacing={3}>
                <Text fontSize="16px">{viewProductConstant.restrictedUse}</Text>
                <Text
                  fontSize="14px"
                  lineHeight="20px"
                >
                  {productField?.isRestrictedUse ? 'True' : 'False' }
                </Text>
              </Stack>
              <Divider />
              <Stack spacing={3}>
                <Text fontSize="16px">{viewProductConstant.sellSheet}</Text>
                <Text
                  fontSize="14px"
                  lineHeight="20px"
                  sx={{ display: "flex", flexWrap: "wrap" }}
                >
                  {productField?.sellSheet}
                </Text>
              </Stack>
              <Divider />
              <Stack spacing={3}>
                <Text fontSize="16px">{viewProductConstant.thumbnaillink}</Text>
                <Box sx={{ display:'flex', justifyContent:"center" }} >
                  <img
                    alt="image"
                    src={productField?.image
                      ? `${AppConfig.staticImageHost}/${productField.image}`
                      : defaultImage}
                    width="231px"
                  />
                </Box>
            </Stack>
            <Divider />
            <Stack spacing={3}>
              <Text fontSize="16px">{viewProductConstant?.Keywords}</Text>
              {productField?.keywords && productField?.keywords?.length > 0 && (
                <Box>
                  {productField.keywords.map((item: string, i: number) => {
                    return (
                      <Chip
                        key={i}
                        label={item}
                        sx={styles.keywordChip}
                        variant="outlined"
                      />
                    );
                  })}
                </Box>
              )}
            </Stack>
            <Divider />
            <Stack spacing={3}>
              <Text fontSize="16px">{viewProductConstant.companionProduct}</Text>
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "3px",
                  display: "flex",
                  padding: "20px",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Text fontSize="17px">{viewProductConstant.companionProduct}</Text>
                  <VSpacer size="3" />
                  {productField?.companionProducts &&
                    productField?.companionProducts?.map(
                      ({ id, productDetails: { name } }) => (
                        <Text fontSize="11px" key={id} multiline>
                          {name}
                        </Text>
                      ))}
                </Box>
              </Box>
            </Stack>
            <Divider />
            <Stack spacing={3}>
              <Text fontSize="16px">{viewProductConstant.alternativeProduct}</Text>
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "3px",
                  display: "flex",
                  padding: "20px",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Text fontSize="17px">{viewProductConstant.alternativeProduct}</Text>
                  <VSpacer size="3" />
                  {productField?.alternativeProducts &&
                    productField?.alternativeProducts?.map(
                      ({ id, productDetails: { name } }) => (
                        <Text fontSize="11px" key={id} multiline>
                          {name}
                        </Text>
                      ))}
                </Box>
              </Box>
              </Stack>
            </Stack>
            <VSpacer size="6" />
          </InnerContainer>
        </Box>
      </SideSheet>
      <DeleteConfirmation
        handleDelete={() => deleteProductById(productId ?? '')}
        onClose={onClose}
        productFieldId={productField?.id ?? ''}
        setShowDeleteConfirmationDialog={setShowDeleteConfirmationDialog}
        showDeleteConfirmationDialog={showDeleteConfirmationDialog}
      />
    </>
  );
};

export default ViewProductDrawer;
