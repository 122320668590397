import { DateTime } from 'luxon';

export const daysBeforeNow = (days: number): Date => (
  DateTime.now().minus({ days }).toJSDate()
);

export const daysFromNow = (days: number): Date => (
  DateTime.now().plus({ days }).toJSDate()
);

export const daysFromDate = (date: Date) => {
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date provided');
  }
  const input = DateTime.fromJSDate(date);
  const now = DateTime.now();
  return now.diff(input, 'days').days;
}

export const daysToMilliseconds = (days: number): number => {
  return days * 24 * 60 * 60 * 1000;
}

export const formatDate = (date?: Date | string | null) => {
  if (date) {
    return DateTime.fromISO(date.toString()).toFormat("ccc LLL dd hh:mm a");
  } else {
    return "---";
  }
};

export const formatDateWithDayAndMonth = (dateObject: Date) => {
  const dateObj = DateTime.fromJSDate(dateObject);
  const day = dateObj.toFormat('dd');
  const month = dateObj.toFormat('LLL').toUpperCase();
  return `${month} ${day}`;
};

export const formatDateOnly = (date: Date, timezone = 'America/New_York'): string => {
  return DateTime.fromJSDate(date).setZone(timezone).toFormat('yyyy-MM-dd');
}

export const formatFullDate = (date: Date | undefined) => {
  if (!date) {
    return "---";
  }
  return DateTime
    .fromJSDate(date)
    .toFormat('MMM dd, yyyy \'at\' h:mm a')
    .replace(/AM|PM/gi, (match) => match.toLowerCase());
}

export const getCurrentYear = () => new Date().getFullYear().toString();

export const getMostRecentDate = (dates: Date[]) => {
  const copy = [...dates];
  const sorted = copy.sort((a, b) => (a === b) ? 0 : (a < b) ? 1 : -1);
  return sorted[0]
}

export const hoursUntil = (date: Date) => {
  const now = new Date();
  const differenceInMilliSeconds = date.getTime() - now.getTime();
  const hours = differenceInMilliSeconds / (60 * 60 * 1000);
  return hours;
}

export const minutesAgo = (minutes: number) => (
  DateTime.now().minus({ minutes }).toJSDate()
);

export const minutesUntil = (date: Date) => {
  return (
    DateTime.fromJSDate(date).diffNow('minutes').minutes
  );
}
