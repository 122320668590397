import {
  MultiPageModal,
  MultiPageStep,
} from '@/components/DesignSystem/MultiPageModal/MultiPageModal';
import { AddPriceModal } from '@/components/shared/CreateOffer/AddPriceModal';
import { PriceList } from '@/components/shared/CreateOffer/PriceList';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { OfferProduct } from '@/pages/CreateSendPriceRequest/interfaces';
import { Button, HSpacer, VSpacer } from '@/components/DesignSystem';
import { Box } from '@mui/material';
import { DemoBlock } from '../../components/DemoBlock';
import { useState } from 'react';
import { getResponsiveValue } from '@/hooks/useMediaQuery';
import { AddPrices } from '@/components/shared/CreateOffer/AddPrices';
import { ProductType } from '@shared/enums/ProductType';
import { FulfillmentMethod, ProductUom } from '@shared/enums';
import { CreateOfferSuccessDialog } from '@/components/shared/CreateOffer/CreateOfferSucessDialog';
import { ReviewAndSubmit } from '@/components/shared/CreateOffer/ReviewAndSubmit';
import { Offer } from '@/pages/ReceivePlaceOffers/interfaces';
import { ApiPricingRequestPublic } from '@api/interfaces';

const styles = {
  box: {
    maxHeight: getResponsiveValue(undefined, '490px'),
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  icon: {
    width: '16px',
    height: '16px',
    marginRight: '11px',
  },
} as const;

const demoProducts: OfferProduct[] = [
  {
    allowSubstitutions: true,
    crop: 'crop-test',
    id: '1',
    initialQuantity: 1,
    initialUom: 'GAL' as ProductUom,
    name: 'Product-test',
    package: 'package-test',
    price: 100,
    pricingRequestProductId: '1',
    quantity: 1,
    substituteProduct: 'substituteProduct1',
    type: 'chemical' as ProductType,
    uom: 'GAL' as ProductUom,
  },
  {
    allowSubstitutions: true,
    crop: 'crop-test2',
    id: '2',
    initialQuantity: 1,
    initialUom: 'GAL' as ProductUom,
    name: 'Product-test2',
    package: 'package-test2',
    price: 10000,
    pricingRequestProductId: '1',
    quantity: 1,
    substituteProduct: 'substituteProduct',
    type: 'chemical' as ProductType,
    uom: 'GAL' as ProductUom,
  },
  {
    allowSubstitutions: true,
    crop: 'crop-test1',
    id: '3',
    initialQuantity: 1,
    initialUom: 'GAL' as ProductUom,
    name: 'product-test1',
    package: 'package-test1',
    price: 0,
    pricingRequestProductId: '1',
    quantity: 1,
    substituteProduct: undefined,
    type: 'chemical' as ProductType,
    uom: 'AC' as ProductUom,
  },
];

const demoOffer: Offer = {
  fulfillmentMethod: FulfillmentMethod.DELIVERY,
  note: "Test Note",
  products: [],
  publicId: "12345",
};

const demoPriceRequest: ApiPricingRequestPublic = {
  acceptedDate: null,
  approvedDate: null,
  deliveryAddress1: "Test Address 1",
  deliveryAddress2: "Test Address 2",
  deliveryCity: "Test City",
  deliveryPostalCode: "12345",
  deliveryState: null,
  expiration: null,
  id: '',
  isExpired: false,
  note: null,
  offer: null,
  paymentType: null,
  publicId: 0,
  rejectedDate: null,
  submittedDate: null,
  userId: '1234',
} as ApiPricingRequestPublic;


export const CreateOfferDemo = () => {
  const steps = ['Add Prices', 'Review And Submit'];
  const [activeStep, setActiveStep] = useState<number | undefined>();
  const [showAddPriceModal, setShowAddPriceModal] = useState(false);
  const [showEditPriceModal, setShowEditPriceModal] = useState(false);
  const [showPriceList, setShowPriceList] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  return (
    <>
      {steps.map((name: string, index: number) => (
        <DemoBlock key={name} label={name}>
          <Button
            onClick={() => setActiveStep(index + 1)}
            testID={`create-offer-demo-show-button-${name}`}
          >
            Show Modal
          </Button>
        </DemoBlock>
      ))}

      <MultiPageModal
        actionRight={
          <Button
            testID="create-offer-demo-view-details-button"
            variant="text"
          >
            View Details
          </Button>
        }
        activeStep={activeStep}
        onClose={() => setActiveStep(undefined)}
        onStepChange={setActiveStep}
        showProgressIndicator={false}
        testID="create-offer-demo-multi-page-modal"
        title="Create Offer"
      >
        <MultiPageStep
          nextButton={(handleClick) => (
            <Button
              onClick={handleClick}
              testID="create-offer-demo-multi-step-modal-review-offer-button"
            >
              Review Offer
            </Button>
          )}
        >
          <Box sx={styles.box}>
            <AddPrices
              onChange={() => {
                console.log("onChange");
              }}
              products={demoProducts}
            />
          </Box>
        </MultiPageStep>

        <MultiPageStep
          nextButton={(handleClick) => (
            <Button
              onClick={handleClick}
              testID="create-offer-demo-submit-button"
            >
              Submit
            </Button>
          )}
          previousButton={(handleClick) => (
            <>
              <Button
                onClick={handleClick}
                testID="create-offer-demo-previous-button"
                variant="text"
              >
                <ArrowBack sx={styles.icon} /> Back
              </Button>
              <HSpacer size="2" />
            </>
          )}
        >
          <Box sx={styles.box}>
            <ReviewAndSubmit
              handleShippingChange={() => console.log("handleShippingChange")}
              offer={demoOffer}
              priceRequest={demoPriceRequest}
              products={demoProducts}
            />
          </Box>
        </MultiPageStep>
      </MultiPageModal>
      <VSpacer size="7" />
      <DemoBlock label="Subtask modals">
        <Button onClick={() => setShowAddPriceModal(true)} testID="demo-button">
          Add Price Modal
        </Button>
        <VSpacer size="5" />
        <Button onClick={() => setShowEditPriceModal(true)} testID="demo-button">
          Edit Price Modal
        </Button>
        <VSpacer size="5" />
        <Button onClick={() => setShowPriceList(true)} testID="demo-button">
          Price List
        </Button>
       <VSpacer size="5" />
        <Button onClick={() => setShowSuccessModal(true)} testID="demo-button">
          Success Screen
        </Button>
      </DemoBlock>
      {showAddPriceModal && (
        <AddPriceModal
          onClose={() => setShowAddPriceModal(false)}
          onSave={(newProduct) => console.log(newProduct)}
          product={demoProducts[2]}
        />
      )}
      {showEditPriceModal && (
        <AddPriceModal
          onClose={() => setShowEditPriceModal(false)}
          onSave={(newProduct) => console.log(newProduct)}
          product={demoProducts[0]}
        />
      )}
      {showPriceList && (
        <PriceList
          onClose={() => setShowPriceList(false)}
          products={demoProducts}
        />
      )}
      {showSuccessModal && (
        <CreateOfferSuccessDialog
          daysToAccept={12}
          onDone={() => {
            setShowSuccessModal(false);
          }}
        />
      )}
    </>
  );
};
