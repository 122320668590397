import {
  Button,
  Card,
  Chip,
  ChipProps,
  Dialog,
  Heading,
  HSpacer,
  InlineMessage,
  ProgressLoader,
  SplitTabs,
  Text,
  TopAppBar,
  VSpacer,
} from '@/components/DesignSystem';
import { GlobalBanner } from '@/components/DesignSystem/Banner/GlobalBanner';
import { ProductCard } from '@/components/DesignSystem/ProductCard/ProductCard';
import { DesktopOnly } from '@/components/shared/DesktopOnly';
import { MobileOnly } from '@/components/shared/MobileOnly';
import {
  PriceRequestOrderModal,
} from '@/components/shared/PriceRequestOrder/PriceRequestOrderModal';
import { FriendlyPricingRequestStatus } from '@/constants/FriendlyPricingRequestStatus';
import { QueryKeys } from '@/constants/QueryKeys';
import { Routes } from '@/constants/Routes';
import { useAuthentication } from '@/contexts/dataSync/AuthenticationContext';
import { getResponsiveValue, useMediaQuery } from '@/hooks/useMediaQuery';
import { OfferProductsRetailer, ProductOffers } from '@/pages/CompareAcceptOffers/interfaces';
import { OfferProduct } from '@/pages/CreateSendPriceRequest/interfaces';
import { FarmerPaymentModal } from '@/pages/Payment/FarmerPaymentModal';
import { SelectPaymentTypeModal } from '@/pages/Payment/SelectPaymentTypeModal';
import { ConfirmReceiptModal } from '@/pages/ReviewSelectOffers/ConfirmReceiptModal';
import { getProductOffers } from '@/pages/ReviewSelectOffers/helpers';
import { MyOrderButton } from '@/pages/ReviewSelectOffers/MyOrderButton';
import { ProductOfferCard } from '@/pages/ReviewSelectOffers/ProductOfferCard';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { PaymentApi } from '@/utilities/api/PaymentApi';
import { PricingRequestsApi } from '@/utilities/api/PricingRequestsApi';
import { calculateStripeTotals } from '@/utilities/Offer';
import { getStatusChipPropsFromPRStatusNew } from '@/utilities/PricingRequest';
import { getSubdomain } from '@/utilities/ThemeUtilities';
import { PricingRequestEndpoint } from '@api/endpoints';
import { ApiOffer, ApiPricingRequestProduct } from '@api/interfaces';
import { Warning } from '@mui/icons-material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import Check from '@mui/icons-material/Check';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Description from '@mui/icons-material/Description';
import Email from '@mui/icons-material/Email';
import { Alert, Box, Container, Divider, Stack, Tab, useTheme } from '@mui/material';
import {
  AllowedPaymentMethodType,
  OfferStatus,
  PaymentMethodPreference,
  PricingRequestPaymentStatus,
  PricingRequestStatus,
  PricingRequestStatusNew,
} from '@shared/enums';
import { PaymentMethodNew } from '@shared/enums/PaymentMethod';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { PriceRequestDetails } from './PriceRequestDetails';
import { PriceRequestDetailsModal } from './PriceRequestDetailsModal';
import { RetailerCard } from './RetailerCard';
import { RetailerOffer } from './RetailerOffer';

export interface Totals {
  shippingCost: number,
  stripeFee: number,
  subtotal: number,
}

const ReviewSelectOffers = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isMobile } = useMediaQuery();
  const { openSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { user } = useAuthentication();
  const subdomain = getSubdomain();
  const theme = useTheme();

  const [selectedTab, setSelectedTab] = useState<number>(subdomain ? 0 : 1);
  const [invalidProductRequest, setInvalidProductRequest] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showOrder, setShowOrder] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<ApiOffer>();
  const [isInOpenStatus, setIsInOpenStatus] = useState(false);
  const [isInFinishStatus, setIsInFinishStatus] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showPaymentCompleteModal, setShowPaymentCompleteModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = (
    useState<AllowedPaymentMethodType>(AllowedPaymentMethodType.ACH)
  );
  const [paymentStatus, setPaymentStatus] = useState<PricingRequestPaymentStatus>();
  const [totals, setTotals] = useState<Totals>({
    shippingCost: 0,
    subtotal: 0,
    stripeFee: 0,
  });
  const [showPaymentTypeModal, setShowPaymentTypeModal] = useState(false);
  const [showConfirmReceiptModal, setShowConfirmReceiptModal] = useState(false);

  const { data: productRequest, isFetching: isRequestFetching } = useQuery(
    [QueryKeys.GET_PRICING_REQUEST, id],
    () => PricingRequestsApi.getPricingRequest(id as string),
    {
      enabled: !!id,
      onSuccess: (data) => {
        const isOpen = data.status === PricingRequestStatus.Open;
        const acceptedOrClosed = data.status === PricingRequestStatus.Accepted
          || data.status === PricingRequestStatus.Closed;
        setIsInOpenStatus(isOpen);
        setIsInFinishStatus(acceptedOrClosed);
        setShowBanner(data.status === PricingRequestStatus.Review);
        setPaymentStatus(data.paymentStatus ?? undefined);
      },
      onError: () => {
        setInvalidProductRequest(true);
      },
      retry: false,
    },
  );

  const { data: offers, isFetching: areOffersFetching } = useQuery(
    [QueryKeys.GET_OFFERS, id],
    async () => {
      const offers = await PricingRequestsApi.getOffers(id as string);
      const totalsResult = offers?.data.map((offer) => calculateStripeTotals(offer)) ?? [];
      const calculatedTotals = totalsResult.reduce((acc, totalItem) => {
        acc.subtotal += totalItem.subtotal;
        acc.shippingCost += totalItem.shippingCost;
        return acc;
      }, { shippingCost: 0, subtotal: 0 });
      setTotals({ ...calculatedTotals, stripeFee: 0 });
      return offers;
    },
    {
      enabled: !!id && !!productRequest,
      retry: false,
    },
  );

  const { data: stripeFees } = useQuery(
    [QueryKeys.GET_STRIPE_FEES, totals],
    () => {
      const totalAmount = totals.shippingCost + totals.subtotal;
      return PaymentApi.getPaymentFee(totalAmount);
    },
    {
      enabled: !!offers,
      retry: false,
    },
  );

  useEffect(() => {
    const stripeFee = stripeFees?.[paymentMethod]?.convenienceFee ?? 0;
    setTotals({ ...totals, stripeFee });
  }, [paymentMethod, stripeFees]);

  const prStatus = useMemo((): ChipProps => {
    if (productRequest?.farmerPricingRequestStatus) {
      if (paymentStatus === PricingRequestPaymentStatus.PAYMENT_RECEIVED
        && productRequest.farmerPricingRequestStatus !== PricingRequestStatusNew.OfferClosed) {
        return getStatusChipPropsFromPRStatusNew(PricingRequestStatusNew.AwaitingFulfillment);
      }
      return getStatusChipPropsFromPRStatusNew(
        productRequest?.farmerPricingRequestStatus,
      );
    }
    return { label: undefined, testID: '' };
  }, [paymentStatus, productRequest?.farmerPricingRequestStatus]);

  const isPickupReady =
    productRequest?.farmerPricingRequestStatus === PricingRequestStatusNew.PickupReady;
  const isOrderShipped =
    productRequest?.farmerPricingRequestStatus === PricingRequestStatusNew.OrderShipped;
  const showConfirmReceiptAction = isPickupReady || isOrderShipped;
  const showPayNowAction = (
    productRequest?.isPaymentDue
    && !productRequest?.isPaymentSubmitted
    && paymentStatus !== PricingRequestPaymentStatus.PAYMENT_RECEIVED
    && paymentStatus !== PricingRequestPaymentStatus.PAYMENT_INITIATED_PROCESSING
  );
  const showMyOrderButton = (
    !!offers?.data.length
    && (
      productRequest?.status === PricingRequestStatus.Open
      || productRequest?.status === PricingRequestStatus.Review
    )
  );
  const offerAcceptedStatuses = [
    OfferStatus.Accepted,
    OfferStatus.AwaitingDelivery,
    OfferStatus.AwaitingFulfillment,
    OfferStatus.AwaitingPickup,
    OfferStatus.OrderShipped,
    OfferStatus.Partial,
    OfferStatus.PickupReady,
  ];
  const hasSomeOffersInside = productRequest?.offers?.some((offer) => (
    offer.paymentMethodPreference === PaymentMethodPreference.INSIDE
      && offerAcceptedStatuses.includes(offer.status)
  ));

  const [submittedProducts, setSubmittedProducts] = useState<ProductOffers[]>([]);
  useEffect(() => {
    setSubmittedProducts(
      getProductOffers(offers?.data || [], productRequest?.salespersons),
    );
  }, [offers, productRequest]);

  const unSubmittedProducts = useMemo(() => {
    const result = [];

    for (const product of (productRequest?.products || [])) {
      const productHasOffer = !!submittedProducts.find(p => (
        p.product.id === product.id
        && p.retailers?.[0].offerProduct?.productId === product.productId
      ));
      if (!productHasOffer) {
        result.push({ ...product, companionProducts: [] });
      }
      for (const companionProduct of (product.companionProducts || [])) {
        const companionProductHasOffer = !!submittedProducts.find(p => (
          p.product.id === companionProduct.id
          && p.retailers?.[0].offerProduct?.productId === companionProduct.productId
        ));
        if (!companionProductHasOffer) {
          result.push(companionProduct);
        }
      }
    }
    return result;
  }, [productRequest, submittedProducts]);

  const unSubmittedRetailers = useMemo(() => {
    return productRequest?.salespersons?.filter(
      x => offers?.data?.findIndex(y => y.pricingRequestRetailerId === x.id) === -1,
    );
  }, [offers, productRequest]);

  const acceptedOffers = useMemo(() => (
    submittedProducts.filter(({ product }) => !!product.acceptedOfferProductId)
  ), [submittedProducts]);

  const offerProductsRetailers = useMemo(() => (
    acceptedOffers.reduce((acc, productOffers) => {
      const productOfferRetailer = productOffers.retailers.find(({ offerProduct }) => (
        productOffers.product.acceptedOfferProductId === offerProduct?.id
      ));

      if (!productOfferRetailer?.offerProduct) {
        return acc;
      }

      const offerProductsRetailer = acc.find(({ retailer }) => (
        retailer.id === productOfferRetailer?.retailer?.id
      ));

      const offerProduct: OfferProduct = {
        name: productOffers.product.name,
        initialUom: productOffers.product.uom,
        initialQuantity: productOffers.product.quantity,
        ...productOfferRetailer.offerProduct,
      };

      if (!offerProductsRetailer) {
        acc.push({
          retailer: {
            ...productOfferRetailer.retailer,
            createdAt: productOfferRetailer?.retailer?.createdAt ?? new Date(),
            id: productOfferRetailer?.retailer?.id ?? null,
            name: productOfferRetailer?.retailer?.name ?? null,
          },
          offerProducts: [offerProduct],
        });
      } else {
        offerProductsRetailer.offerProducts.push(offerProduct);
      }

      return acc;
    }, [] as OfferProductsRetailer[])
  ), [acceptedOffers]);

  const isReadonly = (
    productRequest?.status !== PricingRequestStatus.Review
    && productRequest?.status !== PricingRequestStatus.Open
  );
  const showOrderDetailsButton = !!productRequest?.farmerPricingRequestStatus && [
    PricingRequestStatusNew.PaymentDue,
    PricingRequestStatusNew.OnAccountRequested,
    PricingRequestStatusNew.FinancingRequested,
    PricingRequestStatusNew.AwaitingFulfillment,
    PricingRequestStatusNew.PickupReady,
    PricingRequestStatusNew.OrderShipped,
    PricingRequestStatusNew.OfferClosed,
    PricingRequestStatusNew.OfferExpired,
  ].includes(productRequest.farmerPricingRequestStatus);


  const handleViewOrder = useCallback(() => {
    setShowOrder(true);
  }, []);

  const { mutate: rateAndCloseRequest } = useMutation(
    async (ratings: Map<string, number | undefined>) => {
      const ratingObjs= Array.from(ratings).map(([name, rating]) => {
        const retailerId = offerProductsRetailers.find(
          ({ retailer }) => (retailer.name === name),
        )?.retailer.salespersonId;
        const locationId = productRequest?.salespersons?.find(
          (salesPerson) => salesPerson.retailerSalesperson?.id === retailerId,
        )?.retailerSalesperson?.locationId;
        return {
          locationId,
          pricingRequestId: id,
          rating,
          retailerId,
          userId: user?.id,
        };
      });
      const validRatings= ratingObjs.filter(({ retailerId, userId }) => (
        [retailerId, userId].every(Boolean)
      )) as PricingRequestEndpoint.RateAndClose.Request;
      await PricingRequestsApi.rateAndClose(id!, validRatings);
    },
    {
      onSuccess: async () => {
        openSnackbar(isPickupReady ? 'Pickup confirmed' : 'Delivery confirmed');
        await queryClient.invalidateQueries([QueryKeys.GET_PRICING_REQUEST, id]);
      },
      onError: (error: { message: string }) => {
        openSnackbar(error.message || 'An error has occurred');
      },
    },
  );

  const handlePaymentMethodSelection = async (selectedPaymentMethod: PaymentMethodNew) => {
    if (selectedPaymentMethod === PaymentMethodNew.Bank) {
      setPaymentMethod(AllowedPaymentMethodType.ACH);
      setShowPaymentModal(true);
    } else if (selectedPaymentMethod === PaymentMethodNew.Card) {
      setPaymentMethod(AllowedPaymentMethodType.CreditCard);
      setShowPaymentModal(true);
    } else if (id) {
      await PaymentApi.requestPayment(id, selectedPaymentMethod);
      await queryClient.invalidateQueries([QueryKeys.GET_PRICING_REQUEST, id]);
    }
    setShowPaymentTypeModal(false);
  }

  const { mutate: acceptOfferProducts } = useMutation(
    async () => {
      if (!id) {
        return;
      }

      const offerProductIds = acceptedOffers.map((productOffers) => (
        productOffers.product.acceptedOfferProductId as string
      ));

      await PricingRequestsApi.acceptOfferProducts(id, { offerProductIds });
      await queryClient.invalidateQueries([QueryKeys.GET_PRICING_REQUEST, id]);
      await queryClient.invalidateQueries([QueryKeys.GET_OFFERS, id]);
      setShowOrder(false);
      openSnackbar('Order submitted successfully');
    },
    {
      onError: (error: { message: string, code: string }) => {
        openSnackbar(
          error.code === "400" ? error.message : "An error has occurred",
        );
      },
    },
  );

  const getParentProductName = (product: ApiPricingRequestProduct) =>{
    if (product.alternativeTo) {
      return product.alternativeTo;
    }
    if (product.companionToProductId) {
      return productRequest?.products?.find((p) => p.id === product.companionToProductId)?.name
    }
    return;
  }

  const LoadingSpinner = (
    <Stack alignItems="center">
      <ProgressLoader type="circular" />
    </Stack>
  );

  const ProductList = (
    (isRequestFetching || areOffersFetching) ? (
      <Box mt="20px">
        {LoadingSpinner}
      </Box>
    ) : (
      <>
        <Stack divider={<Divider />}>
          {submittedProducts.map((productOffers, index) => (
            <ProductOfferCard
              isClosed={isInFinishStatus}
              key={index}
              onChange={(newProductOffers) => setSubmittedProducts(
                submittedProducts.map((currentProductOffers) => {
                  const isSameOfferProduct = (
                    currentProductOffers.product.id === productOffers.product.id
                    && currentProductOffers.retailers?.[0].offerProduct?.id
                    === productOffers.retailers?.[0].offerProduct?.id
                  );
                  return isSameOfferProduct
                    ? newProductOffers
                    : currentProductOffers;
                }),
              )}
              onViewOrder={handleViewOrder}
              orders={acceptedOffers.length}
              parentProductName={getParentProductName(
                productOffers.product as ApiPricingRequestProduct,
              )}
              productOffers={productOffers}
              readonly={isReadonly}
              testID={`product-list-item-${index}`}
            />
          ))}
        </Stack>
        {!!unSubmittedProducts?.length && (
          <>
            <Divider />
            {!isInOpenStatus && (
              <>
                <VSpacer size="9" />
                <Box px={getResponsiveValue('17px', '0')}>
                  <Heading
                    level="3"
                    testID="review-select-offers-no-products-submitted"
                    title="No Offers submitted"
                  />
                </Box>
                <VSpacer size="7" />
                <Divider />
              </>
            )}
            {unSubmittedProducts.map((product, index) => (
              <>
                {isInOpenStatus ? (
                  <ProductOfferCard
                    isInOpenStatus
                    key={product.id}
                    onChange={() => null}
                    onViewOrder={handleViewOrder}
                    orders={acceptedOffers.length}
                    parentProductName={getParentProductName(product as ApiPricingRequestProduct)}
                    productOffers={{ product: product as ApiPricingRequestProduct, retailers: [] }}
                    readonly
                    testID={`product-list-item-${index}`}
                  />
                ) : (
                  <Box key={product.id} px={getResponsiveValue('17px', '0')} py="20px">
                    <ProductCard
                      companionToProductName={getParentProductName(
                        product as ApiPricingRequestProduct,
                      )}
                      disableClick
                      lineThrough={!isInOpenStatus}
                      product={product as unknown as OfferProduct}
                      testID={`product-list-item-unsubmitted-${index}`}
                    />
                  </Box>
                )}
                <Divider />
              </>
            ))}
          </>
        )}
      </>
    )
  );

  const NonStripePaymentCard = (
    <>
      {(productRequest?.status === PricingRequestStatus.OnAccountRequested
        || productRequest?.status === PricingRequestStatus.FinancingRequested) &&
        <>
          <Card
            sx={{ borderRadius: isMobile ? undefined : "8px" }}
            testID="non-stripe-payment-card"
          >
            <Stack flexDirection="row" justifyContent="space-between">
              <Text>Payment</Text>
              <HSpacer size="3" />
              <Text>{FriendlyPricingRequestStatus[productRequest.status]}</Text>
            </Stack>
          </Card>
          <VSpacer size="5" />
        </>
      }
    </>
  )

  const PaidBadge = (
    <>
      {hasSomeOffersInside &&
        productRequest?.paymentStatus === PricingRequestPaymentStatus.PAYMENT_RECEIVED &&
        <>
          <Card sx={{ borderRadius: isMobile ? undefined : "8px" }} testID="paid-status-card">
            <Stack alignItems="center" flexDirection="row" justifyContent="center">
              <CheckCircle color="success" />
              <HSpacer size="3" />
              <Text>Paid</Text>
            </Stack>
          </Card>
          <VSpacer size="5" />
        </>
      }
    </>
  )

  const RetailerList = (
    (isRequestFetching || areOffersFetching) ? (
      <Box mt="20px">
        {LoadingSpinner}
      </Box>
    ) : (
      <>
        <Stack divider={<Divider />}>
          {offers?.data.map((offer, index) => {
            if (!offer.pricingRequestRetailer) {
              return null;
            }

            const matchingOffers = submittedProducts.filter((submittedProduct) =>
              offer?.products?.some(
                (product) => submittedProduct.product.acceptedOfferProductId === product.id,
              ),
            );

            return (
              <Box
                key={offer.id}
                px={getResponsiveValue('17px', '0')}
                py="20px"
              >
                <RetailerCard
                  acceptedProducts={matchingOffers}
                  bottomLeftAccessory={(
                    <Stack spacing={1}>
                      {(!!offer.note) && (
                        <VSpacer size="2"/>
                      )}
                      <Stack alignItems="center" direction="row" flexWrap="wrap">
                        {!!offer.note && (
                          <>
                            <InlineMessage
                              icon={<Email color="info" fontSize="small" />}
                              testID={`retailer-list-note-badge-${index}`}
                              title="Note"
                              titleCategory="overline"
                              titleColor="info"
                              titleSpacing="3"
                            />
                            <HSpacer size="5"/>
                          </>
                        )}
                      </Stack>
                    </Stack>
                  )}
                  isInOpenStatus={isInOpenStatus}
                  onClick={() => setSelectedOffer(offer)}
                  productOfferRetailer={{
                    retailer: offer.pricingRequestRetailer,
                  }}
                  rightAccessory={(
                    <Stack alignItems="center">
                      <Box>
                        <Text category="body-xlarge" color="primary">
                          {offer.products?.length ?? 0}
                        </Text>
                      </Box>
                      <Text category="overline" color="primary">
                        Products
                      </Text>
                    </Stack>
                  )}
                  testID={`retailer-list-item-${index}`}
                />
              </Box>
            )
          })}
        </Stack>
        {!!unSubmittedRetailers?.length && (
          <>
            {
              !isInOpenStatus && (
                <>
                  <Divider />
                  <VSpacer size="9" />
                  <Box px={getResponsiveValue('17px', '0')}>
                    <Heading
                      level="3"
                      testID="review-select-offers-no-offers-submitted"
                      title="No Offers submitted"
                    />
                  </Box>
                  <VSpacer size="7" />
                  <Divider />
                </>
              )
            }
            <Stack divider={<Divider />}>
              {unSubmittedRetailers?.map((retailer, index) => (
                <Box key={retailer.id} onClick={() => {
                  setSelectedOffer({
                    pricingRequestRetailer: retailer,
                  } as ApiOffer);
                }} px={getResponsiveValue('17px', '0')} py="20px" sx={{
                  cursor: "pointer",
                }}>
                  <RetailerCard
                    isInOpenStatus={isInOpenStatus}
                    lineThrough
                    productOfferRetailer={{ retailer }}
                    testID={`retailer-list-item-unsubmitted-${index}`}
                  />
                </Box>
              ))}
            </Stack>
          </>
        )}
        <RetailerOffer
          acceptedOffers={acceptedOffers}
          isClosed={isInFinishStatus}
          onViewOrder={handleViewOrder}
          productRequest={productRequest!}
          readonly={isReadonly}
          selectedOffer={selectedOffer}
          setSelectedOffer={setSelectedOffer}
          setSubmittedProducts={setSubmittedProducts}
          submittedProducts={submittedProducts}
        />
      </>
    )
  );

  const PriceRequestDetailsView = (
    <Card sx={{ borderRadius: "8px" }} testID="price-request-details">
      {!!productRequest && (
        <Stack maxWidth={isMobile ? undefined : '370px'}>
          <PriceRequestDetails priceRequest={productRequest} />
        </Stack>
      )}
    </Card>
  );

  const InvalidPriceRequestMessage = (
    <Container maxWidth="md">
      <VSpacer size="8" />
      <Alert color="error" icon={false}>
        The specified price request was not found or you cannot review it yet.
      </Alert>
    </Container>
  );

  const Banner = (
    <GlobalBanner
      bottomSpacerSize="7"
      description={isMobile ? "Select a product or retailer to find the best offers" : ""}
      message={!isMobile
        ? "Offers ready for review. Select a product or retailer to find the best offers!"
        : "Offers ready for review"}
      onClose={() => setShowBanner(false)}
      status="info"
      testID="review-and-select-offers-banner"
    />
  );

  const OrderDetailsButton = (props: { isTextVariant?: boolean}) => (
    <>
      {showOrderDetailsButton && (
        <Stack direction="row">
          <Button
            color="primary"
            onClick={handleViewOrder}
            startIcon={<Description />}
            testID="price-request-order-details"
            variant={props.isTextVariant ? "text" : "outlined"}
          >
            Order details
          </Button>
        </Stack>
      )}
    </>
  );

  const PriceRequestStatusBadge = (
    <>
      {paymentStatus === PricingRequestPaymentStatus.PAYMENT_INITIATED_PROCESSING
        || paymentStatus === PricingRequestPaymentStatus.PAYMENT_INITIATED ? (
          <Chip
            color="info"
            label={PricingRequestPaymentStatus.PAYMENT_INITIATED_PROCESSING}
            testID="processing-payment-chip"
          />
        ) : (
          <Chip {...prStatus} />
        )
      }
    </>
  );

  const TopBar = (
    <TopAppBar
      isStatic
      leftAccessory={
        <Button
          onClick={() => navigate(Routes.PAST_PRICING_REQUESTS)}
          startIcon={<ArrowBack />}
          testID="view-price-request-back-button"
          variant="text"
        >
          <DesktopOnly>
            <Text category="label-medium">Back to Product Requests</Text>
          </DesktopOnly>
        </Button>
      }
      rightAccessory={showMyOrderButton ? (
        <MyOrderButton
          onViewOrder={handleViewOrder}
          orders={acceptedOffers.length}
        />
      ) : (
        <MobileOnly>
          <OrderDetailsButton isTextVariant />
        </MobileOnly>
      )}
      testID="view-price-request-top-app-bar"
      zIndex={theme.zIndex.appBar - 1}
    >
    </TopAppBar>
  );

  const PriceRequestStatus = (
    <>
      <Stack px={getResponsiveValue("17px", "0px")}>
        <Heading
          level="1"
          overline="Product Request"
          testID="price-request-id"
          title={`#${productRequest?.publicId}`}
        />
        <VSpacer size="4" />
        {PriceRequestStatusBadge}
        {showOrderDetailsButton && (
          <DesktopOnly>
            <VSpacer size="6" />
            <OrderDetailsButton />
          </DesktopOnly>
        )}
      </Stack>
      <MobileOnly>
        <VSpacer size="3" />
        <Stack direction="row" px="10px">
          <Button
            onClick={() => setShowDetailsModal(true)}
            testID="price-request-view-details"
            variant="text"
          >
            View Details
          </Button>
        </Stack>
      </MobileOnly>
    </>
  );

  const ActionsCard = (
    <>
      <Card sx={{ borderRadius: "8px" }} testID="price-request-payment">
        {showPayNowAction && (
          <Stack flexDirection="row" justifyContent="space-between">
            <InlineMessage
              icon={<Warning color="warning" fontSize="small" />}
              testID="payment-due-badge"
              title="Payment due"
              titleColor="warning"
            />
            <Button
              onClick={() => setShowPaymentTypeModal(true)}
              testID="product-request-pay-now-button"
            >
              Pay now
            </Button>
          </Stack>
        )}
        {showConfirmReceiptAction && (
          <Stack alignItems="center">
            <Button
              onClick={async () => {
                if (subdomain) {
                  const retailerNames = (
                    offerProductsRetailers.map(({ retailer }) => retailer.name)
                  );
                  const ratings = new Map(retailerNames.map((name) => [name, undefined]));
                  await rateAndCloseRequest(ratings);
                } else {
                  setShowConfirmReceiptModal(true);
                }
              }}
              testID="product-request-confirm-receipt-button"
            >
              {isPickupReady ? 'Confirm Pickup' : 'Confirm Delivery'}
            </Button>
          </Stack>
        )}
      </Card>
      <VSpacer size="5" />
    </>
  );

  const DetailsView = (
    <Stack sx={{ minWidth: 325 }}>
      {NonStripePaymentCard}
      {PaidBadge}
      {(showPayNowAction || showConfirmReceiptAction) && ActionsCard}
      <Card sx={{ borderRadius: "8px" }} testID="price-request-status">
        {isRequestFetching
          ? LoadingSpinner
          : PriceRequestStatus}
      </Card>
      <VSpacer size="5" />
      {!isRequestFetching && PriceRequestDetailsView}
    </Stack>
  );

  const TabsView = (
    <>
      <SplitTabs
        onChange={(_, value) => setSelectedTab(value)}
        sx={{
          paddingLeft: getResponsiveValue('17px', '0px'),
          paddingRight: getResponsiveValue('17px', '0px'),
        }}
        testID="review-select-offers-tabs"
        value={selectedTab}
      >
        <Tab label="Products" />
        {!subdomain &&
          <Tab label="Retailers" />
        }
      </SplitTabs>
      <VSpacer size="5" />
      <Divider />
      {selectedTab === 0 && ProductList}
      {selectedTab === 1 && RetailerList}
    </>
  );

  if (invalidProductRequest) {
    return InvalidPriceRequestMessage;
  }

  return (
    <>
      {TopBar}
      {showBanner && !subdomain && Banner}
      <DesktopOnly>
        <Stack direction="row" px="24px">
          {DetailsView}
          <HSpacer size="7" />
          <Stack flexGrow="1" minWidth={isMobile ? undefined : '50%'}>
            <Card
              sx={{ borderRadius: "8px" }}
              testID="review-select-offers-tabs-view"
            >
              {TabsView}
            </Card>
          </Stack>
        </Stack>
      </DesktopOnly>
      <MobileOnly>
        {NonStripePaymentCard}
        {PaidBadge}
        {(showPayNowAction || showConfirmReceiptAction) && ActionsCard}
        {PriceRequestStatus}
        <VSpacer size="6" />
        {TabsView}
      </MobileOnly>
      {!!productRequest && (
        <>
          <PriceRequestDetailsModal
            onClose={() => setShowDetailsModal(false)}
            open={showDetailsModal}
            priceRequest={productRequest}
          />
          <PriceRequestOrderModal
            offerProductsRetailers={offerProductsRetailers}
            onClose={() => setShowOrder(false)}
            onOfferProductDelete={(offerProduct) => setSubmittedProducts(
              submittedProducts.map((productOffers) => (
                productOffers.product.acceptedOfferProductId === offerProduct.id ? {
                  ...productOffers,
                  product: {
                    ...productOffers.product,
                    acceptedOfferProductId: null,
                  },
                } : productOffers
              )),
            )}
            onSubmit={() => acceptOfferProducts()}
            open={showOrder}
            priceRequest={productRequest}
            readonly={isReadonly}
            testID="price-request-order-modal"
          />
          {showPaymentModal &&
            <FarmerPaymentModal
              onClose={() => setShowPaymentModal(false)}
              onSuccess={(status) => {
                setShowPaymentModal(false);
                setShowPaymentCompleteModal(true);
                setPaymentStatus(status);
              }}
              paymentMethod={paymentMethod}
              productRequest={productRequest}
              totals={totals}
            />
          }
          {showPaymentCompleteModal &&
            <Dialog
              acceptButton={(props) => (
                <Button
                  onClick={() => setShowPaymentCompleteModal(false)}
                  {...props}
                >
                  Dismiss
                </Button>
              )}
              heroIcon={<Check color="success" />}
              onClose={() => setShowPaymentCompleteModal(false)}
              open
              showCloseButton={false}
              testID="payment-complete-modal"
              title="Payment complete"
            >
              <Text textAlign="center">
                You'll receive a confirmation email from Stripe acknowledging receipt of your
                payment.
              </Text>
            </Dialog>
          }
          {showPaymentTypeModal &&
            <SelectPaymentTypeModal
              onClose={() => setShowPaymentTypeModal(false)}
              onSubmit={handlePaymentMethodSelection}
              open
            />
          }
          {showConfirmReceiptModal &&
            <ConfirmReceiptModal
              onClose={() => setShowConfirmReceiptModal(false)}
              onConfirm={rateAndCloseRequest}
              retailerNames={offerProductsRetailers.map(({ retailer }) => retailer.name)}
            />
          }
        </>
      )}
    </>
  );
}

export default ReviewSelectOffers;
