import { Button, Card, Text } from '@/components/DesignSystem';
import AddIcon from '@mui/icons-material/Add';
import { Stack } from '@mui/material';
import React from 'react';

interface SalespersonRecipientCardProps {
  onAction: () => void,
  salesperson: { businessName: string, id: string },
  selectedRecipientIds: string[],
}
export const SalespersonRecipientCard = ({
  onAction,
  salesperson,
  selectedRecipientIds,
}: SalespersonRecipientCardProps) => {
  const isUserAdded = selectedRecipientIds.includes(salesperson.id);

  return (
    <Card testID={`${salesperson.id}-recipient-card`}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Text category="title-small">{salesperson.businessName}</Text>
        {isUserAdded ? (
          <Button
            onClick={onAction}
            testID={`${salesperson.id}-recipient-remove-button`}
            variant="text"
          >
            Remove
          </Button>
        ) : (
          <Button
            onClick={onAction}
            startIcon={<AddIcon />}
            testID={`${salesperson.id}-recipient-add-button`}
            variant="outlined"
          >
            Add
          </Button>
        )}
      </Stack>
    </Card>
  );
}
