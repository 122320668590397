import {
  Button,
  Input,
  MenuItem,
  Modal,
  Search,
  Select,
  Text,
  VSpacer,
} from '@/components/DesignSystem';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';
import {
  Alert,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import UploadIcon from '@mui/icons-material/Upload';
import { SponsoredBannerApi } from '@/utilities/api/SponsoredBannerApi';
import { QueryKeys } from '@/constants/QueryKeys';
import { ProductsApi } from '@/utilities/api/ProductsApi';
import { ManufacturerApi } from '@/utilities/api/ManufacturerApi';
import { ApiManufacturer } from '@api/interfaces';
import { useSearch } from '@/hooks/useSearch';
import { AddSponsoredBannerRadioGroup, errorMessages, ToastMessages } from '@/constants/constant';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { AppConfig } from '@/constants/AppConfig';
import { sanitizedFileName } from '@shared/utilities';

interface GetProducts {
  id?: string;
  name: string | null;
  categoryId: string | null;
  type?: string | null;
  image?: string | null;
  description: string | null;
  slug: string | null;
  createdBy: string | null;
  status: string | null;
  isFeatured: boolean | null;
  purchaseUom: Array<string> | null;
  packageSize: Array<string> | null;
  sellSheet: string | null;
  keywords: Array<string> | null;
  isRestrictedUse: boolean | null;
}

type CardDetailsProp = {
  bannerImage?: string;
  id: string;
  manufacturerId?: string;
  name: string;
  productId?: string;
  rank: number;
  retailerId?: string;
};

interface SaveSponsoredBannerModalProps {
  close: () => void;
  cardDetails?: CardDetailsProp;
}

export const SaveSponsoredBannerModal = ({
  close,
  cardDetails,
}: SaveSponsoredBannerModalProps) => {
  let sponsoredTypeKey = '',
    sponsoredTypeValue = '';

  if (cardDetails?.manufacturerId) {
    sponsoredTypeKey = 'manufacturerId';
    sponsoredTypeValue = cardDetails?.manufacturerId;
  } else if (cardDetails?.retailerId) {
    sponsoredTypeKey = 'retailerId';
    sponsoredTypeValue = cardDetails?.retailerId;
  } else if (cardDetails?.productId) {
    sponsoredTypeKey = 'productId';
    sponsoredTypeValue = cardDetails?.productId;
  }
  const viewCardDetails = {
    name: cardDetails?.name ?? '',
    bannerImage: cardDetails?.bannerImage ?? '',
    sponsoredType: sponsoredTypeKey,
    dropdownValue: sponsoredTypeValue,
  };

  const [bannerName, setBannerName] = useState(viewCardDetails?.name ?? '');
  const [sponsoredType, setSponsoredType] = useState(
    viewCardDetails?.sponsoredType ?? '',
  );
  const [dropdownValue, setDropdownValue] = useState(
    viewCardDetails?.dropdownValue ?? '',
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadedLogo, setUploadedLogo] = useState({ name: '', base64: '' });
  const [selectedFile, setSelectedFile] = useState<Blob>();
  const [isError, setError] = useState('');
  const queryClient = useQueryClient();

  const { openSnackbar } = useSnackbar();

  const { search, setSearch, debouncedSearch } = useSearch();

  const { data: getProducts } = useQuery(
    [QueryKeys.GET_PRODUCTS, debouncedSearch],
    () => ProductsApi.getProductList(debouncedSearch),
  );

  const { data: manufacturers } = useQuery(
    [QueryKeys.GET_MANUFACTURERS, debouncedSearch],
    () => ManufacturerApi.list({ search: debouncedSearch }),
  );

  // const { data: retailers } = useQuery([debouncedSearch], () =>
  //   UserApi.list({
  //     userType: UserType.PartnerRetailer,
  //     search: debouncedSearch,
  //   }),
  // );

  const getDropdownOptions = () => {
    switch (sponsoredType) {
      case 'retailerId':
        return;
        // return retailers?.data?.map((retailer: ApiUser) => ({
        //   name: retailer.businessName ?? '',
        //   id: retailer.id ?? '',
        // }));
      case 'productId':
        return getProducts?.data?.map((product: GetProducts) => ({
          name: product.name ?? '',
          id: product.id ?? '',
        }));
      case 'manufacturerId':
        return manufacturers?.map((manufacturer: ApiManufacturer) => ({
          name: manufacturer.name ?? '',
          id: manufacturer.id ?? '',
        }));
      default:
        return [];
    }
  };

  const getDropdownLabel = () => {
    let dropdownLabel;
    AddSponsoredBannerRadioGroup.forEach(({ label, value }) => {
      if (value === sponsoredType) {
        dropdownLabel = label;
      }
    });
    return dropdownLabel;
  };

  const handleOnTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSponsoredType(event.target.value);
    setSearch('');
    setDropdownValue('');
  };

  const fileInputRef = useRef(null);
  const handleUploadClick = () => {
    if (fileInputRef.current) {
      (fileInputRef.current as HTMLInputElement).click();
    }
  };
  const { mutate: saveSponsorBanner, isLoading: isSaving } = useMutation(
    () =>
      SponsoredBannerApi.create({
        bannerTitle: bannerName,
        bannerImage: `${uploadedLogo.name}+${uploadedLogo.base64}`,
        [sponsoredType]: dropdownValue,
      }),
    {
      onError: (error: DetailedApiError) => {
        if (error?.code == '500') {
          return setErrorMessage(errorMessages.somethingWentWrong);
        }
        return setErrorMessage(error.message);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.GET_SPONSORED_BANNER]);
        openSnackbar(ToastMessages.sponsoredBannerCreateSuccess);
        close();
      },
    },
  );

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploadedLogo({ name: '', base64: '' });
    setError('');
    const imageFile = event.target.files?.[0];

    if (imageFile) {
      if (imageFile.size > 1024 * 1024) {
        return setError(errorMessages.imageTooLarge);
      }
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;

        image.onload = () => {
          if (
            image.width !== 500 ||
            image.height !== 250 ||
            imageFile.type !== 'image/png'
          ) {
            setError(
              `Invalid image. Please upload PNG Image
                with Ratio: 2:1 Size: 500x250 pixels Max file Size: 1MB`,
            );
          } else {
            setError('');
            setUploadedLogo({
              name: sanitizedFileName(imageFile.name),
              base64: reader.result as string,
            });
            setSelectedFile(imageFile as Blob);
          }
        };
      };
      reader.readAsDataURL(imageFile);
    }
  };

  const handleRemoveFile = () => {
    setUploadedLogo({ name: '', base64: '' });
    setSelectedFile(undefined);
    if (fileInputRef.current) {
      (fileInputRef.current as HTMLInputElement).value = '';
    }
  };

  const Remove = (
    <Text
      color="primary"
      onClick={handleRemoveFile}
      sx={{ display: 'inline-block', cursor: 'pointer', marginLeft: '10px' }}
      testID='remove-image'
    >
      remove
    </Text>
  );

  return (
    <Modal
      {...(!viewCardDetails?.name?.length && {
        acceptButton: () => (
          <Button
            disabled={!dropdownValue || !bannerName || !uploadedLogo.base64}
            loading={isSaving}
            onClick={() => saveSponsorBanner()}
            testID='save'
            variant='contained'
          >
            Save
          </Button>
        ),
      })}
      cancelButton={() => (
        <Button
          disabled={isSaving}
          onClick={close}
          testID='cancel'
          variant='text'
        >
          Cancel
        </Button>
      )}
      onClose={close}
      open={true}
      testID="add-sponsorbanner-modal"
    >
      <Text category='h4'>{viewCardDetails?.name ? 'View' : 'Add'} Sponsored Banner</Text>
      <VSpacer size='3' />
      <Stack>
        {!!errorMessage && (
          <>
            <Alert color='error' icon={false}>
              {errorMessage}
            </Alert>
            <VSpacer mobileSize='5' size='8' />
          </>
        )}
        <VSpacer size='5' />
        <Typography
          color={isError && 'error'}
          component='p'
          sx={{ fontSize: '14px' }}
        >
          {isError?.length ? (
            <>{isError}</>
          ) : (
            <>
              File type: PNG Ratio: 2:1 Size: 500 x 250 pixels Max file size:
              1MB
            </>
          )}
        </Typography>
        <VSpacer size='2' />
        <Box
          style={{
            border: '0.5px dashed grey',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {!selectedFile && !viewCardDetails?.name && (
            <Button
              onClick={handleUploadClick}
              sx={{
                color: 'white',
                borderColor: 'white',
                margin: '25px 0px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              testID="upload-sponsored-banner"
              variant='outlined'
            >
              <UploadIcon /> Upload Image{' '}
              <input
                accept='image/png'
                id='raised-button-file'
                onChange={handleImageChange}
                ref={fileInputRef}
                style={{ display: 'none' }}
                type='file'
              />
            </Button>
          )}
          {viewCardDetails?.bannerImage && (
            <img
              alt={viewCardDetails?.bannerImage}
              height={'60%'}
              src={`${AppConfig.staticImageHost}/${viewCardDetails?.bannerImage}`}
              style={{ objectFit: 'cover', padding: '20px 0px' }}
              width={'60%'}
            />
          )}
          {selectedFile && (
            <Stack>
              {!isError && (
                <>
                  <img
                    alt='Selected'
                    src={URL.createObjectURL(selectedFile)}
                    style={{
                      maxWidth: '200px',
                      maxHeight: '100px',
                      marginTop: '10px',
                      marginLeft: 'auto',
                      objectFit: 'cover',
                      marginBottom: '10px',
                      marginRight: 'auto',
                    }}
                  />
                  <Stack
                    sx={{ direction: 'row', justifyContent: 'space-between' }}
                  >
                    <Text
                      sx={{
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'inline-block',
                      }}
                    >
                      {uploadedLogo.name}
                      {Remove}
                    </Text>
                  </Stack>
                </>
              )}
            </Stack>
          )}
        </Box>
        <VSpacer size='8' />
        <Input
          disabled={!!viewCardDetails?.name}
          label='Banner name'
          onChangeText={setBannerName}
          required
          testID='Banner-name'
          value={bannerName}
        />
        <VSpacer size='5' />
        <Stack>
          <Text>Sponsor type *required</Text>
          <VSpacer size='3' />
          <RadioGroup
            aria-labelledby='demo-radio-buttons-group-label'
            name='radio-buttons-group'
            onChange={handleOnTypeChange}
            sx={{ display: 'inline-block' }}
            value={sponsoredType}
          >
            {AddSponsoredBannerRadioGroup?.map(
              ({ value, label }: { value: string; label: string }) => (
                <FormControlLabel
                  control={<Radio sx={{ color: 'white !important' }} />}
                  disabled={!!viewCardDetails?.name || label === 'Retailer'}
                  key={value}
                  label={label}
                  value={value}
                />
              ),
            )}
          </RadioGroup>
          {!!sponsoredType?.length && (
            <>
              <VSpacer size='5' />
              <FormControl fullWidth>
                <Select
                  disabled={!!viewCardDetails?.name}
                  label={`Select ${getDropdownLabel()}`}
                  labelId='demo-simple-select-label'
                  onChangeValue={setDropdownValue}
                  {...(!viewCardDetails?.name?.length && {
                    onClear: () => setDropdownValue(''),
                  })}
                  testID='demo-simple-select'
                  value={dropdownValue}
                >
                  <VSpacer size='5' />
                  <MenuItem onKeyDown={(e) => e.stopPropagation()} testID='search'>
                    <Search
                      fullWidth
                      onChangeText={setSearch}
                      testID='search'
                      value={search}
                    />
                  </MenuItem>
                  {getDropdownOptions()?.length === 0 && (
                    <MenuItem disabled testID='no-products'>
                      No {getDropdownLabel()} Available
                    </MenuItem>
                  )}
                  {getDropdownOptions()?.map(
                    (option: { name: string; id: string }) => (
                      <MenuItem
                        key={option?.id}
                        testID={`option-${option?.id}`}
                        value={option?.id}
                      >
                        {option.name}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
            </>
          )}
        </Stack>
      </Stack>
    </Modal>
  );
};
