import { Button, HSpacer, Toolbar, VSpacer } from '@/components/DesignSystem';
import {
  Filter,
  FilterOption,
  FilterSelections,
} from '@/components/DesignSystem/Toolbar/interfaces';
import { useGetRetailers } from '@/hooks/useHierarchyOfRetailers';
import { useSearch } from '@/hooks/useSearch';
import { Action } from '@/pages/Admin/CustomNotifications/helper';
import { RetailerCard } from '@/pages/Admin/CustomNotifications/RetailerCard';
import { HierarchyOfRetailersApi } from '@/utilities/api/HierarchyOfRetailersApi';
import { RetailerEndpoint } from '@api/endpoints';
import { ApiRetailer } from '@api/interfaces';
import AddIcon from '@mui/icons-material/Add';
import { CircularProgress, Pagination, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface RetailersListProps {
  onChange: (updatedIds: string[]) => void,
  onSelectRetailer: (retailer: ApiRetailer) => void,
  retailerOptions: FilterOption[],
  selectedRecipientIds: string[],
  stateAndCountyOptions: FilterOption[],
}

export const RetailersList = ({
  onChange,
  onSelectRetailer,
  retailerOptions,
  selectedRecipientIds,
  stateAndCountyOptions,
}: RetailersListProps) => {
  const [page, setPage] = useState(0);
  const [isAddingAll, setIsAddingAll] = useState(false);
  const { setSearch, debouncedSearch } = useSearch(1);
  const [filterSelections, setFilterSelections] =
    useState<FilterSelections | undefined>(() => new Map());

  const selectionsString = filterSelections && JSON.stringify(
    Array.from(filterSelections).map(([, values]) => Array.from(values)),
  );

  const countyIds = Array.from(filterSelections?.get('state-county-filter') ?? []);
  const locationIds = Array.from(filterSelections?.get('location-filter') ?? []);

  const retailerFilters: RetailerEndpoint.List.Query = {
    countyId: countyIds.length ? countyIds : undefined,
    isActive: true,
    locationId: locationIds.length ? locationIds : undefined,
    search: debouncedSearch || undefined,
  };

  const {
    isLoading,
    getRetailers,
  } = useGetRetailers({
    ...retailerFilters,
    page,
  }, true);

  useEffect(() => {
    setPage(0);
  }, [debouncedSearch, selectionsString]);

  const addAll = async () => {
    setIsAddingAll(true);
    const newSelectedRecipients = new Set(selectedRecipientIds);
    const retailers = await HierarchyOfRetailersApi.listRetailers(retailerFilters, true);
    retailers.data.forEach((retailer) => {
      retailer.locationDetails?.forEach((location) => {
        location.salespersons.forEach((salesperson) => newSelectedRecipients.add(salesperson.id));
      });
    });
    onChange(Array.from(newSelectedRecipients));
    setIsAddingAll(false);
  }

  const removeAll = () => {
    onChange([]);
  }

  const showRemoveAllButton = !!selectedRecipientIds.length;

  const filters: Filter[] = [
    {
      id: 'state-county-filter',
      label: 'State & Counties',
      options: stateAndCountyOptions,
      selectionMethod: 'multi-select',
    },
    {
      id: 'location-filter',
      label: 'Retailer & Locations',
      options: retailerOptions,
      selectionMethod: 'multi-select',
    },
  ];

  return (
    <>
      <Toolbar
        filters={filters}
        onChange={({ search, selections }) => {
          setSearch(search ?? '');
          setFilterSelections(selections);
        }}
        testID="salesperson-recipients-toolbar"
        totalItems={getRetailers?.total}
        totalUnit="retailer"
      />
      <Stack flexDirection="row" justifyContent="flex-end">
        {showRemoveAllButton && (
          <Button
            onClick={removeAll}
            testID="remove-all-recipients-button"
            variant="text"
          >
            Remove all
          </Button>
        )}
        {showRemoveAllButton && (
          <HSpacer size="5" />
        )}
        <Button
          loading={isAddingAll}
          onClick={addAll}
          startIcon={<AddIcon />}
          testID="remove-all-recipients-button"
        >
          Add all
        </Button>
      </Stack>
      <VSpacer size="5" />
      {getRetailers?.data.map((retailer) => (
        <React.Fragment key={retailer.id}>
          <RetailerCard
            addedSalespersonIds={selectedRecipientIds}
            onAction={(action: Action) => {
              const updatedRecipients = new Set(selectedRecipientIds);
              const salespersonIds = retailer.locationDetails?.flatMap(
                (location) => location.salespersons.map((salesperson) => salesperson.id),
              ) ?? [];
              salespersonIds.forEach((id) => {
                if (action === Action.AddAll) {
                  updatedRecipients.add(id);
                } else {
                  updatedRecipients.delete(id);
                }
              })
              onChange(Array.from(updatedRecipients));
            }}
            onSelectRetailer={onSelectRetailer}
            retailer={retailer}
          />
          <VSpacer size="4" />
        </React.Fragment>
      ))}
      <VSpacer size='8' />
      {isLoading ? (
        <Stack
          alignItems='center'
          direction='column'
          justifyContent='center'
          sx={{ marginTop: '10rem' }}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <>
          {getRetailers && !!getRetailers.lastPage && (
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='center'
            >
              <Pagination
                count={getRetailers.lastPage + 1}
                onChange={(_, page) => {
                  setPage(page - 1);
                }}
                page={getRetailers.page + 1}
              />
            </Stack>
          )}
        </>
      )}
    </>
  )
}
