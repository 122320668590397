import { Client } from './Client';
import { ProductEndpoint } from '@api/endpoints';

export class ProductsApi {
  static getProductList (
    search: string = '',
  ): Promise<ProductEndpoint.ProductList.Response> {
    if (search) {
      return Client(`product?search=${search}&isAllProductWithPagination=true`);
    }
    return Client(`product?isAllProductWithPagination=true`);
  }
}
