import { Button, HSpacer, VSpacer } from '@/components/DesignSystem';
import { QueryKeys } from '@/constants/QueryKeys';
import ProductListTab from '@/pages/Admin/PricingRequestList/ProductListTab';
import ProductRecommendationInfo
  from '@/pages/Admin/ProductRecommendation/ProductRecommendationInfo';
import { ProductRecommendationApi } from '@/utilities/api/ProductRecommendationApi';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Card, Container, Stack } from '@mui/material';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';


const ProductRecommendationView = () => {
  const { id = '' } = useParams();

  const { data: recommendation } = useQuery(
    [QueryKeys.GET_RECOMMENDATION_BY_ID, id],
    () => ProductRecommendationApi.getById(id as string),
  );

  const navigate = useNavigate();
  return (
    <>
      <Container maxWidth='xl' sx={{ paddingBottom: '24px' }}>
        <VSpacer size='10' />
        <Button
          onClick={() => {
            navigate('/admin/product-recommendation');
          }}
          startIcon={<ArrowBackIcon />}
          testID="back-to-product-recommendations"
          variant='text'
        >
          Back to Product Recommendations
        </Button>
        <Stack sx={{ display: 'flex', flexDirection: 'revert' }}>
          <Stack sx={{ width: '325px' }}>
            <VSpacer size='5'/>
            <ProductRecommendationInfo recommendation={recommendation}/>
            <VSpacer size='5'/>
          </Stack>
          <HSpacer size='7'/>
          <Stack sx={{ width: 'calc(100% - 325px)' }}>
            <Card sx={{ padding: '20px' }}>
              <ProductListTab
                bundles={recommendation?.bundles ?? []}
                products={recommendation?.productRecommendationData ?? []}
                showPrices={true}
              />
            </Card>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default ProductRecommendationView;
