import { Client } from "./Client";
import { UserEndpoint } from "@api/endpoints"
import { State } from "@shared/enums";
import { QueryUtil } from "../Query";

export class CountyApi {
  static list (state?: State): Promise<UserEndpoint.ListCounties.Response> {
    if (!state) {
      return Client('counties');
    }

    const query = QueryUtil.stringify({ state });
    return Client(`counties?${query}`);
  }
}
