import { Chip, HSpacer, Text } from '@/components/DesignSystem';
import { Filter, FilterSelection } from '@/components/DesignSystem/Toolbar/interfaces';
import { useColor } from '@/hooks/useColor';
import { SXStyles } from '@/themes/variant-interfaces/SXStyles';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Check from '@mui/icons-material/Check';
import { Stack, useTheme } from '@mui/material';
import React from 'react';

const styles: SXStyles = {
  dropdownIcon: {
    height: '18px',
    marginRight: '-4px',
    width: '18px',
  },
} as const;

interface FilterChipProps {
  filter: Filter,
  onClick?: React.MouseEventHandler<HTMLDivElement>,
  selection?: FilterSelection,
  testID: string,
}

export const FilterChip = ({
  filter,
  onClick,
  selection,
  testID,
}: FilterChipProps) => {
  const isBoolean = filter.selectionMethod === 'boolean';
  const showCount = ['chips', 'multi-select'].includes(filter.selectionMethod);
  const selectionCount = selection?.size ?? 0;
  const hasSubOptions = filter.options.some((option) => !!option.subOptions?.length);
  const labelText = selectionCount && showCount && !hasSubOptions
    ? `${selectionCount} ${filter.label}`
    : filter.label;
  const showSelectedState = !!selection?.size;
  const { getColor, getHoverColor } = useColor();
  const theme = useTheme();
  const showCheckIcon = showSelectedState
    && (filter.selectionMethod !== 'multi-select' || hasSubOptions);

  return (
    <Chip
      key={filter.id}
      label={
        <Stack alignItems="center" direction="row">
          {showCheckIcon && (
            <>
              <Check color="primary" />
              <HSpacer size="3" />
            </>
          )}
          <Text
            category="label-large"
            color={showSelectedState ? 'primary' : undefined}
            testID={`${testID}-label`}
          >
            {labelText}
          </Text>
          {!isBoolean && (
            <>
              <HSpacer size="3"/>
              <ArrowDropDown
                color={showSelectedState ? 'primary' : undefined}
                sx={styles.dropdownIcon}
              />
            </>
          )}
        </Stack>
      }
      onClick={onClick}
      size="medium"
      sx={{
        "&:hover": {
          backgroundColor: showSelectedState
            ? getHoverColor('primary') : undefined,
        },
        backgroundColor: showSelectedState
          ? getColor('primary', theme.palette.mode === 'dark' ? 10 : 1) : undefined,
      }}
      testID={testID}
      variant={showSelectedState ? 'filled' : 'outlined'}
    />
  );
}
