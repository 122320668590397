import { Fragment, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryKeys } from '@/constants/QueryKeys';
import { FeaturedManufacturerApi } from '@/utilities/api/FeaturedManufacturerApi';
import AddFeaturedManufacturers from './AddFeaturedManufacturers';
import { useSearch } from '@/hooks/useSearch';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { DetailedApiError } from '@/utilities/api/DetailedApiError';
import { ToastMessages } from '@/constants/constant';
import DetailedCard from './DetailedCard';

const FeaturedManufacturer = () => {
  const queryClient = useQueryClient();
  const [addFeaturedManufacturer, setFeaturedManufacturer] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const { openSnackbar } = useSnackbar();

  const { search, setSearch, debouncedSearch } = useSearch();

  const { data: featuredManufacturers } = useQuery(
    [QueryKeys.GET_FEATURED_MANUFACTURERS, debouncedSearch],
    () => FeaturedManufacturerApi.list(debouncedSearch),
    {
      onSuccess: (response) => {
        if (response?.length) {
          return setIsSearch(true);
        }
        if (debouncedSearch) {
          return setIsSearch(true);
        }
        return setIsSearch(false);
      },
    },
  );

  const { mutate: deleteManufacturer } = useMutation(
    (id: string) => FeaturedManufacturerApi.delete(id),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          QueryKeys.GET_FEATURED_MANUFACTURERS,
        ]);
        openSnackbar(ToastMessages.featuredManufacturerDeleteSuccess);
      },
      onError: (error: DetailedApiError) => {
        if (error?.code == '500') {
          return openSnackbar('Something went wrong');
        }
        return openSnackbar(error?.message);
      },
    },
  );

  const { mutate: saveChanges, isLoading: isSaveChangesLoading } = useMutation(
    (args: string[]) => FeaturedManufacturerApi.reOrderFeaturedManufacturer(args),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          QueryKeys.GET_FEATURED_MANUFACTURERS,
        ]);
        openSnackbar(ToastMessages.featuredManufacturerAddSuccess);
      },
      onError: (error: DetailedApiError) => {
        if (error?.code == '500') {
          return openSnackbar(ToastMessages.featuredManufacturerReOrderFailure);
        }
        return openSnackbar(error?.message);
      },
    },
  )

  const manufacturersData = featuredManufacturers?.map(
    ({ rank, manufacturerName, id, manufacturerId }) => ({
      id,
      rank: rank ?? '',
      name: manufacturerName ?? '',
      manufacturerId: manufacturerId ?? '',
    }) ?? [],
  )

  const getTitle = () => {
    if (!isSearch) {
      return 'Featured Manufacturer';
    }
    if (manufacturersData && manufacturersData?.length <= 1) {
      return `${manufacturersData?.length} Featured Manufacturer`;
    }
    return `${manufacturersData?.length} Featured Manufacturers`;
  };

  return (
    <Fragment>
      <DetailedCard
        addNew={setFeaturedManufacturer}
        cardTitle='Manufacturer'
        data={manufacturersData}
        deleteCard={deleteManufacturer}
        isSaveChangesLoading={isSaveChangesLoading}
        isSearch={isSearch}
        saveChanges={saveChanges}
        search={search}
        setSearch={setSearch}
        title={getTitle()}
      />
      {addFeaturedManufacturer && (
        <AddFeaturedManufacturers
          addFeaturedManufacturer={addFeaturedManufacturer}
          close={() => setFeaturedManufacturer(false)}
        />
      )}
    </Fragment>
  );
};

export default FeaturedManufacturer;
