import {
  Button,
  Card,
  Checkbox,
  Modal,
  ProgressLoader,
  Text,
  TextLink,
  Toolbar,
} from '@/components/DesignSystem';
import { Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { QueryKeys } from '@/constants/QueryKeys';
import { ApiCategory, ApiProduct } from '@api/interfaces';
import { useSearch } from '@/hooks/useSearch';
import { ProductApi } from '@/utilities/api/ProductApi';
import { Filter, FilterSelections } from '@/components/DesignSystem/Toolbar/interfaces';
import { ManufacturerApi } from '@/utilities/api/ManufacturerApi';
import { Modify } from '@shared/utilities/UtilityTypes';

interface SelectProductsModalProps {
  existingProducts?: ApiProduct[],
  onClose: () => void,
  onSave: (products: ApiProduct[]) => void,
  testID: string,
}

export const SelectProductsModal = ({
  existingProducts,
  onClose,
  onSave,
  testID,
}: SelectProductsModalProps) => {
  const { debouncedSearch, setSearch } = useSearch(1);
  const [selectedProducts, setSelectedProducts] = useState<ApiProduct[]>(existingProducts ?? []);
  const [filterSelections, setFilterSelections] =
    useState<FilterSelections | undefined>(() => new Map());

  const selectionsString = JSON.stringify(filterSelections
    ? Array.from(filterSelections).map(([, values]) => Array.from(values))
    : [],
  );

  const manufacturerIds = Array.from(filterSelections?.get('manufacturer') ?? []);
  const categoryIds = Array.from(filterSelections?.get('category') ?? []);
  const { data: products, isFetching: areProductsFetching } = useQuery(
    [QueryKeys.GET_PRODUCTS, selectionsString, debouncedSearch],
    async () => await ProductApi.getProductList({
      categoryId: categoryIds,
      manufacturerId: manufacturerIds,
      search: debouncedSearch,
    }),
  );

  const { data: manufacturers } = useQuery(
    [QueryKeys.GET_MANUFACTURERS_LIST],
    async () => await ManufacturerApi.list(),
  );

  const { data: categories } = useQuery(
    [QueryKeys.GET_CATEGORY],
    ProductApi.getCategories,
  );

  const categoriesWithNames = useMemo(() => (
    categories?.data.filter(({ name }) => name) ?? []
  ) as Modify<ApiCategory, { name: string }>[], [categories]);

  const filters: Filter[] = useMemo(() => [
    {
      id: 'manufacturer',
      label: 'Manufacturer',
      options: manufacturers?.map(({ id, name }) => ({
        id,
        label: name,
      })) ?? [],
      selectionMethod: 'multi-select',
    },
    {
      id: 'category',
      label: 'Category',
      options: categoriesWithNames.map(({ id, name }) => ({
        id,
        label: name,
      })) ?? [],
      selectionMethod: 'multi-select',
    },
  ], [manufacturers, categoriesWithNames]);

  const ProductView = (
    <Card
      sx={{ borderRadius: '12px', maxHeight: '430px' }}
      testID={`${testID}-product-view`}
    >
      <Stack alignItems="end" height="25px">
        <TextLink
          category="label-medium"
          onClick={() => setSelectedProducts([])}
          testID={`${testID}-clear`}
        >
          Clear
        </TextLink>
      </Stack>
      <Stack maxHeight="405px" overflow="auto" pb="30px">
        {products?.data.map((product) => (
          <Stack direction="row" height="60px" key={product.id} minHeight="60px">
            <Checkbox
              checked={selectedProducts.some(({ id }) => id === product.id)}
              onChangeChecked={(checked) => {
                if (checked) {
                  setSelectedProducts([...selectedProducts, product]);
                } else {
                  setSelectedProducts(selectedProducts.filter(({ id }) => id !== product.id));
                }
              }}
              testID={`${testID}-product-checkbox-${product.id}`}
            />
            <Stack justifyContent="center">
              <Text
                category="body-large"
                maxWidth="340px"
                noWrap
              >
                {product.name}
              </Text>
              {!!product.manufacturer && (
                <Text maxWidth="340px" noWrap sx={{ color: '#B0B0B0' }}>
                  {product?.manufacturer?.name}
                </Text>
              )}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Card>
  );

  return (
    <Modal
      acceptButton={(props) => (
        <Button
          {...props}
          disabled={!selectedProducts.length}
          onClick={() => {
            onSave(selectedProducts);
            onClose();
          }}
          variant="contained"
        >
          {selectedProducts.length ? `Apply ${selectedProducts.length} Products` : 'Apply'}
        </Button>
      )}
      cancelButton={(props) => (
        <Button
          {...props}
          onClick={onClose}
        >
          Cancel
        </Button>
      )}
      onClose={onClose}
      open
      testID={testID}
      title="Select Products"
      width={560}
    >
      <Stack gap="28px">
        <Text category="body-small">
          Select products to add to this promotion.
        </Text>
        <Toolbar
          filters={filters}
          onChange={({ search, selections }) => {
            setSearch(search ?? '');
            setFilterSelections(selections);
          }}
          testID={`${testID}-toolbar`}
          totalItems={products?.total}
          totalUnit="product"
        />
        {areProductsFetching ? (
          <Stack alignItems="center" py="180px">
            <ProgressLoader type="circular" />
          </Stack>
        ) : ProductView}
      </Stack>
    </Modal>
  );
}
