import { Card, HSpacer, Text } from '@/components/DesignSystem';
import { PromotionsSideSheet } from '@/pages/Admin/PromotionsSideSheet';
import { ApiPromotion } from '@api/interfaces/ApiPromotion';
import { EditOutlined } from '@mui/icons-material';
import { Box, Chip, IconButton, Stack } from '@mui/material';
import { PromotionType } from '@shared/enums/PromotionType';
import React, { useState } from 'react';

type ProductListData = {
  id: string, name: string
}

interface ProductListItemProps {
  farmerPromotions: ApiPromotion[],
  getoneDataList: (id:string) => void,
  handleEdit: (id:string) => void,
  handleViewProductListDrawer: (e: React.MouseEvent<HTMLDivElement>) => void,
  productListData: ProductListData,
  retailerPromotions: ApiPromotion[],
  setIsEditProductField: (isEdited:boolean) => void,
}

const ProductListItem = ({
  farmerPromotions,
  getoneDataList,
  handleEdit,
  handleViewProductListDrawer,
  productListData,
  retailerPromotions,
  setIsEditProductField,
}: ProductListItemProps) => {
  const [showFarmerPromotions, setShowFarmerPromotions] = useState(false);
  const [showRetailerPromotions, setShowRetailerPromotions] = useState(false);

  return (
    <>
      <Card
        onClick={(e) => {
          handleViewProductListDrawer(e);
          getoneDataList(productListData?.id);
        }}
        sx={{ cursor: 'pointer' }}
        testID="product-list-item"
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Stack spacing={2}>
            <Text category="h6" testID='productName' >
              {productListData && productListData?.name}
            </Text>
            <Text category="h6" testID='productCategoryId'>
              {productListData && productListData?.id}
            </Text>
            {!!(farmerPromotions.length || retailerPromotions.length) && (
              <>
                <Stack direction="row">
                  {!!farmerPromotions.length && (
                    <>
                      <Chip
                        color="warning"
                        label="Farmer Rewards"
                        onClick={(e) => {
                          setShowFarmerPromotions(true);
                          e.stopPropagation();
                        }}
                      />
                      <HSpacer size="4" />
                    </>
                  )}
                  {!!retailerPromotions.length && (
                    <Chip
                      color="info"
                      label="Active Sales Incentives"
                      onClick={(e) => {
                        setShowRetailerPromotions(true);
                        e.stopPropagation();
                      }}
                    />
                  )}
                </Stack>
              </>
            )}
          </Stack>
          <Box>
            <IconButton
              data-testid='Edit'
              onClick={(e) => {
                e.stopPropagation();
                getoneDataList(productListData?.id)
                handleEdit(productListData?.id)
                setIsEditProductField(true);
              }}
            >
              <EditOutlined />
            </IconButton>
          </Box>
        </Stack>
      </Card>
      {(showFarmerPromotions || showRetailerPromotions) && (
        <PromotionsSideSheet
          onClose={() => {
            setShowFarmerPromotions(false);
            setShowRetailerPromotions(false);
          }}
          promotionType={showFarmerPromotions ? PromotionType.Farmer : PromotionType.Retailer}
          promotions={showFarmerPromotions ? farmerPromotions : retailerPromotions}
        />
      )}
    </>
  );
}
export default ProductListItem
