import { Card, HSpacer, Text, VSpacer } from "@/components/DesignSystem";
import { ApiRecommendationFarmer } from "@api/interfaces";
import { Stack } from "@mui/material";
import { formatPhoneNumber } from "@shared/utilities";

interface FarmerCardProps {
  farmer: ApiRecommendationFarmer,
  showTelephone?: 'inline' | 'stacked',
  testID: string,
}

export const FarmerCard = ({
  farmer,
  showTelephone,
  testID,
}: FarmerCardProps) => {
  const renderContactInfo = () => {
    const telephoneElement = farmer.telephone && (
      <Text category="body-medium" testID={`${testID}-telephone`}>
        {formatPhoneNumber(farmer.telephone, 'paren')}
      </Text>
    )

    if (!farmer.countyData) {
      return telephoneElement
    }
    const contactInfoElement = (
      <Text category="body-medium" testID={`${testID}-contact-info`}>
        {farmer.countyData.county}, {farmer.countyData.state}
      </Text>
    )
    switch (showTelephone) {
      case 'inline':
        return (
          <Stack direction="row">
            {contactInfoElement}
            <HSpacer size="3"/>•<HSpacer size="3"/>
            {telephoneElement}
          </Stack>
        )
      case 'stacked':
        return (
          <Stack direction="column">
            {contactInfoElement}
            <VSpacer size="2"/>
            {telephoneElement}
          </Stack>
        )
      default:
        return contactInfoElement;
    }
  }

  return (
    <Card testID={testID}>
      {!!farmer.businessName && (
        <>
          <Text category="overline" testID={`${testID}-business-name`}>{farmer.businessName}</Text>
          <VSpacer size="3"/>
        </>
      )}
      <Text category="title-medium"
            testID={`${testID}-full-name`}>{farmer.firstName} {farmer.lastName}</Text>
      <VSpacer size="3"/>
      {renderContactInfo()}
    </Card>
  );
};