import { OfferProduct } from "@/pages/CreateSendPriceRequest/interfaces";
import { AllowedPaymentMethodType } from "@shared/enums";
import { roundToFixed } from "@shared/utilities";
import { ApiOffer, ApiOfferProduct } from '@api/interfaces';
import { AppConfig } from '@/constants/AppConfig';

type PaymentMethodTypes =
  | 'acss_debit'
  | 'affirm'
  | 'afterpay_clearpay'
  | 'alipay'
  | 'au_becs_debit'
  | 'bacs_debit'
  | 'bancontact'
  | 'blik'
  | 'boleto'
  | 'card'
  | 'card_present'
  | 'customer_balance'
  | 'eps'
  | 'fpx'
  | 'giropay'
  | 'grabpay'
  | 'ideal'
  | 'interac_present'
  | 'klarna'
  | 'konbini'
  | 'link'
  | 'oxxo'
  | 'p24'
  | 'paynow'
  | 'pix'
  | 'promptpay'
  | 'sepa_debit'
  | 'sofort'
  | 'us_bank_account'
  | 'wechat_pay';


export const calculateOfferSubtotal = (products: (OfferProduct | ApiOfferProduct)[]) => {
  return products.reduce((total, product) => {
    if ('companionProducts' in product) {
      return total + ((product.companionProducts ?? []).reduce(
        (companionTotal, companionProduct) => (
          companionTotal + (companionProduct.price ?? 0)
        ), 0) + (product.price ?? 0));
    }
    return total + (product.price ?? 0);
  }, 0);
}

export const calculateStripeTotals = (offer: ApiOffer) => {
  let subtotal = 0;
  let shippingCost = 0;
  const hasAcceptedProduct = offer.products?.some((product) => product.isAccepted);
  if (hasAcceptedProduct && offer.totalShipmentCost) {
    shippingCost = offer.totalShipmentCost;
  }
  offer.products?.filter((product) => product.isAccepted).forEach((product) => {
    subtotal += product.price;
  });
  return { shippingCost, subtotal };
}

export const calculateStripeFees = (
  subtotal: number,
  shippingCost: number,
  paymentMethodType: PaymentMethodTypes,
) => {
  let stripeFee: number;
  const netAmount = subtotal + shippingCost;
  const {
    creditCardPerTransactionCost,
    creditCardPercentFee,
    achPercentFee,
  } = AppConfig.stripeFees;
  const achMaxDollarsFee = 5;
  switch (paymentMethodType) {
    case AllowedPaymentMethodType.CreditCard:
    case AllowedPaymentMethodType.Link: {
      stripeFee = (netAmount + creditCardPerTransactionCost) / (1 - creditCardPercentFee)
        - netAmount;
      break;
    }
    case AllowedPaymentMethodType.ACH: {
      const achStripeFee = netAmount / (1 - achPercentFee) - netAmount
      stripeFee = Math.min(achStripeFee, achMaxDollarsFee);
      break;
    }
    default:
      throw new Error('Invalid payment method');
  }
  return Number(roundToFixed(stripeFee, 2)) || 0;
}

