import { Button, ProgressLoader, Text, Toolbar, VSpacer } from '@/components/DesignSystem';
import {
  Filter,
  FilterOption,
  FilterSelections,
} from '@/components/DesignSystem/Toolbar/interfaces';
import { DateRangeModal } from '@/components/shared/DateRangeModal';
import { QueryKeys } from '@/constants/QueryKeys';
import { useSearch } from '@/hooks/useSearch';
import { CustomNotificationCard } from '@/pages/Admin/CustomNotifications/CustomNotificationCard';
import {
  NotificationInputs,
  SaveNotificationModal,
} from '@/pages/Admin/CustomNotifications/SaveNotificationModal';
import { useSnackbar } from '@/providers/GlobalSnackbarProvider';
import { RetailerLocationApi } from '@/utilities/api/RetailerLocationApi';
import { ScheduledNotificationApi } from '@/utilities/api/ScheduledNotificationApi';
import Add from '@mui/icons-material/Add';
import { Container, Pagination, Stack } from '@mui/material';
import { omit } from '@shared/utilities';
import { Resolved } from '@shared/utilities/UtilityTypes';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const CustomNotifications = () => {
  const { setSearch, debouncedSearch } = useSearch(1);

  const queryClient = useQueryClient();
  const { openSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [filterSelections, setFilterSelections] =
    useState<FilterSelections | undefined>(() => new Map());
  const [customDateRange, setCustomDateRange]
    = useState<{ startDate: Date, endDate: Date } | undefined>();
  const [showCustomDateRangeModal, setShowCustomDateRangeModal] = useState(false);
  const [showCreateNotificationModal, setShowCreateNotificationModal] = useState(false);

  const selectionsString = filterSelections && JSON.stringify(
    Array.from(filterSelections).map(([, values]) => Array.from(values)),
  );

  useEffect(() => {
    setPage(0);
  }, [selectionsString, debouncedSearch]);

  useEffect(() => {
    if (filterSelections?.get('send-date')?.has('custom') && !customDateRange) {
      setShowCustomDateRangeModal(true);
    } else if (!filterSelections?.get('send-date')?.has('custom') && customDateRange) {
      setCustomDateRange(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionsString]);

  const { data: locations } = useQuery(
    [QueryKeys.GET_LOCATIONS],
    async () => RetailerLocationApi.list({ isActive: true, limit: 1000 }),
  );

  const locationFilterOptions = useMemo(() => {
    const nestedOptions: Resolved<FilterOption, 'subOptionLabel' | 'subOptions'>[] = [];
    locations?.data.forEach(({ id, name, retailer  }) => {
      if (!retailer) {
        return;
      }
      const existingRetailerOption = nestedOptions.find((option) => option.id === retailer?.id);
      if (!existingRetailerOption) {
        nestedOptions.push({
          id: retailer.id,
          label: retailer.name,
          subOptionLabel: 'Locations',
          subOptions: [{
            id,
            label: name,
          }],
        });
      } else {
        existingRetailerOption.subOptions.push({
          id,
          label: name,
        });
      }
    });
    nestedOptions.sort((a, b) => a.label.localeCompare(b.label));
    return nestedOptions;
  }, [locations]);

  const formatDate = (date: Date) => {
    const luxonDate = DateTime.fromJSDate(date);
    return luxonDate.toFormat('MMM d, y');
  }

  const filters: Filter[] = [
    {
      id: 'send-date',
      label: 'Send date',
      options: [
        { id: '7', label: 'Older than a week' },
        { id: '180', label: 'Older than 6 months' },
        { id: '365', label: 'Older than a year' },
        {
          id: 'custom',
          label: 'Custom range',
          subline: customDateRange
            ? `${formatDate(customDateRange.startDate)} - ${formatDate(customDateRange.endDate)}`
            : undefined,
        },
      ],
      selectionMethod: 'single-select',
    },
    {
      id: 'status',
      label: 'Status',
      options: [
        { id: 'pending', label: 'Pending' },
        { id: 'sent', label: 'Sent' },
      ],
      selectionMethod: 'single-select',
    },
    {
      id: 'retail-location',
      label: 'Retailer & Locations',
      options: locationFilterOptions,
      selectionMethod: 'multi-select',
    },
  ];

  const status = filterSelections?.get('status')?.values().next().value;
  const sendDate = filterSelections?.get('send-date')?.values().next().value;
  const [startSendDate, endSendDate] = useMemo(() => {
    if (sendDate === 'custom' && customDateRange) {
      return [customDateRange.startDate, customDateRange.endDate];
    } else if (sendDate && sendDate !== 'custom') {
      const daysOld = parseInt(sendDate);
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - daysOld);
      return [undefined, endDate];
    }
    return [undefined, undefined];
  }, [customDateRange, sendDate]);
  const locationIds = Array.from(filterSelections?.get('retail-location') ?? []);

  const { data: notifications, isFetching } = useQuery(
    [
      QueryKeys.GET_SCHEDULED_NOTIFICATIONS,
      debouncedSearch, selectionsString, customDateRange, page,
    ],
    async () => ScheduledNotificationApi.list({
      endSendDate,
      limit: 20,
      locationIds: locationIds.length ? locationIds : undefined,
      page,
      search: debouncedSearch,
      startSendDate,
      status,
    }),
  );

  const onCreateNotification = async (inputs: NotificationInputs) => {
    await createNotification({
      ...omit(inputs, ['farmerIds', 'salespersonIds']),
      recipientIds: inputs.farmerIds.concat(inputs.salespersonIds),
    });
  }

  const { mutateAsync: createNotification } = useMutation(
    ScheduledNotificationApi.create,
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(QueryKeys.GET_SCHEDULED_NOTIFICATIONS);
        openSnackbar('Custom notification created successfully');
      },
      onError: () => {
        openSnackbar('Failed to create custom notification');
      },
    },
  );

  const Header = (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Text category="title-large">
          Custom Notifications
        </Text>
        <Button
          onClick={() => setShowCreateNotificationModal(true)}
          startIcon={<Add />}
          testID="create-notification-button"
        >
          Create
        </Button>
      </Stack>
      <VSpacer size="5" />
      <Stack alignItems="end" direction="row" justifyContent="space-between">
        <Toolbar
          filters={filters}
          initialSelections={filterSelections}
          onChange={({ search, selections }) => {
            setSearch(search ?? '');
            setFilterSelections(selections);
          }}
          showClearAllButton
          testID="notifications-toolbar"
          totalItems={notifications?.total}
          totalUnit="notification"
        />
      </Stack>
    </>
  );

  const NotificationCards = !!notifications?.data.length && (
    <Stack gap="16px">
      {notifications.data.map((notification) => (
        <CustomNotificationCard key={notification.id} notification={notification} />
      ))}
    </Stack>
  );

  return (
    <>
      <Container maxWidth="lg">
        <VSpacer size="10" />
        {Header}
        <VSpacer size="6" />
        {isFetching? (
          <Stack alignItems="center" py="180px">
            <ProgressLoader type="circular" />
          </Stack>
        ) : NotificationCards}
        {!!notifications?.total && notifications?.lastPage > 0 && (
          <Stack alignItems="center" py="20px">
            <Pagination
              count={notifications?.lastPage + 1}
              onChange={(event, page) => {
                setPage(page - 1);
              }}
              page={notifications?.page + 1}
            />
          </Stack>
        )}
      </Container>
      {showCustomDateRangeModal && (
        <DateRangeModal
          acceptButtonLabel="Apply"
          cancelButton={undefined}
          disableBackdropClick
          onClose={() => setShowCustomDateRangeModal(false)}
          onConfirm={(startDate, endDate) => {
            setCustomDateRange({ startDate, endDate });
            setShowCustomDateRangeModal(false);
          }}
          open
          testID="custom-date-range-modal"
          title="Custom range"
        />
      )}
      {showCreateNotificationModal && (
        <SaveNotificationModal
          onClose={() => setShowCreateNotificationModal(false)}
          onSubmit={onCreateNotification}
          testID="create-notification-modal"
        />
      )}
    </>
  );
}
