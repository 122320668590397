import { FilterOption } from '@/components/DesignSystem/Toolbar/interfaces';
import { RetailerLocationsList } from '@/pages/Admin/CustomNotifications/RetailerLocationsList';
import { RetailersList } from '@/pages/Admin/CustomNotifications/RetailersList';
import { SalespersonList } from '@/pages/Admin/CustomNotifications/SalespersonList';
import { ApiRetailer, LocationDetails } from '@api/interfaces';
import React, { useState } from 'react';

interface AddSalespersonRecipientsDialogContentProps {
  onChange: (updatedIds: string[]) => void,
  retailerOptions: FilterOption[],
  selectedRecipientIds: string[],
  stateAndCountyOptions: FilterOption[],
}

export const AddSalespersonRecipientsDialogContent = ({
  onChange,
  retailerOptions,
  selectedRecipientIds,
  stateAndCountyOptions,
}: AddSalespersonRecipientsDialogContentProps) => {
  const [selectedRetailer, setSelectedRetailer] = useState<ApiRetailer>();
  const [selectedLocation, setSelectedLocation] = useState<LocationDetails>();

  return (
    <>
      {!!selectedRetailer && !selectedLocation && (
        <RetailerLocationsList
          locations={selectedRetailer.locationDetails ?? []}
          onBack={() => {
            setSelectedRetailer(undefined);
          }}
          onChange={onChange}
          onSelectLocation={setSelectedLocation}
          retailer={selectedRetailer}
          selectedRecipientIds={selectedRecipientIds}
        />
      )}
      {!!selectedRetailer && !!selectedLocation && (
        <SalespersonList
          location={selectedLocation}
          onBack={() => {
            setSelectedLocation(undefined);
          }}
          onChange={onChange}
          retailer={selectedRetailer}
          selectedRecipientIds={selectedRecipientIds}
        />
      )}
      {!selectedRetailer && !selectedLocation && (
        <RetailersList
          onChange={onChange}
          onSelectRetailer={(retailer) => {
            setSelectedRetailer(retailer);
          }}
          retailerOptions={retailerOptions}
          selectedRecipientIds={selectedRecipientIds}
          stateAndCountyOptions={stateAndCountyOptions}
        />
      )}
    </>
  );
}
