import { Button, Card, HSpacer, IconButton, Text, VSpacer } from '@/components/DesignSystem';
import { Action } from '@/pages/Admin/CustomNotifications/helper';
import { LocationDetails } from '@api/interfaces';
import { KeyboardArrowRight } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Stack } from '@mui/material';
import React from 'react';

interface LocationCardProps {
  addedSalespersonIds: string[],
  location: LocationDetails,
  onAction: (action: Action) => void,
  onSelectLocation: (location: LocationDetails) => void,
}

export const LocationCard = ({
  addedSalespersonIds,
  location,
  onAction,
  onSelectLocation,
}: LocationCardProps) => {
  const getSublineText = (location: LocationDetails) => {
    const locationsCount = location.salespersons?.length ?? 0;
    let subline = `${locationsCount} salesperson`;
    if (locationsCount !== 1) {
      subline += 's';
    }
    return subline;
  }

  const getAddedSalespersonCount = () => {
    return location.salespersons?.filter(
      (salesperson) => addedSalespersonIds.includes(salesperson.id),
    ).length ?? 0;
  }

  const addedRecipientsCount = getAddedSalespersonCount();
  const salespersonCount = location.salespersons?.length ?? 0;
  const showAddAllButton = salespersonCount > 0 && addedRecipientsCount !== salespersonCount;
  const showRemoveAllButton = addedRecipientsCount > 0;
  const showNavigateButton = salespersonCount > 0;

  return (
    <Card testID={`${location.id}-salesperson-recipient-location-card`}>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack justifyContent="flex-end">
          <Text category="title-small">{location.name}</Text>
          <VSpacer size="2" />
          <Text category="body-small">
            {getSublineText(location)}
          </Text>
        </Stack>
        <Stack alignItems="center" direction="row">
          {showRemoveAllButton && (
            <>
              <Text category="body-medium">
                {addedRecipientsCount} recipient{addedRecipientsCount > 1 ? 's' : ''}
              </Text>
              <HSpacer size="4" />
              <Button
                onClick={() => onAction(Action.RemoveAll)}
                testID={`${location.id}-salesperson-recipient-location-remove-button`}
                variant="text"
              >
                Remove all
              </Button>
            </>
          )}
          {showRemoveAllButton && showAddAllButton && (
            <HSpacer size="4" />
          )}
          {showAddAllButton && (
            <Button
              onClick={() => onAction(Action.AddAll)}
              startIcon={<AddIcon />}
              testID={`${location.id}-salesperson-location-add-button`}
              variant="outlined"
            >
              Add all
            </Button>
          )}
          {showNavigateButton && (
            <>
              <HSpacer size="4" />
              <IconButton
                onClick={() => onSelectLocation(location)}
                testID={`${location.id}-location-navigate-button`}
              >
                <KeyboardArrowRight />
              </IconButton>
            </>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
